'use client';

/* eslint-disable jsx-a11y/no-autofocus */
import { ChangeEvent, FC, KeyboardEvent, useEffect, useRef, useState } from 'react';

import classNames from 'classnames';

import { Icon } from '../../../icon/components/Icon/Icon';
import { Typography } from '../../../typography/components/Typography/Typography';
import { InputFieldAction } from '../InputFieldAction/InputFieldAction';

interface Props {
   id: string;
   actionIcon: string;
   autocomplete?: 'on' | 'off';
   clearable?: boolean;
   disabled?: boolean;
   supportText?: string;
   label?: string;
   leadingIcon?: string;
   maxLength?: number;
   readOnly?: boolean;
   required?: boolean;
   suffix?: string;
   trailingIcon?: string;
   value?: string;
   width?: string | number;
   onClear?: () => void;
   onChange?: (value: string) => void;
   onEnter?: (value: string) => void;
   onActionClick?: (value: string) => void;
   onFocus?: () => void;
   onBlur?: () => void;
   autoFocus?: boolean;
}

export const InputGroup: FC<Props> = ({
   id,
   actionIcon,
   autocomplete = 'on',
   clearable = false,
   disabled = false,
   supportText,
   label,
   leadingIcon,
   maxLength = 255,
   readOnly = false,
   required = false,
   suffix,
   trailingIcon,
   value = '',
   width,
   onClear,
   onChange,
   onEnter,
   onActionClick,
   onFocus,
   onBlur,
   autoFocus
}) => {
   const [inputValue, setInputValue] = useState(value);
   const [focused, setFocused] = useState(false);

   const inputRef = useRef<HTMLInputElement>(null);

   /* istanbul ignore next */
   const handleFocus = () => {
      if (!focused) {
         setFocused(true);
         onFocus?.();
      }
   };

   /* istanbul ignore next */
   const handleBlur = () => {
      if (focused) {
         setFocused(false);
         onBlur?.();
      }
   };

   /* istanbul ignore next */
   const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      setInputValue(event.target.value);
      onChange?.(event.target.value);
   };

   const handleClear = () => {
      inputRef.current?.focus();
      setInputValue('');
      onChange?.('');
      onClear?.();
   };

   /* istanbul ignore next */
   const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
         onEnter?.(inputValue);
         inputRef.current?.blur();
      }
   };

   /* istanbul ignore next */
   const handleActionClick = () => {
      onActionClick?.(inputValue);
   };

   useEffect(() => setInputValue(value), [value]);

   return (
      <div data-testid="input-group" style={{ width }} className="gap-component-input-input-group-container-spacing-outer-gap flex w-full flex-col">
         <div className={`group relative cursor-text ${disabled || readOnly ? 'pointer-events-none' : ''}`}>
            {label && (
               <label
                  data-testid="input-group-label"
                  htmlFor={id}
                  style={{
                     maxWidth: 'calc(100% - var(--component-input-input-field-action-container-sizing-min-size) - var(--component-input-input-group-container-spacing-padding-left) - var(--component-input-input-group-container-spacing-gap))'
                  }}
                  className={classNames(
                     'absolute left-0 top-0 flex items-center transition-all cursor-pointer',
                     !disabled && (inputValue !== '' || focused) && 'text-component-input-input-group-label-color-text-neutral-enabled-as-label',
                     !disabled && focused && 'text-component-input-input-group-label-color-text-neutral-focused',
                     !disabled && inputValue === '' && !focused && 'text-component-input-input-group-label-color-text-neutral-enabled-as-placeholder',
                     disabled && 'text-component-input-input-group-label-color-text-neutral-disabled',
                     focused && 'h-[var(--component-input-input-group-container-border-width-focused)]',
                     inputValue !== '' && !focused && 'h-[var(--component-input-input-group-container-border-width-enabled)]',
                     !focused && inputValue === '' && 'h-full',
                     (inputValue !== '' || focused) && 'bg-component-input-input-group-container-color-background px-component-input-input-group-label-spacing-padding-x',
                     inputValue === '' && !focused && leadingIcon
                        ? 'phone:ml-[calc(var(--component-input-input-group-container-spacing-padding-left)+var(--component-input-input-group-container-spacing-gap)+var(--component-data-display-icon-container-sizing-width-mobile-body))] tab-port:ml-[calc(var(--component-input-input-group-container-spacing-padding-left)+var(--component-input-input-group-container-spacing-gap)+var(--component-data-display-icon-container-sizing-width-tablet-body))] tab-land:ml-[calc(var(--component-input-input-group-container-spacing-padding-left)+var(--component-input-input-group-container-spacing-gap)+var(--component-data-display-icon-container-sizing-width-tablet-body))] ml-[calc(var(--component-input-input-group-container-spacing-padding-left)+var(--component-input-input-group-container-spacing-gap)+var(--component-data-display-icon-container-sizing-width-desktop-body))]'
                        : 'ml-component-input-input-group-container-spacing-padding-left'
                  )}
               >
                  <Typography variant={inputValue !== '' || focused ? 'caption' : 'body'} color="inherit" maxLines="1">
                     {label}
                     {required && '*'}
                  </Typography>
               </label>
            )}

            <div
               data-testid="input-group-container"
               className={classNames(
                  focused && 'border-component-input-input-group-container-border-width-focused',
                  focused && 'border-component-input-input-group-container-color-border-neutral-focused',
                  'border-component-input-input-group-container-color-border-neutral-enabled',
                  'hover:border-component-input-input-group-container-color-border-neutral-hover',
                  'min-h-component-input-input-group-container-sizing-min-height',
                  'gap-component-input-input-group-container-spacing-gap',
                  'bg-component-input-input-group-container-color-background',
                  'rounded-component-input-input-group-container-border-radius',
                  'border-component-input-input-group-container-border-width-enabled',
                  'box-border',
                  'flex',
                  'items-center',
                  'justify-between',
                  'overflow-hidden',
                  'transition-all',
                  'min-w-fit'
               )}
            >
               <div className="pl-component-input-input-group-container-spacing-padding-left gap-component-input-input-group-container-spacing-gap flex h-full flex-1 cursor-text justify-between pr-0">
                  {leadingIcon && (
                     <div
                        data-testid="input-group-leading-icon"
                        className={`w-component-data-display-icon-container-sizing-size-body m-auto flex aspect-square h-full items-center justify-center ${
                           disabled ? 'text-component-input-input-group-icon-start-color-text-on-surface-disabled' : 'text-component-input-input-group-icon-start-color-text-on-surface-enabled'
                        }`.replace(/\s+/g, ' ')}
                     >
                        <Icon icon={leadingIcon} />
                     </div>
                  )}
                  <div className="flex h-full flex-1 items-center">
                     <input
                        data-testid="input-group-input"
                        autoComplete={autocomplete}
                        className="text-component-input-input-group-input-color-text-on-surface-enabled disabled:text-component-input-input-group-input-color-text-on-surface-disabled typography-component-typography-body-regular-md w-full bg-transparent outline-none"
                        disabled={disabled}
                        id={id}
                        maxLength={maxLength}
                        name={id}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onKeyUp={handleKeyPress}
                        readOnly={readOnly}
                        ref={inputRef}
                        required={required}
                        type="text"
                        value={inputValue}
                        autoFocus={autoFocus}
                     />
                  </div>
                  {suffix && (
                     <div data-testid="input-group-suffix" className={`flex items-center ${disabled ? 'text-component-input-input-group-suffix-color-text-disabled' : 'text-component-input-input-group-suffix-color-text-enabled'}`}>
                        <Typography variant="body" color="inherit">
                           {suffix}
                        </Typography>
                     </div>
                  )}
                  {clearable && inputValue !== '' && (
                     <div
                        data-testid="input-group-clear"
                        onClick={handleClear}
                        role="presentation"
                        className={`w-component-data-display-icon-container-sizing-size-body m-auto flex aspect-square h-full items-center justify-center cursor-pointer ${
                           disabled ? 'text-component-input-input-group-icon-end-color-text-on-surface-disabled' : 'text-component-input-input-group-icon-end-color-text-on-surface-enabled'
                        }`.replace(/\s+/g, ' ')}
                     >
                        <Icon icon="times-circle" />
                     </div>
                  )}
                  {trailingIcon && (
                     <div
                        data-testid="input-group-trailing-icon"
                        className={`w-component-data-display-icon-container-sizing-size-body m-auto flex aspect-square h-full items-center justify-center ${
                           disabled ? 'text-component-input-input-group-icon-end-color-text-on-surface-disabled' : 'text-component-input-input-group-icon-end-color-text-on-surface-enabled'
                        }`.replace(/\s+/g, ' ')}
                     >
                        <Icon icon={trailingIcon} />
                     </div>
                  )}
               </div>
               <div className="pr-component-input-input-group-container-spacing-padding-right flex h-full items-center justify-center">
                  <InputFieldAction icon={actionIcon} disabled={disabled} onClick={handleActionClick} />
               </div>
            </div>
         </div>
         {supportText && (
            <div
               data-testid="input-group-support-text"
               className={`pl-component-input-input-group-supporting-text-spacing-padding-left gap-component-input-input-group-supporting-text-spacing-gap flex items-center

                        ${!disabled ? 'text-component-input-input-group-supporting-text-color-text-neutral-enabled' : ''}
                        ${disabled ? 'text-component-input-input-group-supporting-text-color-text-neutral-disabled' : ''}
                        `.replace(/\s+/g, ' ')}
            >
               {supportText && (
                  <Typography variant="caption" color="inherit">
                     {supportText}
                  </Typography>
               )}
            </div>
         )}
      </div>
   );
};
