import { createContext } from 'react';

export interface RadioGroupContextValue {
   name: string | undefined;
   onChange: (value: any) => void;
   value: any;
}

const RadioGroupContext = createContext<RadioGroupContextValue | undefined>(undefined);

export default RadioGroupContext;
