import { BusinessContext } from '@ravago/shared/page-data/models/config';

export class BusinessContextUtils {
   static isUSBusinessContext(businessContext: BusinessContext | undefined) {
      if (businessContext === undefined) return false;
      const businessContextName = businessContext.name.toLowerCase();
      return businessContextName.includes('entec') || businessContextName.includes('bamberger');
   }

   static getContactEmail(businessContext: BusinessContext | undefined, countryCode: string) {
      if (businessContext === undefined) return false;

      const emailDomain = businessContext?.emailAddress?.split('@')[1] ?? '';
      const country = countryCode === 'GB' ? 'UK' : countryCode;

      return country === 'US' ? businessContext?.emailAddress : `ask.${country}@${emailDomain}`.toLowerCase();
   }
}
