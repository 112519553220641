import { User, UserGroups } from '@ravago/shared/authentication';
import { FeatureToggle, UserPermissions } from '@ravago/shared/page-data/models/config';
import {
   CallOffOrderColor,
   DynamicProductBlockColor,
   DynamicProductBlockDefaultColor,
   ProductDetail,
   QuotedColor
} from '@ravago/shared/page-data/models/elements';

export class DynamicProductBlockUtils {
   static getUserPermissions = (user: User | undefined, toggles: FeatureToggle[], privateFeatureToggles: FeatureToggle[]) => {
      const userPermissions: UserPermissions = {
         hasGuestPermission: false,
         hasPendingPermission: false,
         hasAnonymousPermission: false,
         hasVerifiedPermission: false
      };

      if (!user) {
         userPermissions.hasAnonymousPermission =
            toggles.find((feature) => feature.name === 'public-request-for-quotation-anonymous-user')?.allowed ?? false;
         return userPermissions;
      }

      const userGroups = user.groups ?? [];

      if (userGroups.includes(UserGroups.digitalPlatformGuest)) {
         userPermissions.hasGuestPermission = privateFeatureToggles.some(
            (toggle) => toggle.name === 'request-for-quotation-guest-user' && toggle.allowed
         );
         return userPermissions;
      }

      if (userGroups.includes(UserGroups.digitalPlatformPendingVerification)) {
         userPermissions.hasPendingPermission = privateFeatureToggles.some(
            (toggle) => toggle.name === 'request-for-quotation-pending-user' && toggle.allowed
         );
         return userPermissions;
      }

      userPermissions.hasVerifiedPermission = privateFeatureToggles.some(
         (toggle) => toggle.name === 'request-for-quotation-verified-user' && toggle.allowed
      );

      return userPermissions;
   };

   static hasPermission = (user: User | undefined, userPermissions: UserPermissions) => {
      if (!user) {
         return userPermissions.hasAnonymousPermission;
      }

      if (user.groups?.includes(UserGroups.digitalPlatformGuest)) {
         return userPermissions.hasGuestPermission;
      }

      if (user.groups?.includes(UserGroups.digitalPlatformPendingVerification)) {
         return userPermissions.hasPendingPermission;
      }

      return userPermissions.hasVerifiedPermission;
   };

   static createEmptyColorState = (): DynamicProductBlockColor => ({
      colorId: undefined,
      colorName: undefined,
      callOffOrderId: undefined,
      isQuotedColor: false,
      producerColorCode: undefined,
      desiredColor: undefined,
      quantity: undefined
   });

   static getDefaultColorValue = (
      defaults: DynamicProductBlockDefaultColor | undefined,
      product: ProductDetail,
      callOffOrderColors: CallOffOrderColor[],
      quotedColors: QuotedColor[]
   ): DynamicProductBlockColor => {
      if (defaults && defaults.defaultColorName) {
         const defaultColor =
            callOffOrderColors.find(
               (color) => color.color.name === defaults.defaultColorName && color.color.producerColorCode === (defaults.defaultColorCode ?? '')
            ) ||
            quotedColors.find(
               (color) => color.color.name === defaults.defaultColorName && color.color.producerColorCode === (defaults.defaultColorCode ?? '')
            ) ||
            product.colors.find((color) => color.name === defaults.defaultColorName);
         if (!defaultColor) return DynamicProductBlockUtils.createEmptyColorState();

         const defaultColorCode = 'color' in defaultColor ? defaultColor.color.producerColorCode : defaults.defaultColorCode;

         return {
            colorId: 'color' in defaultColor ? defaultColor.color.id : defaultColor.id,
            colorName: 'color' in defaultColor ? defaultColor.color.name : defaultColor.name,
            producerColorCode: defaultColorCode,
            desiredColor: 'color' in defaultColor ? undefined : defaults.defaultDesiredColor,
            callOffOrderId: 'recommendedCallOff' in defaultColor ? defaultColor.recommendedCallOff.id : undefined,
            isQuotedColor: false,
            quantity: undefined
         };
      }

      if (product.colors.length === 1) {
         return {
            colorId: product.colors[0].id,
            colorName: product.colors[0].name,
            producerColorCode: undefined,
            desiredColor: undefined,
            callOffOrderId: undefined,
            isQuotedColor: false,
            quantity: undefined
         };
      }

      if (callOffOrderColors.length > 0) {
         return {
            colorId: callOffOrderColors[0].color.id,
            colorName: callOffOrderColors[0].color.name,
            producerColorCode: callOffOrderColors[0].color.producerColorCode,
            desiredColor: undefined,
            callOffOrderId: callOffOrderColors[0].recommendedCallOff.id,
            isQuotedColor: false,
            quantity: undefined
         };
      }

      if (quotedColors.length > 0) {
         const defaultQuotedColor = quotedColors.reduce((earliest, current) => {
            const earliestDate = new Date(Math.min(...earliest.quotations.map((q) => new Date(q.effective.until).getTime())));
            const currentDate = new Date(Math.min(...current.quotations.map((q) => new Date(q.effective.until).getTime())));
            return currentDate < earliestDate ? current : earliest;
         });
         return {
            colorId: defaultQuotedColor.color.id,
            colorName: defaultQuotedColor.color.name,
            producerColorCode: defaultQuotedColor.color.producerColorCode,
            desiredColor: undefined,
            callOffOrderId: undefined,
            isQuotedColor: true,
            quantity: undefined
         };
      }

      return DynamicProductBlockUtils.createEmptyColorState();
   };
}
