import { FC, ReactNode } from 'react';

import classNames from 'classnames';

interface Props {
   onClick?: () => void;
   divider?: boolean;
   children: ReactNode;
}

export const TableRow: FC<Props> = ({ onClick, children, divider = false }) => (
   <tr data-testid={`table-row${divider ? '--divider' : ''}`} onClick={onClick} role="presentation" className={classNames(divider && `border-b-component-data-display-table-border-width border-b-component-data-display-table-color-border`, onClick && 'cursor-pointer')}>
      {children}
   </tr>
);
