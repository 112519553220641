'use client';

import { createContext, useContext } from 'react';

import { initialState, Action, BottomContainerState } from '../reducers/bottom-container-reducer';

export const BottomContainerContext = createContext<{ state: BottomContainerState; dispatch?: React.Dispatch<Action> }>({
   state: initialState,
   dispatch: undefined
});

export const useBottomContainerContext = () => {
   const context = useContext(BottomContainerContext);
   if (!context) {
      throw new Error('useRequestForQuotationContext must be used within a RequestForQuotationContextProvider');
   }
   return context;
};
