'use client';

import { OktaAuth, Token } from '@okta/okta-auth-js';

import { useCallback, useEffect, useMemo, useState } from 'react';

import { useAuthenticationConfigContext } from '../contexts/authentication-config-context';
import { User } from '../models/user.model';

let intervalId: any;

export default function useClientAuthentication() {
  const { okta } = useAuthenticationConfigContext();
  const [loading, setLoading] = useState(true);
  const [accessToken, setAccessToken] = useState<string>();
  const [user, setUser] = useState<User>();
  const [mail, setMail] = useState<string>();
  const [token, setToken] = useState<Token>();

  const oktaAuth = useMemo(() => {
    if (!okta) return undefined;

    return new OktaAuth({ ...okta });
  }, [okta]);

  const getUser = useCallback(async () => {
    if (!oktaAuth) return;

    let currentToken = (await oktaAuth.tokenManager.getTokens()).accessToken;

    if (!currentToken) {
      setLoading(false);
      return;
    }

    // Refresh token when expired
    if (oktaAuth.tokenManager.hasExpired(currentToken)) {
      await oktaAuth.tokenManager.renew('accessToken');
      currentToken = (await oktaAuth.tokenManager.getTokens()).accessToken;

      if (!currentToken) {
        setLoading(false);
        return;
      }
    }

    setUser(currentToken.claims as unknown as User);
    setToken(currentToken as Token);
    setAccessToken(currentToken.accessToken);
    setMail(currentToken?.claims?.sub);
    setLoading(false);
  }, [oktaAuth]);

  const login = (redirectUrl?: string) => {
    if (redirectUrl) localStorage.setItem('navigationPending', redirectUrl);
    const { pathname } = window.location;
    const locale = pathname.split('/')[1];
    const languageCode = locale.split('-')[0].toLowerCase();
    window.location.href = `/my/${languageCode}/auth`;
  };

  const logout = () => {
    if (!oktaAuth || !window) return;

    localStorage.removeItem('productsToCompare');
    oktaAuth.tokenManager.clear();

    const { origin, pathname } = window.location;

    oktaAuth.signOut({ postLogoutRedirectUri: `${origin}/${pathname.split('/')[1]}` });
  };

  const refreshToken = useCallback(async () => {
    if (!oktaAuth) return;

    await oktaAuth.tokenManager.renew('accessToken');
    await getUser();
  }, [oktaAuth, getUser]);

  const tokenExpired = useCallback(() => {
    if (!oktaAuth || !token) return true;

    return oktaAuth.tokenManager.hasExpired(token);
  }, [oktaAuth, token]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  useEffect(() => {
    if (!oktaAuth || !token) return undefined;

    if (!intervalId) {
      intervalId = setInterval(async () => {
        if (tokenExpired()) await refreshToken();
      }, 5000);
    }

    return () => clearInterval(intervalId);
  }, [token, oktaAuth, getUser, tokenExpired, refreshToken]);

  return { user, loading, accessToken, mail, refreshToken, login, logout };
}
