import { ContentfulOrderProduct, ContentfulProduct } from '@ravago/shared/page-data/models/contentful';
import { OrderLineOpenedFrom } from '@ravago/shared/page-data/models/elements';

export interface OrderLineState {
   orderId: number | undefined;
   orderNumber: number | undefined;
   shipmentId: number | undefined;
   openedFrom: OrderLineOpenedFrom | undefined;
   contentfulProduct?: ContentfulOrderProduct | ContentfulProduct | undefined;
}

export interface OrderLineAction {
   type: 'set_order' | 'clear_order';
   value: Partial<OrderLineState>;
}

export const initialState: OrderLineState = {
   orderId: undefined,
   orderNumber: undefined,
   shipmentId: undefined,
   openedFrom: undefined,
   contentfulProduct: undefined
};

export const OrderLineReducer = (state: OrderLineState, action: OrderLineAction) => {
   switch (action.type) {
      case 'set_order':
         return {
            ...state,
            orderId: action.value.orderId,
            orderNumber: action.value.orderNumber,
            contentfulProduct: action.value.contentfulProduct,
            openedFrom: action.value.openedFrom,
            shipmentId: action.value.shipmentId
         };
      case 'clear_order':
         return initialState;
      default:
         return state;
   }
};
