'use client';

import { useAuthenticationConfigContext } from '../contexts/authentication-config-context';
import { User } from '../models/user.model';
import useClientAuthentication from './use-client-authentication';
import useServerAuthentication from './use-server-authentication';

export interface UseAuthenticationProps {
  user: User | undefined;
  loading: boolean;
  accessToken: string | undefined;
  mail: string | null | undefined;
  login: (redirectUrl?: string) => void;
  refreshToken: () => Promise<void>;
  logout: () => void;
}

export const useAuthentication = (): UseAuthenticationProps => {
  const { useNextAuth } = useAuthenticationConfigContext();
  return (useNextAuth ? useServerAuthentication : useClientAuthentication)();
};
