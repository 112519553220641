'use client';

import { FC, useMemo } from 'react';

import { CallOffOrderDetail, DisplayOrder } from '@ravago/shared/page-data/models/elements';
import { Badge } from '@ravago/shared/radiance/components/badge/components/Badge/Badge';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Paper } from '@ravago/shared/radiance/components/paper/components/Paper/Paper';
import { ProgressBar, ProgressBarSegmentValue } from '@ravago/shared/radiance/components/progress-bar/components/ProgressBar/ProgressBar';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { useTranslations } from '../../../providers/translation-client-context-provider';
import { DateUtils } from '../../../utils/date/date.utils';
import { OrderUtils } from '../../../utils/order/order.utils';

interface CallOffProgressBarProps {
   callOffOrderDetail: CallOffOrderDetail;
   showLabel: boolean;
   showSupportingText: boolean;
}

interface CallOffToolTipProps {
   order: DisplayOrder;
}

const CallOffToolTip: FC<CallOffToolTipProps> = ({ order }) => {
   const translate = useTranslations();

   return (
      <Paper shadow border={false}>
         <Box direction="column">
            <Box direction="row" justify="between">
               <Typography variant="body-highlight">Order {order.orderNumber}</Typography>
               <Badge size="regular" variant={order.status !== 'Invoiced' ? 'info' : 'warning'} weight="bold">
                  {translate(OrderUtils.getStatusTranslation(order.status))}
               </Badge>
            </Box>
            <Box direction="column" gap="xs">
               <Box width="80%" gap="none">
                  <Box width="50%">
                     <Typography variant="small-highlight" color="subtle">
                        {translate('my-orders.order-date')}
                     </Typography>
                  </Box>
                  <Box width="50%">
                     <Typography variant="small">{order.orderedOn ? DateUtils.localizeDate(order.orderedOn) : '-'}</Typography>
                  </Box>
               </Box>
               <Box width="80%" gap="none">
                  <Box width="50%">
                     <Typography variant="small-highlight" color="subtle">
                        {translate('my-orders.quantity')}
                     </Typography>
                  </Box>
                  <Box width="50%">
                     <Typography variant="small">
                        {order.quantity.amount} {order.quantity.unitOfMeasure}
                     </Typography>
                  </Box>
               </Box>
            </Box>
         </Box>
      </Paper>
   );
};

export const CallOffProgressBar: FC<CallOffProgressBarProps> = ({ callOffOrderDetail, showLabel, showSupportingText }) => {
   const translate = useTranslations();

   const label = showLabel ? translate('my-orders.call-off-progress-title') : undefined;
   const supportingText = showSupportingText
      ? translate('my-orders.call-off-progress-description', {
           consumed: `${callOffOrderDetail.consumedQuantity.amount} ${callOffOrderDetail.consumedQuantity.unitOfMeasure}`,
           total: `${callOffOrderDetail.totalQuantity.amount} ${callOffOrderDetail.totalQuantity.unitOfMeasure}`,
           remaining: `${callOffOrderDetail.remainingQuantity.amount} ${callOffOrderDetail.remainingQuantity.unitOfMeasure}`
        })
      : undefined;

   const progressBarSegmentValues = useMemo(() => {
      const values = callOffOrderDetail.orders.map((order) => ({
         id: order.orderNumber,
         value: order.quantity.amount,
         active: true
      }));

      const consumedTotal = values.reduce((total, { value }) => total + value, 0);

      if (consumedTotal < callOffOrderDetail.totalQuantity.amount) {
         values.push({
            id: 'remaining',
            value: callOffOrderDetail.remainingQuantity.amount,
            active: false
         });
      }

      return values;
   }, [callOffOrderDetail]);

   const renderTooltip = (segment: ProgressBarSegmentValue) => {
      const orderResult = callOffOrderDetail.orders.find((order) => order.orderNumber === segment.id);
      if (!orderResult) return null;
      return <CallOffToolTip order={orderResult} />;
   };

   return <ProgressBar value={progressBarSegmentValues} label={label} supportingText={supportingText} tooltip={renderTooltip} />;
};
