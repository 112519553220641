import { useContext } from 'react';

import { FlyoutContext } from '../providers/flyout-context-providers';

export const useFlyoutContext = () => {
   const context = useContext(FlyoutContext);
   if (!context) {
      throw new Error('useFlyoutContext must be used within a FlyoutContext');
   }
   return context;
};
