import { FC, ReactNode, useMemo } from 'react';

import useId from '../../../../hooks/useId/useId';
import { Typography } from '../../../typography/components/Typography/Typography';
import { ProgressBarSegment } from '../atoms/ActiveSegment/ProgressBarSegment';

export interface ProgressBarSegmentValue {
   id: string;
   value: number;
   active: boolean;
}

interface Props {
   value: Array<ProgressBarSegmentValue>;
   label?: string;
   supportingText?: string;
   tooltip?: (segment: ProgressBarSegmentValue) => ReactNode;
}

export const ProgressBar: FC<Props> = ({ value, label, supportingText, tooltip }) => {
   const labelId = useId(undefined, 'progress-bar');

   const max = useMemo(() => value.reduce((acc, curr) => acc + curr.value, 0), [value]);
   const ariaValueNow = useMemo(() => value.filter((segment) => segment.active).reduce((acc, curr) => acc + curr.value, 0), [value]);

   return (
      <div data-testid="progress-bar" className="gap-component-data-display-progress-bar-container-spacing-gap z-10 flex flex-col">
         {label && (
            <div id={labelId}>
               <Typography variant="body" color="strong">
                  {label}
               </Typography>
            </div>
         )}
         <div
            role="progressbar"
            aria-valuemin={0}
            aria-valuemax={max}
            aria-valuenow={ariaValueNow}
            aria-labelledby={labelId}
            data-testid="progress-bar-track"
            className="h-component-data-display-progress-bar-track-sizing-heigth px-component-data-display-progress-bar-track-spacing-padding-x py-component-data-display-progress-bar-track-spacing-padding-y gap-component-data-display-progress-bar-track-spacing-gap bg-component-data-display-progress-bar-track-color-background rounded-component-data-display-progress-bar-track-border-radius flex justify-between"
         >
            {value.map((segment) => (
               <ProgressBarSegment key={`progress-bar-segment-${segment.id}`} segment={segment} tooltip={tooltip} width={`calc(${(segment.value / max) * 100}% - (${value.length - 1} * var(--component-data-display-progress-bar-track-spacing-gap)) / ${value.length})`} />
            ))}
         </div>
         {supportingText && (
            <div data-testid="progress-bar-supporting-text">
               <Typography variant="small" color="subtle">
                  {supportingText}
               </Typography>
            </div>
         )}
      </div>
   );
};
