import { ReactNode } from 'react';

export interface BottomContainerState {
   bottomSheet?: {
      header: ReactNode;
      children?: ReactNode;
      options?: {
         closeOnOutsideClick: boolean;
      };
      expanded: boolean;
   };
}

export type Action =
   | {
        type: 'show_bottom_sheet';
        bottomSheet: {
           header: ReactNode;
           children?: ReactNode;
           options?: {
              closeOnOutsideClick: boolean;
           };
        };
     }
   | {
        type: 'hide_bottom_sheet';
     }
   | {
        type: 'expand_bottom_sheet';
     }
   | {
        type: 'close_bottom_sheet';
     };

export const initialState: BottomContainerState = {
   bottomSheet: undefined
};

export const reducer = (state: BottomContainerState, action: Action): BottomContainerState => {
   switch (action.type) {
      case 'show_bottom_sheet':
         return { ...state, bottomSheet: { ...action.bottomSheet, expanded: false } };
      case 'hide_bottom_sheet':
         return { ...state, bottomSheet: undefined };
      case 'expand_bottom_sheet':
         if (state.bottomSheet) {
            return { ...state, bottomSheet: { ...state.bottomSheet, expanded: true } };
         }
         return { ...state };
      case 'close_bottom_sheet':
         if (state.bottomSheet) {
            return { ...state, bottomSheet: { ...state.bottomSheet, expanded: false } };
         }
         return { ...state };
      default:
         return { ...state };
   }
};
