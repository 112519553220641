'use client';

import { FC, ReactNode } from 'react';

import { useBodyOverflowHidden } from '../../../../hooks/useBodyOverflowHidden/useBodyOverflowHidden';

interface Props {
   closeOnOutsideClick?: boolean;
   container?: boolean;
   expanded?: boolean;
   viewport?: boolean;
   onClose?: () => void;
   onOpen?: () => void;
   header: ReactNode;
   position?: 'absolute' | 'relative';
   children?: ReactNode;
}

export const BottomSheet: FC<Props> = ({ closeOnOutsideClick = true, container = true, expanded = false, viewport = false, onClose, onOpen, header, position = 'absolute', children }) => {
   useBodyOverflowHidden(expanded, { disabled: container });

   /* istanbul ignore next */
   const handleClose = () => {
      if (!closeOnOutsideClick) return;

      onClose?.();
   };

   const handleHeaderClick = () => {
      if (!children) return;

      if (expanded) {
         onClose?.();
      } else {
         onOpen?.();
      }
   };

   return (
      <>
         {viewport && <div data-testid="bottom-sheet--backdrop" className={`fixed inset-0 transition-all ${expanded ? 'bg-component-surfaces-bottom-sheet-backdrop-color-background z-50' : 'pointer-events-none -z-10'}`} onClick={handleClose} role="presentation" />}
         <div className={`z-[60] w-full ${viewport ? 'fixed inset-x-0 bottom-0' : position}`}>
            <div
               data-testid={`bottom-sheet${!viewport ? '--inline' : ''}${expanded ? '--expanded' : ''}${!container ? '--no-container' : ''}`}
               className={`gap-component-surfaces-bottom-sheet-container-spacing-gap rounded-t-component-surfaces-bottom-sheet-border-radius shadow-component-surfaces-bottom-sheet-container-box-shadow bg-component-surfaces-bottom-sheet-container-color-background  px-component-surfaces-bottom-sheet-container-spacing-padding-x py-component-surfaces-bottom-sheet-container-spacing-padding-y flex flex-col
                        ${expanded && children ? 'pb-component-surfaces-bottom-sheet-container-spacing-padding-bottom-expanded' : ''}
                        ${container && viewport ? 'container' : ''}
                      `.replace(/\s+/g, ' ')}
            >
               <div onClick={handleHeaderClick} role="presentation" data-testid="bottom-sheet-header" className={`flex w-full flex-col ${children ? 'cursor-pointer' : ''}`}>
                  {header}
               </div>

               {expanded && children && <div data-testid="bottom-sheet-content">{expanded && children}</div>}
            </div>
         </div>
      </>
   );
};
