import { FC, ReactNode } from 'react';

interface Props {
   useContainerQuery?: boolean;
   spanDesktop?: number;
   spanTablet?: number;
   spanPhone?: number;
   startDesktop?: number;
   startTablet?: number;
   startPhone?: number;
   children: ReactNode | Array<ReactNode>;
}

export const GridCol: FC<Props> = ({ useContainerQuery, spanDesktop, spanTablet, spanPhone, startDesktop, startTablet, startPhone, children }) => (
   <div
      className={`
          ${useContainerQuery ? '@' : ''}phone:col-span-${spanPhone ?? 'auto'}
          ${useContainerQuery ? '@' : ''}phone:col-start-${startPhone ?? 'auto'}
          ${useContainerQuery ? '@' : ''}tab-port:col-span-${spanTablet ?? 'auto'}
          ${useContainerQuery ? '@' : ''}tab-port:col-start-${startTablet ?? 'auto'}
          ${useContainerQuery ? '@' : ''}tab-land:col-span-${spanTablet ?? 'auto'}
          ${useContainerQuery ? '@' : ''}tab-land:col-start-${startTablet ?? 'auto'}
          ${useContainerQuery ? '@' : ''}desktop:col-span-${spanDesktop ?? 'auto'}
          ${useContainerQuery ? '@' : ''}desktop:col-start-${startDesktop ?? 'auto'}`.replace(/\s+/g, ' ')}
   >
      {children}
   </div>
);
