'use client';

import { FC } from 'react';

import { useAuthenticationContext } from '@ravago/shared/authentication';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Button } from '@ravago/shared/radiance/components/button/components/Button/Button';

import { usePathname, useSearchParams } from 'next/navigation';

interface Props {
   buttonLabel: string;
   language: string;
   trailingIcon?: string;
   variant?: 'primary' | 'secondary' | 'tertiary' | 'quaternary' | 'error';
   fullwidth?: boolean;
}

export const LoginButton: FC<Props> = ({ buttonLabel, language, trailingIcon, variant = 'secondary', fullwidth = false }) => {
   const pathname = usePathname();
   const searchParams = useSearchParams();
   const { login } = useAuthenticationContext();

   const handleLogin = () => {
      if (typeof window !== 'undefined') {
         localStorage.setItem('navigationPendingScrollPosition', window.scrollY.toString());
      }

      const fullUrl = `${pathname}?${searchParams.toString()}`;
      login(fullUrl);
   };

   return (
      <Box direction={fullwidth ? 'column' : 'row'} width={fullwidth ? '100%' : undefined}>
         <Button onClick={handleLogin} fullWidth={fullwidth} variant={variant} trailingIcon={trailingIcon}>
            {buttonLabel}
         </Button>
      </Box>
   );
};
