'use client';

import { createContext } from 'react';

import { ClientConfig } from '@ravago/shared/page-data/models/config';

export interface ConfigContextProps {
   config: ClientConfig;
}

export const ConfigContext = createContext<ConfigContextProps | null>(null);
