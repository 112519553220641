'use client';

import { useContext } from 'react';

import { DialogFormContext } from '../context/dialog-form-context';

export const useDialogFormContext = () => {
   const {
      customerFeedback = {
         category: undefined,
         feedback: undefined
      },
      setFeedback = () => {}
   } = useContext(DialogFormContext) ?? {};

   return { setFeedback, customerFeedback };
};
