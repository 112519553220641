import { Session, initAuth0 } from '@auth0/nextjs-auth0';

import { User } from '../models/user.model';

const auth0Instance = initAuth0({
  secret: process.env['OKTA_SECRET'],
  issuerBaseURL: process.env['OKTA_ISSUER'],
  baseURL: process.env['OKTA_BASE_URL'],
  clientID: process.env['OKTA_CLIENT_ID'],
  clientSecret: process.env['OKTA_CLIENT_SECRET'],
  auth0Logout: false,
  authorizationParams: { scope: 'openid profile CustomerPortalToggles.Info CustomerPortal.Info offline_access' }
});

export type AppUserSession = Omit<Session, 'user'> & {
  user: User;
};

export const getUserSession = async (): Promise<AppUserSession | null> => {
  const session = await auth0Instance.getSession();
  if (!session) return null;

  return {
    ...session,
    user: session.user as User
  };
};

export default auth0Instance;
