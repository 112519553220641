export class AppUtils {
   static getKey(nodeId: string, identifier: string | number): string {
      return `${nodeId}-${identifier}`;
   }

   static firstLetterToLowerCase(value: string): string {
      if (value.length === 0) return value;

      return value[0].toLowerCase() + value.slice(1);
   }
}
