import { FC, ReactNode } from 'react';

import { Typography } from '../../../typography/components/Typography/Typography';

interface Props {
   align?: 'left' | 'center' | 'right';
   children: ReactNode;
   colSpan?: number;
   verticalAlign?: 'top' | 'middle' | 'baseline' | 'bottom';
   width?: string;
}

export const TableSubheader: FC<Props> = ({ align = 'left', colSpan = 1, verticalAlign = 'middle', width, children }) => (
   <td
      data-testid="table-subheader"
      align={align}
      colSpan={colSpan}
      valign={verticalAlign}
      width={width}
      className="bg-component-data-display-table-cell-color-background-striped-default px-component-data-display-table-cell-spacing-padding-x py-component-data-display-table-cell-spacing-padding-y snap-start whitespace-normal"
   >
      <Typography variant="body" color="strong">
         {children}
      </Typography>
   </td>
);
