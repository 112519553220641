import { FC } from 'react';

import { OrderAddress } from '@ravago/shared/page-data/models/elements';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';
import { useBreakpoint } from '@ravago/shared/radiance/hooks/useBreakpoint/useBreakpoint';

import { useTranslations } from '../../../providers/translation-client-context-provider';

interface Props {
   destinationAddress?: OrderAddress;
   deliveryInstructions: string;
   deliveryTerm: string;
   deliveryTermAddress?: OrderAddress;
   isInFlyout?: boolean;
}

export const DeliveryInformation: FC<Props> = ({
   destinationAddress,
   deliveryTermAddress,
   deliveryInstructions,
   deliveryTerm,
   isInFlyout = false
}) => {
   const translate = useTranslations();
   const { phone } = useBreakpoint();

   return (
      <>
         {!isInFlyout && (
            <Box>
               <Typography variant="heading-4" component="h4" color="strong">
                  {translate('shipment.delivery-information')}
               </Typography>
            </Box>
         )}
         <Box gap={isInFlyout || phone ? 'lg' : '2xl'} direction={isInFlyout || phone ? 'column' : 'row'} width="100%">
            {destinationAddress && (
               <Box direction="column" gap="xs">
                  <Typography variant="body" component="span" color="subtle">
                     {destinationAddress.id === deliveryTermAddress?.id
                        ? translate('shipment.destination-and-delivery-term-address')
                        : translate('shipment.destination-address')}
                  </Typography>
                  <Typography variant="body" component="span">
                     <div dangerouslySetInnerHTML={{ __html: destinationAddress.htmlFormat }} />
                  </Typography>
               </Box>
            )}
            {deliveryTermAddress && deliveryTermAddress.id !== destinationAddress?.id && (
               <Box direction="column" gap="xs">
                  <Typography variant="body" component="span" color="subtle">
                     {translate('shipment.delivery-term-address')}
                  </Typography>
                  <Typography variant="body" component="span">
                     <div dangerouslySetInnerHTML={{ __html: deliveryTermAddress.htmlFormat }} />
                  </Typography>
               </Box>
            )}
            {deliveryInstructions && (
               <Box direction="column" gap="xs" wrap="wrap" width={phone || isInFlyout ? '100%' : '35%'}>
                  <Typography variant="body" component="span" color="subtle">
                     {translate('shipment.delivery-instructions')}
                  </Typography>
                  <Typography variant="body" component="span">
                     {deliveryInstructions}
                  </Typography>
               </Box>
            )}
            {deliveryTerm && (
               <Box direction="column" gap="xs">
                  <Typography variant="body" component="span" color="subtle">
                     {translate('shipment.delivery-term')}
                  </Typography>
                  <Typography variant="body" component="span">
                     {deliveryTerm}
                  </Typography>
               </Box>
            )}
         </Box>
      </>
   );
};
