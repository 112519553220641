import { FC, ReactNode } from 'react';

import { Typography } from '../../../typography/components/Typography/Typography';

interface Props {
   align?: 'left' | 'center' | 'right';
   children: ReactNode;
   colSpan?: number;
   onClick?: () => void;
   rowSpan?: number;
   verticalAlign?: 'top' | 'middle' | 'baseline' | 'bottom';
   width?: string;
   whiteSpace?: 'normal' | 'nowrap';
   gutter?: boolean;
   wordBreak?: 'normal' | 'all' | 'keep' | 'words';
}

export const TableCell: FC<Props> = ({ align = 'left', colSpan = 1, rowSpan = 1, verticalAlign = 'middle', width, children, onClick, whiteSpace = 'normal', gutter = true, wordBreak = 'normal' }) => (
   <td
      data-testid={`table-cell${!gutter ? '--no-gutter' : ''}`}
      align={align}
      colSpan={colSpan}
      rowSpan={rowSpan}
      valign={verticalAlign}
      onClick={onClick}
      className={`snap-start
          ${whiteSpace === 'nowrap' ? 'whitespace-nowrap' : ''}
          ${whiteSpace === 'normal' ? 'whitespace-normal' : ''}
          ${gutter ? 'px-component-data-display-table-cell-spacing-padding-x py-component-data-display-table-cell-spacing-padding-y' : ''}
          ${wordBreak === 'all' ? 'break-all' : ''}
          ${wordBreak === 'normal' ? 'break-normal' : ''}
          ${wordBreak === 'keep' ? 'break-keep' : ''}
          ${wordBreak === 'words' ? 'break-words' : ''}
       `}
      style={{ minWidth: width, maxWidth: width }}
   >
      <Typography variant="body" color="inherit">
         {children}
      </Typography>
   </td>
);
