'use client';

import { FC, useEffect, useState } from 'react';

import { CallOffOrderDetail } from '@ravago/shared/page-data/models/elements';
import { Address } from '@ravago/shared/page-data/models/elements/address.model';
import { Customer } from '@ravago/shared/page-data/models/features';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Button } from '@ravago/shared/radiance/components/button/components/Button/Button';
import { DatePicker } from '@ravago/shared/radiance/components/input-field/components/DatePicker/DatePicker';
import { InputField } from '@ravago/shared/radiance/components/input-field/components/InputField/InputField';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { useCallOffAgreementContext } from '../../../../context/call-off-agreement-context';
import { useFlyoutContext } from '../../../../hooks/use-flyout-context';
import { useTranslations } from '../../../../providers/translation-client-context-provider';
import { DateUtils } from '../../../../utils/date/date.utils';

interface Props {
   callOffOrderDetail: CallOffOrderDetail;
   deliveryAddress: Address | undefined;
   showDeliveryAddress?: boolean;
   customer?: Customer;
   onClick?: () => void;
}

export const CallOffOrderDeliveryRequest: FC<Props> = ({ callOffOrderDetail, deliveryAddress, showDeliveryAddress = true, customer, onClick }) => {
   const translate = useTranslations();
   const [quantity, setQuantity] = useState('');
   const [hasQuantityError, setHasError] = useState(false);
   const [hasPastDateError, setHasPastDateError] = useState(false);
   const [hasWeekendDateError, setHasWeekendDateError] = useState(false);
   const [additionalInfo, setAdditionalInfo] = useState('');
   const [deliveryDate, setDeliveryDate] = useState(DateUtils.getNextBusinessDay());
   const [quantityErrorMessage, setQuantityErrorMessage] = useState('');
   const [dateErrorMessage, setDateErrormessage] = useState('');
   const { state: flyoutState, dispatch: flyoutDispatch } = useFlyoutContext();
   const { state: callOffAgreementState, dispatch: callOffAgreementDispatch } = useCallOffAgreementContext();

   useEffect(() => {
      if (callOffAgreementState) {
         setQuantity(callOffAgreementState.quantity?.amount.toString() || '');
         setDeliveryDate(callOffAgreementState.deliveryDate || DateUtils.getNextBusinessDay());
         setAdditionalInfo(callOffAgreementState.additionalInfo || '');
      }
   }, [callOffAgreementState]);

   const handleNewQuantityChange = (value: string) => {
      const qty = parseInt(value, 10);
      if (Number.isNaN(qty) || qty > callOffOrderDetail.remainingQuantity.amount) {
         setQuantityErrorMessage(translate('request-delivery.invalid-quantity'));
         setHasError(true);
         return;
      }
      setQuantity(value);
      callOffAgreementDispatch?.({
         type: 'set_call_off_quantity',
         value: { quantity: { amount: parseInt(value, 10), unitOfMeasure: callOffOrderDetail.remainingQuantity.unitOfMeasure } }
      });
      setHasError(false);
   };

   const handleDeliveryDateChange = (value: string) => {
      const selectedDate = new Date(value);
      const nextBusinessDate = new Date(DateUtils.getNextBusinessDay());

      if (selectedDate < nextBusinessDate) {
         setHasPastDateError(true);
         setDateErrormessage(translate('request-delivery.date-past'));
      } else {
         setHasPastDateError(false);
         setDateErrormessage('');
      }
      callOffAgreementDispatch?.({
         type: 'set_call_off_delivery_date',
         value: { deliveryDate: value }
      });
      setDeliveryDate(value);
   };

   const handleAdditionalInfoChange = (value: string) => {
      setAdditionalInfo(value);
      callOffAgreementDispatch?.({
         type: 'set_call_off_additional_info',
         value: { additionalInfo: value }
      });
   };

   const handleRequestDeliveryClick = () => {
      if (flyoutDispatch && callOffAgreementDispatch) {
         callOffAgreementDispatch({
            type: 'set_call_off',
            value: {
               callOffOrderDetail,
               deliveryAddress,
               customer,
               deliveryDate,
               quantity: {
                  amount: parseInt(quantity, 10),
                  unitOfMeasure: callOffOrderDetail.remainingQuantity.unitOfMeasure
               },
               additionalInfo
            }
         });
         if (flyoutState.idOpenDialog !== 'call-off-agreement') {
            flyoutDispatch({
               type: 'open_flyout',
               value: { flyoutType: 'call-off-agreement', title: 'Call off agreement' }
            });
         } else {
            onClick?.();
         }
      }
   };

   return (
      <Box direction="column" gap="md">
         <InputField
            type="number"
            label="Quantity"
            id="request-delivery-add-quantity"
            suffix={callOffOrderDetail.remainingQuantity.unitOfMeasure}
            value={quantity}
            min={1}
            max={999999999}
            error={hasQuantityError}
            errorMessage={quantityErrorMessage}
            onChange={handleNewQuantityChange}
         />
         <DatePicker
            id="request-delivery-date-picker"
            label={translate('request-delivery.delivery-date-label')}
            min={DateUtils.getNextBusinessDay()}
            validateWeekends
            error={hasPastDateError}
            weekendErrorMessage={translate('request-delivery.is-weekend')}
            errorMessage={dateErrorMessage}
            value={deliveryDate}
            onWeekendValidationChange={(isValid) => setHasWeekendDateError(!isValid)}
            onChange={handleDeliveryDateChange}
         />
         <InputField
            label={translate('request-delivery.additional-information')}
            id="request-delivery-additional-info-input"
            maxLength={200}
            value={additionalInfo}
            onChange={handleAdditionalInfoChange}
         />
         {deliveryAddress && showDeliveryAddress && (
            <Box gap="xs" direction="column">
               <Typography component="span" variant="body-highlight">
                  {translate('request-for-quotation.delivery')}
               </Typography>
               <Box direction="column" gap="none">
                  <Typography component="b" variant="body" color="strong">
                     {`${deliveryAddress.line1}, ${deliveryAddress.line2 || ''}`}
                  </Typography>
                  <Typography component="span" variant="body" color="strong">
                     {`${deliveryAddress.city} ${deliveryAddress.postCode}, ${deliveryAddress.countryName}`}
                  </Typography>
               </Box>
            </Box>
         )}
         <Button
            fullWidth
            trailingIcon="long-arrow-right"
            disabled={!quantity || !deliveryDate || hasPastDateError || hasWeekendDateError || hasQuantityError}
            variant={flyoutState.idOpenDialog !== 'call-off-agreement' ? 'primary' : 'secondary'}
            onClick={handleRequestDeliveryClick}
         >
            {translate('request-delivery.summary')}
         </Button>
      </Box>
   );
};
