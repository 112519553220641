'use client';

import { useState, useEffect, createContext, useCallback, PropsWithChildren, useMemo, FC, useContext } from 'react';

import { jwtDecode } from 'jwt-decode';

interface TokenContextProps {
   accessToken: string;
   isLoading: boolean;
   refreshToken: () => Promise<void>;
}

const TokenContext = createContext<TokenContextProps | null>(null);

export const useTokenContext = () => {
  const context = useContext(TokenContext);

  if (!context) {
    throw new Error('useTokenContext must be used within a TokenContextProvider');
  }

  return context;
}

export const TokenContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [accessToken, setAccessToken] = useState('');
  const [expiresAt, setExpiresAt] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchToken = useCallback(async (forceRefresh = false) => {
    try {
      const response = await fetch(`/api/auth/token?refresh=${forceRefresh}`);
      if (!response.ok) {
        throw new Error('Failed to fetch token');
      }
      const data = await response.json();
      setAccessToken(data.accessToken);

      const decoded = jwtDecode(data.accessToken);
      if (decoded.exp) setExpiresAt(decoded.exp);
    } catch (error) {
      setAccessToken('');
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchToken();
  }, [fetchToken]);

  useEffect(() => {
    if (!expiresAt) return undefined;

    const timeUntilRefresh = expiresAt - Date.now();
    if (timeUntilRefresh <= 0) {
      return undefined;
    }

    const timerId = setTimeout(fetchToken, timeUntilRefresh);
    return () => clearTimeout(timerId);
  }, [expiresAt, fetchToken]);

  const tokenContextValue = useMemo<TokenContextProps>(
    () => ({
      accessToken,
      isLoading,
      refreshToken: () => fetchToken(true)
    }),
    [accessToken, isLoading, fetchToken]
  );

  return (
     <TokenContext.Provider value={tokenContextValue}>
        {children}
     </TokenContext.Provider>
  );
};
