'use client';

import { ChangeEvent, FC, KeyboardEvent, useEffect, useRef, useState } from 'react';

import { Icon } from '../../../icon/components/Icon/Icon';
import { Typography } from '../../../typography/components/Typography/Typography';

interface Props {
   id: string;
   autocomplete?: 'on' | 'off';
   clearable?: boolean;
   disabled?: boolean;
   error?: boolean;
   errorMessage?: string;
   supportText?: string;
   label?: string;
   leadingIcon?: string;
   max?: number;
   maxLength?: number;
   min?: number;
   readOnly?: boolean;
   required?: boolean;
   step?: number;
   suffix?: string;
   trailingIcon?: string;
   type?: 'email' | 'number' | 'password' | 'text';
   value?: string;
   width?: string | number;
   onClear?: () => void;
   onChange?: (value: string) => void;
   onEnter?: (value: string) => void;
}

export const InputField: FC<Props> = ({
   id,
   autocomplete = 'on',
   clearable = false,
   disabled = false,
   error = false,
   errorMessage,
   supportText,
   label,
   leadingIcon,
   max,
   maxLength = 255,
   min,
   readOnly = false,
   required = false,
   step = 1,
   suffix,
   trailingIcon,
   type = 'text',
   value = '',
   width,
   onClear,
   onChange,
   onEnter
}) => {
   const [inputValue, setInputValue] = useState(value);
   const [focused, setFocused] = useState(false);

   const inputRef = useRef<HTMLInputElement>(null);

   /* istanbul ignore next */
   const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      setInputValue(event.target.value);
      onChange?.(event.target.value);
   };

   const handleClear = () => {
      setInputValue('');
      onChange?.('');
      onClear?.();
   };

   /* istanbul ignore next */
   const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
         onEnter?.(inputValue);
      }
   };

   useEffect(() => setInputValue(value), [value]);

   /* istanbul ignore next */
   useEffect(() => {
      if (!focused && type === 'number' && inputValue) {
         let numberValue = Number(inputValue);

         if (min !== undefined && numberValue < min) numberValue = min;
         if (max !== undefined && numberValue > max) numberValue = max;

         setInputValue(numberValue.toString());
         onChange?.(numberValue.toString());
      }
   }, [focused, max, min]);

   return (
      <div data-testid={`input-field${error ? '--error' : ''}`} style={{ width }} className="gap-component-input-input-field-container-spacing-outer-gap flex w-full flex-col">
         <div className={`group relative cursor-text ${disabled ? 'pointer-events-none' : ''}`}>
            {label && (
               <label
                  data-testid="input-field-label"
                  htmlFor={id}
                  style={{ maxWidth: 'calc(100% - (var(--component-input-input-field-container-spacing-padding-x) * 2))' }}
                  className={`absolute left-0 top-0 flex items-center transition-all cursor-pointer
                           ${!error && !disabled && (inputValue !== '' || focused) ? 'text-component-input-input-field-label-color-text-neutral-enabled-as-label' : ''}
                           ${!error && !disabled && focused ? 'text-component-input-input-field-label-color-text-neutral-focused' : ''}
                           ${!error && !disabled && inputValue === '' && !focused ? 'text-component-input-input-field-label-color-text-neutral-enabled-as-placeholder' : ''}
                           ${disabled ? 'text-component-input-input-field-label-color-text-neutral-disabled' : ''}
                           ${error && !disabled ? 'text-component-input-input-field-label-color-text-error-enabled group-hover:text-component-input-input-field-label-color-text-error-hover' : ''}
                           ${focused ? 'h-[var(--component-input-input-field-container-border-width-focused)]' : ''}
                           ${inputValue !== '' && !focused ? 'h-[var(--component-input-input-field-container-border-width-enabled)]' : ''}
                           ${!focused && inputValue === '' ? 'h-full' : ''}
                           ${inputValue !== '' || focused ? 'bg-component-input-input-field-container-color-background px-component-input-input-field-label-spacing-padding-x' : ''}
                           ${
                              inputValue === '' && !focused && leadingIcon
                                 ? 'phone:ml-[calc(var(--component-input-input-field-container-spacing-padding-x)+var(--component-input-input-field-container-spacing-gap)+var(--component-data-display-icon-container-sizing-width-mobile-body))] tab-port:ml-[calc(var(--component-input-input-field-container-spacing-padding-x)+var(--component-input-input-field-container-spacing-gap)+var(--component-data-display-icon-container-sizing-width-tablet-body))] tab-land:ml-[calc(var(--component-input-input-field-container-spacing-padding-x)+var(--component-input-input-field-container-spacing-gap)+var(--component-data-display-icon-container-sizing-width-tablet-body))] ml-[calc(var(--component-input-input-field-container-spacing-padding-x)+var(--component-input-input-field-container-spacing-gap)+var(--component-data-display-icon-container-sizing-width-desktop-body))]'
                                 : 'ml-component-input-input-field-container-spacing-padding-x'
                           }
                      `.replace(/\s+/g, ' ')}
               >
                  <Typography variant={inputValue !== '' || focused ? 'caption' : 'body'} color="inherit" maxLines="1">
                     {label}
                     {required && '*'}
                  </Typography>
               </label>
            )}

            <div
               data-testid="input-field-container"
               onClick={() => inputRef.current?.focus()}
               role="presentation"
               className={`min-h-component-input-input-field-container-sizing-min-height px-component-input-input-field-container-spacing-padding-x py-component-input-input-field-container-spacing-padding-y gap-component-input-input-field-container-spacing-gap bg-component-input-input-field-container-color-background rounded-component-input-input-field-container-border-radius border-component-input-input-field-container-border-width-enabled focus-within:!border-component-input-input-field-container-border-width-focused box-border flex items-start justify-between transition-all
                           ${
                              error && !disabled
                                 ? '!border-component-input-input-field-container-color-border-error-enabled hover:!border-component-input-input-field-container-color-border-error-hover focus-within:!border-component-input-input-field-container-color-border-error-enabled'
                                 : '!border-component-input-input-field-container-color-border-neutral-enabled hover:!border-component-input-input-field-container-color-border-neutral-hover focus-within:!border-component-input-input-field-container-color-border-neutral-focused'
                           }
                  `.replace(/\s+/g, ' ')}
            >
               {leadingIcon && (
                  <div
                     data-testid="input-field-leading-icon"
                     className={`w-component-data-display-icon-container-sizing-size-body m-auto flex aspect-square h-full items-center justify-center ${
                        disabled ? 'text-component-input-input-field-icon-start-color-text-on-surface-disabled' : 'text-component-input-input-field-icon-start-color-text-on-surface-enabled'
                     }`.replace(/\s+/g, ' ')}
                  >
                     <Icon icon={leadingIcon} />
                  </div>
               )}
               <div className="flex h-full flex-1 items-center">
                  <input
                     data-testid="input-field-input"
                     autoComplete={autocomplete}
                     className="text-component-input-input-field-input-color-text-on-surface-enabled disabled:text-component-input-input-field-input-color-text-on-surface-disabled typography-component-typography-body-regular-md w-full bg-transparent outline-none"
                     disabled={disabled}
                     id={id}
                     max={max}
                     maxLength={maxLength}
                     min={min}
                     name={id}
                     onBlur={/* istanbul ignore next */ () => setFocused(false)}
                     onChange={handleChange}
                     onFocus={/* istanbul ignore next */ () => setFocused(true)}
                     onKeyUp={handleKeyPress}
                     readOnly={readOnly}
                     ref={inputRef}
                     required={required}
                     step={step}
                     type={type}
                     value={inputValue}
                  />
               </div>
               {suffix && (
                  <div data-testid="input-field-suffix" className={`flex items-center ${disabled ? 'text-component-input-input-field-suffix-color-text-disabled' : 'text-component-input-input-field-suffix-color-text-enabled'}`}>
                     <Typography variant="body" color="inherit">
                        {suffix}
                     </Typography>
                  </div>
               )}
               {clearable && inputValue !== '' && (
                  <div
                     data-testid="input-field-clear"
                     onClick={handleClear}
                     role="presentation"
                     className={`w-component-data-display-icon-container-sizing-size-body m-auto flex aspect-square h-full items-center justify-center cursor-pointer ${
                        disabled ? 'text-component-input-search-icon-end-color-text-on-surface-disabled' : 'text-component-input-search-icon-end-color-text-on-surface-enabled'
                     }`.replace(/\s+/g, ' ')}
                  >
                     <Icon icon="times-circle" />
                  </div>
               )}
               {trailingIcon && (
                  <div
                     data-testid="input-field-trailing-icon"
                     className={`w-component-data-display-icon-container-sizing-size-body m-auto flex aspect-square h-full items-center justify-center ${
                        disabled ? 'text-component-input-input-field-icon-end-color-text-on-surface-disabled' : 'text-component-input-input-field-icon-end-color-text-on-surface-enabled'
                     }`.replace(/\s+/g, ' ')}
                  >
                     <Icon icon={trailingIcon} />
                  </div>
               )}
            </div>
         </div>
         {supportText && (
            <div
               data-testid="input-field-support-text"
               className={`pl-component-input-input-field-supporting-text-spacing-padding-left gap-component-input-input-field-supporting-text-spacing-gap flex items-center

                        ${!disabled ? 'text-component-input-input-field-supporting-text-color-text-neutral-enabled' : ''}
                        ${disabled ? 'text-component-input-input-field-supporting-text-color-text-neutral-disabled' : ''}
                        `.replace(/\s+/g, ' ')}
            >
               {supportText && (
                  <Typography variant="caption" color="inherit">
                     {supportText}
                  </Typography>
               )}
            </div>
         )}
         {error && errorMessage && (
            <div data-testid="input-field-error-message" className="pl-component-input-input-field-supporting-text-spacing-padding-left gap-component-input-input-field-supporting-text-spacing-gap text-component-input-input-field-supporting-text-color-text-error flex items-center">
               <Icon icon="exclamation-circle" size="caption" />
               <Typography variant="caption" color="inherit">
                  {errorMessage}
               </Typography>
            </div>
         )}
      </div>
   );
};
