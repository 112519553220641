import { User, UserGroups } from '@ravago/shared/authentication';

export class UserUtils {
   static getUserType(user: User | undefined): string {
      if (!user) return 'anonymous';
      const { groups } = user;

      if (groups.includes(UserGroups.digitalPlatformGuest)) {
         return 'guest';
      }

      if (groups.includes(UserGroups.digitalPlatformPendingVerification)) {
         return 'pending';
      }

      return 'verified';
   }
}
