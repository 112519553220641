import { FC, ReactNode } from 'react';

import classNames from 'classnames';

import { Icon } from '../../../icon/components/Icon/Icon';
import { Typography } from '../../../typography/components/Typography/Typography';

interface Props {
   type?: ReactNode;
   label?: ReactNode;
   leadingIcon?: string;
   variant?: 'regular' | 'selected';
   onClick?: () => void;
   onDelete?: () => void;
   disabled?: boolean;
}

export const Chip: FC<Props> = ({ type, label, leadingIcon, variant = 'regular', onClick, onDelete, disabled = false }) => (
   <div
      role="presentation"
      data-testid={`chip${onClick ? '--clickable' : ''}${disabled ? '--disabled' : ''}--${variant}`}
      onClick={onClick}
      className={classNames(
         'rounded-component-data-display-chip-container-border-radius',
         variant === 'regular' && 'border-component-data-display-chip-container-border-width-default bg-component-data-display-chip-container-color-background-enabled',
         variant === 'selected' && 'border-component-data-display-chip-container-border-width-selected bg-component-data-display-chip-container-color-background-selected',
         'border-component-data-display-chip-container-color-border-enabled',
         'focus-visible:border-component-data-display-chip-container-color-border-focused',
         'group inline-flex overflow-hidden',
         onClick ? 'cursor-pointer' : 'cursor-default'
      )}
   >
      <div
         className={classNames(
            'px-component-data-display-chip-container-spacing-padding-x-default',
            'py-component-data-display-chip-container-spacing-padding-y',
            'min-h-component-data-display-chip-container-sizing-min-height',
            'gap-component-data-display-chip-container-spacing-gap',
            'flex h-full w-full items-center transition-all',
            !disabled && onClick && 'group-hover:bg-component-data-display-chip-state-layer-color-background-hover group-focus-visible:bg-component-data-display-chip-state-layer-color-background-focused group-active:bg-component-data-display-chip-state-layer-color-background-pressed',
            onDelete && 'pr-component-data-display-chip-container-spacing-padding-x-icon',
            leadingIcon && 'pl-component-data-display-chip-container-spacing-padding-x-icon'
         )}
      >
         <div className="gap-component-data-display-chip-container-spacing-inner-gap flex items-baseline">
            {leadingIcon && (
               <div
                  data-testid="chip--leading-icon"
                  className={classNames(
                     disabled && variant === 'regular' && 'text-component-data-display-chip-label-right-color-text-on-disabled',
                     !disabled && variant === 'regular' && 'text-component-data-display-chip-label-right-color-text-on-surface',
                     variant === 'selected' && 'text-component-data-display-chip-label-right-color-text-on-selected'
                  )}
               >
                  <Icon icon={leadingIcon} />
               </div>
            )}
            {type && (
               <span
                  data-testid="chip--type"
                  className={classNames(
                     disabled && variant === 'regular' && 'text-component-data-display-chip-label-left-color-text-on-disabled',
                     !disabled && variant === 'regular' && 'text-component-data-display-chip-label-left-color-text-primary',
                     variant === 'selected' && 'text-component-data-display-chip-label-left-color-text-on-selected'
                  )}
               >
                  <Typography color="inherit" variant="small-highlight">
                     {type}
                  </Typography>
               </span>
            )}
            {label && (
               <span
                  data-testid="chip--label"
                  className={classNames(
                     disabled && variant === 'regular' && 'text-component-data-display-chip-label-right-color-text-on-disabled',
                     !disabled && variant === 'regular' && 'text-component-data-display-chip-label-right-color-text-on-surface',
                     variant === 'selected' && 'text-component-data-display-chip-label-right-color-text-on-selected'
                  )}
               >
                  <Typography color="inherit" variant="small">
                     {label}
                  </Typography>
               </span>
            )}
         </div>
         {onDelete && (
            <button
               data-testid="chip--remove"
               type="button"
               onClick={onDelete}
               disabled={disabled}
               className={classNames(
                  'transition-all',
                  disabled && variant === 'regular' && 'text-component-data-display-chip-label-icon-end-color-text-on-disabled',
                  !disabled && variant === 'regular' && 'text-component-data-display-chip-label-icon-end-color-text-on-surface',
                  variant === 'selected' && 'text-component-data-display-chip-label-icon-color-text-on-primary'
               )}
            >
               <Icon icon="times" />
            </button>
         )}
      </div>
   </div>
);
