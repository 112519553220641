import { useContext } from 'react';

import { DialogContext } from '../providers/dialog-context-providers';

export const useDialogContext = () => {
   const context = useContext(DialogContext);
   if (!context) {
      throw new Error('useDialogContext must be used within a DialogContext');
   }
   return context;
};
