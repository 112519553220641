'use client';

import { createContext, Dispatch, FC, PropsWithChildren, useMemo, useReducer } from 'react';

import { DialogAction, DialogReducer, DialogState, initialState } from '../reducers/dialog-reducer';

export const DialogContext = createContext<{ state: DialogState; dispatch?: Dispatch<DialogAction> }>({
   state: initialState,
   dispatch: undefined
});

export const DialogProviders: FC<PropsWithChildren> = ({ children }) => {
   const [state, dispatch] = useReducer(DialogReducer, initialState);

   const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

   return <DialogContext.Provider value={contextValue}>{children}</DialogContext.Provider>;
};
