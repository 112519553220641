import { CallOffOrderDetail, Quantity } from '@ravago/shared/page-data/models/elements';
import { Address } from '@ravago/shared/page-data/models/elements/address.model';
import { Customer } from '@ravago/shared/page-data/models/features';

export interface CallOffAgreementState {
   callOffId?: string;
   callOffOrderDetail?: CallOffOrderDetail;
   deliveryAddress?: Address;
   customer?: Customer;
   deliveryDate?: string;
   quantity?: Quantity;
   additionalInfo?: string;
   openedFrom?: 'dynamic-product-block';
}

export interface CallOffAgreementAction {
   type:
      | 'set_call_off'
      | 'clear_call_off'
      | 'set_call_off_id'
      | 'set_call_off_quantity'
      | 'set_call_off_delivery_date'
      | 'set_opened_from'
      | 'set_call_off_additional_info';
   value: Partial<CallOffAgreementState>;
}

export const initialState: CallOffAgreementState = {
   callOffId: undefined,
   callOffOrderDetail: undefined,
   deliveryAddress: undefined,
   customer: undefined,
   deliveryDate: undefined,
   quantity: undefined,
   additionalInfo: undefined,
   openedFrom: undefined
};

export const CallOffAgreementReducer = (state: CallOffAgreementState, action: CallOffAgreementAction) => {
   switch (action.type) {
      case 'set_call_off':
         return {
            ...state,
            callOffOrderDetail: action.value.callOffOrderDetail,
            deliveryAddress: action.value.deliveryAddress,
            customer: action.value.customer,
            deliveryDate: action.value.deliveryDate,
            quantity: action.value.quantity,
            additionalInfo: action.value.additionalInfo
         };
      case 'set_call_off_id':
         return {
            ...state,
            callOffId: action.value.callOffId
         };
      case 'set_call_off_quantity':
         return {
            ...state,
            quantity: action.value.quantity
         };
      case 'set_call_off_delivery_date':
         return {
            ...state,
            deliveryDate: action.value.deliveryDate
         };
      case 'set_call_off_additional_info':
         return {
            ...state,
            additionalInfo: action.value.additionalInfo
         };
      case 'set_opened_from':
         return {
            ...state,
            openedFrom: action.value.openedFrom
         };
      case 'clear_call_off':
         return initialState;
      default:
         return state;
   }
};
