'use client';

import { ReactNode } from 'react';

import toast, { ToastOptions } from 'react-hot-toast';

import { Toast } from '../../components/toast/components/Toast/Toast';

export interface NotificationOptions {
   duration?: number;
   position?: ToastOptions['position'];
   action?: string;
   actionClick?: () => void;
   closable?: boolean;
}

/**
 * An object with callable functions to display notifications using the react-hot-toast library.
 * Make sure to include the <NotificationProvider /> component in your application.
 *
 * @returns A function that displays a toast notification.
 * @example
 * import notification from '@ravago/shared/radiance/utils/notifications/notifications';
 *
 * notification('This is a notification message', { duration: 10000, position: 'bottom-left' });
 * */
const notification = (message: ReactNode, options?: NotificationOptions) => {
   /* istanbul ignore next */
   toast.custom(
      (t) => (
         <Toast action={options?.action} onActionClick={options?.actionClick} onClose={options?.closable ? () => toast.remove(t.id) : undefined}>
            {message}
         </Toast>
      ),
      {
         duration: options?.duration ?? 10000,
         position: options?.position ?? 'bottom-left'
      }
   );
};

export default notification;
