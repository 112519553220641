'use client';

import { createContext, PropsWithChildren, useCallback, useMemo, useState } from 'react';

import { DropdownOption } from '@ravago/shared/radiance/components/input-field/components/Dropdown/Dropdown';

export interface FormFeedback {
   category?: DropdownOption;
   feedback?: string;
}

export interface DialogFormProps {
   customerFeedback: FormFeedback;
   setFeedback: (feedback: FormFeedback) => void;
}

export const DialogFormContext = createContext<DialogFormProps | null>(null);

export const DialogFormProvider = ({ children }: PropsWithChildren) => {
   const [customerFeedback, setCustomerFeedback] = useState<FormFeedback>({});

   const setFeedback = useCallback((feedback: FormFeedback) => {
      setCustomerFeedback(feedback);
   }, []);

   const formContext: DialogFormProps = useMemo(
      () => ({
         customerFeedback,
         setFeedback
      }),
      [setFeedback, customerFeedback]
   );

   return <DialogFormContext.Provider value={formContext}>{children}</DialogFormContext.Provider>;
};
