'use client';

import { useContext } from 'react';

import { WidgetContext } from '../context/widget-context';

export const useProductCompare = () => {
   const {
      productsToCompareList = [],
      addProductsToCompare: addProductToCompareList = () => {},
      removeProductFromCompare: removeProductFromCompareList = () => {},
      removeAllProductsFromCompareList = () => {}
   } = useContext(WidgetContext) ?? {};

   return { productsToCompareList, addProductToCompareList, removeProductFromCompareList, removeAllProductsFromCompareList };
};
