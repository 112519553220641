'use client';

import { FC, ReactNode, useMemo, useRef, useState } from 'react';

import { arrow, FloatingArrow, FloatingPortal, offset, Placement, shift, useDismiss, useFloating, useFocus, useHover, useInteractions, useRole, useTransitionStyles } from '@floating-ui/react';

import classNames from 'classnames';

interface Props {
   direction?: 'top' | 'bottom' | 'left' | 'right';
   pointers?: boolean;
   align?: 'start' | 'center' | 'end';
   whiteSpace?: 'normal' | 'nowrap';
   limitWidth?: boolean;
   text: string;
   children: ReactNode;
}

export const Tooltip: FC<Props> = ({ direction = 'top', whiteSpace = 'normal', pointers = true, align = 'center', limitWidth = true, text, children }) => {
   const [open, setOpen] = useState(false);
   const arrowRef = useRef(null);

   const placement: Placement | undefined = useMemo(() => {
      if (direction && (align === 'end' || align === 'start')) return `${direction}-${align}` as Placement;
      return direction;
   }, [direction, align]);

   const { context, refs } = useFloating({
      placement,
      open,
      onOpenChange: setOpen,
      middleware: [
         offset(5),
         shift({ padding: 5 }),
         pointers &&
            arrow({
               element: arrowRef,
               padding: 5
            })
      ]
   });

   const { isMounted, styles } = useTransitionStyles(context);

   const hover = useHover(context, {
      move: false
   });
   const focus = useFocus(context);
   const dismiss = useDismiss(context);
   const role = useRole(context, { role: 'tooltip' });

   const { getReferenceProps, getFloatingProps } = useInteractions([hover, focus, dismiss, role]);

   return (
      <>
         <span ref={refs.setReference} {...getReferenceProps()} data-state={context.open ? 'open' : 'closed'}>
            {children}
         </span>

         {isMounted && (
            <FloatingPortal>
               <div
                  ref={refs.setFloating}
                  {...getFloatingProps()}
                  style={{
                     ...context.floatingStyles,
                     ...styles
                  }}
                  className={classNames(
                     'text-center px-component-data-display-tooltip-container-spacing-padding-x py-component-data-display-tooltip-container-spacing-padding-y min-h-component-data-display-tooltip-container-sizing-min-height bg-component-data-display-tooltip-container-color-background text-component-data-display-tooltip-label-color-text rounded-component-data-display-tooltip-container-border-radius z-[9999]',
                     {
                        'max-w-component-data-display-tooltip-container-sizing-max-width': limitWidth
                     }
                  )}
               >
                  {text}
                  {pointers && <FloatingArrow ref={arrowRef} context={context} className="fill-component-data-display-tooltip-container-color-background" />}
               </div>
            </FloatingPortal>
         )}
      </>
   );
};
