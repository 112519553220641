'use client';

import { FC, ReactNode, useState, useEffect } from 'react';

import classNames from 'classnames';

import { useBodyOverflowHidden } from '../../../../hooks/useBodyOverflowHidden/useBodyOverflowHidden';

interface Props {
   open?: boolean;
   position?: 'left' | 'right' | 'bottom';
   closeOnOutsideClick?: boolean;
   width?: string;
   onFlyoutClose?: () => void;
   disableGutters?: boolean;
   children: ReactNode | Array<ReactNode>;
}

export const Flyout: FC<Props> = ({ open = false, position = 'right', closeOnOutsideClick = true, width, onFlyoutClose, disableGutters = false, children }) => {
   const [isOpen, setIsOpen] = useState(open);

   useEffect(() => setIsOpen(open), [open]);

   useBodyOverflowHidden(isOpen);

   /* istanbul ignore next */
   const handleFlyoutClose = () => {
      if (!closeOnOutsideClick) return;

      onFlyoutClose?.();
   };

   return (
      <>
         <div data-testid="flyout-backdrop" className={`${open ? 'bg-component-surfaces-flyout-background-color-scrimmed fixed z-[80]' : '-z-10 bg-transparent'}  inset-0 transition-colors`} onClick={handleFlyoutClose} role="presentation" />
         <div
            data-testid={`flyout--${open ? 'open' : 'closed'}${disableGutters ? '--no-gutters' : ''}`}
            role="presentation"
            style={{ width }}
            className={classNames(
               'bg-component-surfaces-flyout-container-color-background border-component-color-flyout-border fixed z-[9999] transition-all',
               !disableGutters && 'px-component-surfaces-flyout-container-spacing-padding-x py-component-surfaces-flyout-container-spacing-padding-y',
               position === 'right' && !open && 'translate-x-full',
               position === 'left' && !open && '-translate-x-full',
               position === 'bottom' && !open && 'translate-y-full',
               open ? 'shadow-component-surfaces-flyout-container-box-shadow' : 'overflow-hidden',
               position === 'right' && 'right-0 rounded-l-component-surfaces-flyout-container-border-radius',
               position === 'left' && 'left-0 rounded-r-component-surfaces-flyout-container-border-radius',
               (position === 'left' || position === 'right') && 'min-w-component-surfaces-flyout-container-sizing-min-width max-w-component-surfaces-flyout-container-sizing-max-width inset-y-0',
               position === 'bottom' && 'bottom-0 rounded-t-component-surfaces-flyout-container-border-radius h-component-surfaces-flyout-container-sizing-max-height inset-x-0'
            )}
         >
            {children}
         </div>
      </>
   );
};
