import { FC, ReactNode } from 'react';

import classNames from 'classnames';

import { Box } from '../../../box/components/Box/Box';

import styles from './Table.module.scss';

interface Props {
   children: ReactNode;
   stroked?: 'none' | 'horizontal';
   snapToColumn?: boolean;
   height?: string | number;
   tableLayout?: 'auto' | 'fixed';
   border?: boolean;
   hover?: boolean;
   rounded?: boolean;
   rowDividers?: boolean;
   stickyOption?: ReactNode;
}

export const Table: FC<Props> = ({ children, stroked = 'none', snapToColumn = false, height = 'auto', tableLayout = 'auto', rounded = false, border = false, hover = false, rowDividers = true, stickyOption }) => (
   <>
      {stickyOption && (
         <Box position="absolute" top="0" right="0">
            <div data-testid="table-sticky-option" className="border-component-data-display-table-border-width border-component-data-display-table-color-border shadow-theme-box-shadow-default">
               {stickyOption}
            </div>
         </Box>
      )}
      <div
         data-testid={`table${rounded ? '--rounded' : ''}${border ? '--border' : ''}${hover ? '--hover' : ''}${!rowDividers ? '--no-row-dividers' : ''}`}
         style={{ height }}
         className={classNames(snapToColumn && 'snap-x snap-mandatory overflow-auto', rounded && 'rounded-component-data-display-table-border-radius', border && 'border-component-data-display-table-border-width border-component-data-display-table-color-border')}
      >
         <table
            className={classNames(
               'bg-component-data-display-table-cell-color-background-regular-default w-full',
               border && styles.tableBorder,
               hover && styles.tableHover,
               rounded && styles.tableRounded,
               rowDividers && styles.tableRowDividers,
               stroked === 'horizontal' && styles.tableStrokedHorizontal,
               tableLayout === 'auto' && 'table-auto',
               tableLayout === 'fixed' && 'table-fixed'
            )}
         >
            {children}
         </table>
      </div>
   </>
);
