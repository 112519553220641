import { SalesOrder, Status } from '@ravago/shared/page-data/models/elements';

import { DateUtils } from '../date/date.utils';

import { OrderMapperUtils } from './order-mapper.utils';

export class OrderUtils {
   static async getOrderHeader<T>(baseUrl: string, accessToken: string, orderId: number): Promise<T> {
      const url = `${baseUrl}/customer/orders/${orderId}`;
      return fetch(url, {
         method: 'GET',
         headers: {
            'Content-Type': 'application/json',
            'User-Agent': 'Ravago-bot-eui9K6Lq-dp',
            'X-ravago-authenticationToken': accessToken
         },
         cache: 'no-cache'
      })
         .then((response) => response.json())
         .then((data) => {
            if (data.error) {
               return undefined;
            }
            return data;
         })
         .catch(() => undefined);
   }

   static async getOrderDetailsDocuments<T>(baseUrl: string, accessToken: string, orderId: number): Promise<T> {
      const url = `${baseUrl}/customer/orders/${orderId}/documents`;
      return fetch(url, {
         method: 'GET',
         headers: {
            'Content-Type': 'application/json',
            'User-Agent': 'Ravago-bot-eui9K6Lq-dp',
            'X-ravago-authenticationToken': accessToken
         },
         cache: 'no-cache'
      })
         .then((response) => response.json())
         .catch(() => undefined);
   }

   static async getOrders<T>(baseUrl: string, accessToken: string, isOpen: boolean): Promise<T> {
      const orderedAfter = DateUtils.getStartOfYearFromCurrentInUtc(2).toString();
      const url = `${baseUrl}/customer/orders?page=0&size=10000&isOpen=${isOpen}&orderedAfter=${orderedAfter}`;
      return fetch(url, {
         method: 'GET',
         headers: {
            'Content-Type': 'application/json',
            'User-Agent': 'Ravago-bot-eui9K6Lq-dp',
            'X-ravago-authenticationToken': accessToken
         },
         cache: 'no-cache'
      })
         .then((response) => response.json())
         .then((data) => {
            if (data.error) {
               return undefined;
            }
            return data.content.map((order: SalesOrder) => OrderMapperUtils.convertFromSalesOrder(order));
         })
         .catch(() => undefined);
   }

   static getStatusTranslation(status: Status): string {
      const mapToTranslation: { [key in Status]: string } = {
         'Order Received': 'order.status.order-received',
         Planned: 'order.status.processing',
         Processing: 'order.status.processing',
         'Order Confirmed': 'shipment.status.order-confirmed',
         'Transport Arranged': 'order.status.transport-arranged',
         'In Transit': 'order.status.in-transit',
         Closed: 'order.status.closed',
         Invoiced: 'order.status.invoiced',
         Credited: 'order.status.credited',
         'Awaiting Call Off': 'order.status.awaiting-call-off',
         'Call Off Overdue': 'order.status.call-off-overdue'
      };
      return mapToTranslation[status];
   }
}
