import { FC, ReactNode } from 'react';

import { AspectRatio } from '@ravago/shared/radiance/components/aspect-ratio/components/AspectRatio/AspectRatio';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import styles from './empty-state.component.module.scss';

interface Props {
   label?: string | string[];
   content?: ReactNode;
   icon?: string;
   title?: string;
   image?: ReactNode;
   button?: ReactNode;
   aspectRatioWidth?: string;
}

export const EmptyState: FC<Props> = ({ label, icon, title = '', image, button, content, aspectRatioWidth }) => {
   const iconTemplate = () => {
      if (!icon) return null;
      return (
         <div className={styles.EmptyState__Icon}>
            <div className={styles.EmptyState__IconFree}>
               <i className={`fa fa-${icon}`} />
            </div>
            <div className={styles.EmptyState__IconQuestion}>
               <i className="fa fa-question" />
            </div>
         </div>
      );
   };

   return (
      <Box direction="column" items="center" gap="lg" margin="lg">
         {icon && <div className={styles.EmptyState__IconContainer}>{iconTemplate()}</div>}
         {!!image && (
            <Box maxWidth="500px">
               <AspectRatio phone="21/9" tablet="32/9" desktop="16/9" width={aspectRatioWidth ?? '100%'}>
                  {image}
               </AspectRatio>
            </Box>
         )}
         {title !== '' && (
            <Typography component="h3" variant="heading-3">
               {title}
            </Typography>
         )}
         {content && content}
         {label && Array.isArray(label) && (
            <Box gap="none" direction="column">
               {label
                  .filter((item, index) => label.indexOf(item) === index)
                  .map((string) => (
                     <Typography component="p" variant="body" align="center" key={string}>
                        {/* eslint-disable-next-line react/no-danger */}
                        <span dangerouslySetInnerHTML={{ __html: string }} />
                     </Typography>
                  ))}
            </Box>
         )}
         {typeof label === 'string' && label !== '' && (
            <Typography component="p" variant="body" align="center">
               {/* eslint-disable-next-line react/no-danger */}
               <span dangerouslySetInnerHTML={{ __html: label }} />
            </Typography>
         )}
         {!!button && button}
      </Box>
   );
};
