import { FC } from 'react';

import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

interface Props {
   title?: string;
   translate: (key: string, placeholders?: { [key: string]: any }) => string;
}

export const ProductFlyoutProductTitle: FC<Props> = ({ title, translate }: Props) => (
   <Box direction="column" gap="2xs">
      <Typography component="span" variant="body-highlight">
         {translate('page-consumer.request-for-quotation.product')}
      </Typography>
      <Typography component="span" variant="body">
         {title}
      </Typography>
   </Box>
);
