'use client';

import { FC, useRef, useState } from 'react';

import dataAttributes from '../../../../utils/attributes/attributes';
import Format from '../../../../utils/format/format';
import { Box } from '../../../box/components/Box/Box';
import { Icon } from '../../../icon/components/Icon/Icon';
import { List } from '../../../list/components/List/List';
import { ListItem } from '../../../list/components/ListItem/ListItem';
import { Menu } from '../../../menu/components/Menu/Menu';
import { Typography } from '../../../typography/components/Typography/Typography';

export interface DropdownOption {
   id: string;
   value: string;
   supportingText?: string;
   icon?: string;
}

interface Props {
   id: string;
   clearable?: boolean;
   disabled?: boolean;
   emptyOption?: boolean;
   error?: boolean;
   errorMessage?: string;
   label?: string;
   leadingIcon?: string;
   iconType?: 'flag' | 'default';
   options: Array<DropdownOption>;
   readOnly?: boolean;
   required?: boolean;
   supportText?: string;
   trailingIcon?: string;
   value?: DropdownOption;
   width?: string | number;
   attributes?: Record<string, string>;
   onClear?: () => void;
   onChange?: (value?: DropdownOption) => void;
}

export const Dropdown: FC<Props> = ({ id, options, clearable = false, disabled = false, emptyOption = true, error = false, errorMessage, label, leadingIcon, iconType = 'default', readOnly = false, required = false, supportText, trailingIcon, value, width, attributes = {}, onClear, onChange }) => {
   const [menuOpen, setMenuOpen] = useState(false);

   const inputRef = useRef<HTMLInputElement>(null);

   /* istanbul ignore next */
   const handleChange = (option?: DropdownOption) => {
      onChange?.(option);
      setMenuOpen(false);
   };

   const handleClear = () => {
      setMenuOpen(true);
      onChange?.();
      onClear?.();
   };

   const handleToggle = () => {
      setMenuOpen((curr) => !curr);
   };

   return (
      <div
         data-testid={`dropdown${error ? '--error' : ''}${readOnly ? '--read-only' : ''}${menuOpen ? '--open' : ''}`}
         style={{ width }}
         className={`gap-component-input-dropdown-container-spacing-outer-gap flex w-full flex-col ${disabled || readOnly ? 'pointer-events-none' : ''}`}
         {...dataAttributes(attributes)}
      >
         <Menu
            content={
               <div data-testid="dropdown-menu" className="overflow-y-auto">
                  <List>
                     {emptyOption && <ListItem role="option" ariaSelected={!!value && value.value === undefined} onClick={/* istanbul ignore next */ () => handleChange()} />}
                     {options.map((option) => (
                        <ListItem key={option.id} attributes={{ cy: Format('dropdown-option', option.value) }} role="option" ariaSelected={option.value === value?.value} onClick={/* istanbul ignore next */ () => handleChange(option)} supportingText={option.supportingText}>
                           <Box gap="sm" items="center">
                              {option.icon && <Icon icon={option.icon} size="small" type={iconType} />}

                              <Typography whiteSpace="nowrap">{option.value}</Typography>
                           </Box>
                        </ListItem>
                     ))}
                  </List>
               </div>
            }
            open={menuOpen}
            maxHeight
            width="fill"
            onMenuClose={/* istanbul ignore next */ () => setMenuOpen(false)}
         >
            <div className="group relative w-full cursor-pointer">
               {label && (
                  <label
                     data-testid="dropdown-label"
                     htmlFor={id}
                     style={{
                        maxWidth:
                           value || menuOpen
                              ? 'calc(100% -  2 * var(--component-input-dropdown-container-spacing-padding-x))'
                              : 'calc(100% - 2* var(--component-input-dropdown-container-spacing-padding-x) - var(--component-input-dropdown-container-spacing-gap) - var(--component-data-display-icon-container-sizing-width-body))'
                     }}
                     className={`absolute left-0 top-0 flex items-center transition-all cursor-pointer
                           ${!error && !disabled && (value || menuOpen) ? 'text-component-input-dropdown-label-color-text-neutral-enabled-as-label' : ''}
                           ${!error && !disabled ? 'group-focus-within:text-component-input-dropdown-label-color-text-neutral-focused' : ''}
                           ${!error && !disabled && menuOpen ? 'text-component-input-dropdown-label-color-text-neutral-focused' : ''}
                           ${!error && !disabled && !value && !menuOpen ? 'text-component-input-dropdown-label-color-text-neutral-enabled-as-placeholder' : ''}
                           ${disabled ? 'text-component-input-dropdown-label-color-text-neutral-disabled' : ''}
                           ${error && !disabled ? 'text-component-input-dropdown-label-color-text-error-enabled group-hover:!text-component-input-dropdown-label-color-text-error-hover' : ''}
                           ${
                              value || menuOpen
                                 ? 'bg-component-input-dropdown-container-color-background px-component-input-dropdown-label-spacing-padding-x h-[var(--component-input-dropdown-container-border-width-enabled)] group-focus-within:h-[var(--component-input-dropdown-container-border-width-focused)]'
                                 : 'h-full'
                           }
                           ${menuOpen ? 'h-[var(--component-input-dropdown-container-border-width-focused)]' : ''}
                           ${
                              !value && leadingIcon && !menuOpen
                                 ? 'phone:ml-[calc(var(--component-input-dropdown-container-spacing-padding-x)+var(--component-input-dropdown-container-spacing-gap)+var(--component-data-display-icon-container-sizing-width-mobile-body))] tab-port:ml-[calc(var(--component-input-dropdown-container-spacing-padding-x)+var(--component-input-dropdown-container-spacing-gap)+var(--component-data-display-icon-container-sizing-width-tablet-body))] tab-land:ml-[calc(var(--component-input-dropdown-container-spacing-padding-x)+var(--component-input-dropdown-container-spacing-gap)+var(--component-data-display-icon-container-sizing-width-tablet-body))] ml-[calc(var(--component-input-dropdown-container-spacing-padding-x)+var(--component-input-dropdown-container-spacing-gap)+var(--component-data-display-icon-container-sizing-width-desktop-body))]'
                                 : 'ml-component-input-dropdown-container-spacing-padding-x'
                           }
                      `.replace(/\s+/g, ' ')}
                  >
                     <Typography variant={value || menuOpen ? 'caption' : 'body'} color="inherit" maxLines="1">
                        {label}
                        {required && '*'}
                     </Typography>
                  </label>
               )}

               <div
                  data-testid="dropdown-container"
                  role="presentation"
                  className={`min-h-component-input-dropdown-container-sizing-min-height px-component-input-dropdown-container-spacing-padding-x py-component-input-dropdown-container-spacing-padding-y gap-component-input-dropdown-container-spacing-gap bg-component-input-dropdown-container-color-background rounded-component-input-dropdown-container-border-radius border-component-input-dropdown-container-border-width-enabled focus-within:!border-component-input-dropdown-container-border-width-focused box-border flex items-start justify-between transition-all
                           ${
                              error
                                 ? '!border-component-input-dropdown-container-color-border-error-enabled hover:!border-component-input-dropdown-container-color-border-error-hover focus-within:!border-component-input-dropdown-container-color-border-error-enabled'
                                 : '!border-component-input-dropdown-container-color-border-neutral-enabled hover:!border-component-input-dropdown-container-color-border-neutral-hover focus-within:!border-component-input-dropdown-container-color-border-neutral-focused'
                           }
                           ${menuOpen ? '!border-component-input-dropdown-container-border-width-focused' : ''}
                           ${menuOpen && error ? '!border-component-input-dropdown-container-color-border-error-enabled' : ''}
                           ${menuOpen && !error ? '!border-component-input-dropdown-container-color-border-neutral-enabled' : ''}
                  `.replace(/\s+/g, ' ')}
                  onClick={handleToggle}
               >
                  {leadingIcon && (
                     <div
                        data-testid="dropdown-leading-icon"
                        className={`w-component-data-display-icon-container-sizing-size-body m-auto flex aspect-square h-full items-center justify-center ${
                           disabled ? 'text-component-input-dropdown-icon-start-color-text-on-surface-disabled' : 'text-component-input-dropdown-icon-start-color-text-on-surface-enabled'
                        }`.replace(/\s+/g, ' ')}
                     >
                        <Icon icon={leadingIcon} type={iconType} />
                     </div>
                  )}
                  <div className="flex h-full flex-1 items-center">
                     <input
                        data-testid="dropdown-input"
                        className="text-component-input-dropdown-input-color-text-on-surface-enabled disabled:text-component-input-dropdown-input-color-text-on-surface-disabled typography-component-typography-body-regular-md w-full cursor-pointer bg-transparent outline-none"
                        disabled={disabled}
                        id={id}
                        name={id}
                        readOnly
                        ref={inputRef}
                        required={required}
                        value={value?.value ?? ''}
                     />
                  </div>
                  {clearable && value && (
                     <div
                        data-testid="dropdown-clear"
                        onClick={handleClear}
                        role="presentation"
                        className={`w-component-data-display-icon-container-sizing-size-body m-auto flex aspect-square h-full items-center justify-center cursor-pointer ${
                           disabled ? 'text-component-input-search-icon-end-color-text-on-surface-disabled' : 'text-component-input-search-icon-end-color-text-on-surface-enabled'
                        }`.replace(/\s+/g, ' ')}
                     >
                        <Icon icon="times-circle" />
                     </div>
                  )}
                  {trailingIcon && (
                     <div
                        data-testid="dropdown-trailing-icon"
                        className={`w-component-data-display-icon-container-sizing-size-body m-auto flex aspect-square h-full items-center justify-center ${
                           disabled ? 'text-component-input-dropdown-icon-end-color-text-on-surface-disabled' : 'text-component-input-dropdown-icon-end-color-text-on-surface-enabled'
                        }`.replace(/\s+/g, ' ')}
                     >
                        <Icon icon={trailingIcon} />
                     </div>
                  )}
                  <div
                     data-testid="dropdown-chevron"
                     role="presentation"
                     className={`w-component-data-display-icon-container-sizing-size-body m-auto flex aspect-square h-full items-center justify-center transition-all ${
                        disabled ? 'text-component-input-dropdown-icon-end-color-text-on-surface-disabled' : 'text-component-input-dropdown-icon-end-color-text-on-surface-enabled'
                     } ${menuOpen ? 'rotate-180' : ''}`.replace(/\s+/g, ' ')}
                  >
                     <Icon icon="chevron-down" />
                  </div>
               </div>
            </div>
         </Menu>
         {supportText && (
            <div
               data-testid="dropdown-support-text"
               className={`pl-component-input-dropdown-supporting-text-spacing-padding-left gap-component-input-dropdown-supporting-text-spacing-gap flex items-center

                        ${!disabled ? 'text-component-input-dropdown-supporting-text-color-text-neutral-enabled' : ''}
                        ${disabled ? 'text-component-input-dropdown-supporting-text-color-text-neutral-disabled' : ''}
                        `.replace(/\s+/g, ' ')}
            >
               {supportText && (
                  <Typography variant="caption" color="inherit">
                     {supportText}
                  </Typography>
               )}
            </div>
         )}
         {error && errorMessage && (
            <div data-testid="dropdown-error-message" className="pl-component-input-dropdown-supporting-text-spacing-padding-left gap-component-input-dropdown-supporting-text-spacing-gap text-component-input-dropdown-supporting-text-color-text-error flex items-center">
               <Icon icon="exclamation-circle" size="caption" />
               <Typography variant="caption" color="inherit">
                  {errorMessage}
               </Typography>
            </div>
         )}
      </div>
   );
};
