'use client';

/* eslint-disable react/button-has-type */
import { FC, MouseEvent } from 'react';

import classNames from 'classnames';

import dataAttributes from '../../../../utils/attributes/attributes';
import { Badge } from '../../../badge/components/Badge/Badge';
import { Icon } from '../../../icon/components/Icon/Icon';

interface Props {
   backdrop?: boolean;
   badge?: string;
   disabled?: boolean;
   icon: string;
   type?: 'button' | 'submit' | 'reset';
   variant?: 'on-light' | 'on-primary' | 'on-dark' | 'danger' | 'inherit';
   showGutters?: boolean;
   disableStateLayer?: boolean;
   attributes?: Record<string, string>;
   onClick?: (e: MouseEvent<HTMLDivElement>) => void;
}

const getBadgeVariant = (variant: 'on-light' | 'on-primary' | 'on-dark' | 'danger' | 'inherit') => {
   switch (variant) {
      case 'on-light':
         return 'primary';
      case 'on-primary':
         return 'secondary';
      default:
         return 'primary';
   }
};

const variantClassNames = {
   'on-light':
      'group-hover:bg-component-input-icon-button-state-layer-color-background-on-default-hover group-has[:focus-visible]:bg-component-input-icon-button-state-layer-color-background-on-default-focused group-active:bg-component-input-icon-button-state-layer-color-background-on-default-pressed',
   'on-primary':
      'group-hover:bg-component-input-icon-button-state-layer-color-background-on-primary-hover group-has[:focus-visible]:bg-component-input-icon-button-state-layer-color-background-on-primary-focused group-active:bg-component-input-icon-button-state-layer-color-background-on-primary-pressed',
   'on-dark': 'group-hover:bg-component-input-icon-button-state-layer-color-background-on-dark-hover group-has[:focus-visible]:bg-component-input-icon-button-state-layer-color-background-on-dark-focused group-active:bg-component-input-icon-button-state-layer-color-background-on-dark-pressed',
   danger: 'group-hover:bg-component-input-icon-button-state-layer-color-background-danger-hover group-has[:focus-visible]:bg-component-input-icon-button-state-layer-color-background-danger-focused group-active:bg-component-input-icon-button-state-layer-color-background-danger-pressed',
   inherit: 'group-hover:bg-component-input-icon-button-state-layer-color-background-on-default-hover group-has[:focus-visible]:bg-component-input-icon-button-state-layer-color-background-on-default-focused group-active:bg-component-input-icon-button-state-layer-color-background-on-default-pressed'
};

const variantColorClassNames = {
   enabled: {
      'on-light': 'text-component-input-icon-button-icon-color-fill-primary-enabled',
      'on-primary': 'text-component-input-icon-button-icon-color-fill-on-primary-enabled',
      'on-dark': 'text-component-input-icon-button-icon-color-fill-on-dark-enabled',
      danger: 'text-component-input-icon-button-icon-color-fill-danger-enabled',
      inherit: 'text-inherit'
   },
   disabled: {
      'on-light': 'text-component-input-icon-button-icon-color-fill-primary-disabled',
      'on-primary': 'text-component-input-icon-button-icon-color-fill-on-primary-disabled',
      'on-dark': 'text-component-input-icon-button-icon-color-fill-on-dark-disabled',
      danger: 'text-component-input-icon-button-icon-color-fill-danger-disabled',
      inherit: 'text-component-input-icon-button-icon-color-fill-primary-disabled'
   }
};

const variantBackdropClassNames = {
   'on-light': 'bg-component-input-icon-button-backdrop-color-fill-default',
   'on-primary': 'bg-component-input-icon-button-backdrop-color-fill-primary',
   'on-dark': 'bg-component-input-icon-button-backdrop-color-fill-dark',
   danger: 'bg-component-input-icon-button-backdrop-color-fill-default',
   inherit: 'bg-component-input-icon-button-backdrop-color-fill-default'
};

export const IconButton: FC<Props> = ({ backdrop = false, badge, disabled = false, icon, type = 'button', variant = 'on-light', showGutters = true, disableStateLayer = false, attributes = {}, onClick }) => {
   const badgeVariant = getBadgeVariant(variant);

   const handleClick = (e: MouseEvent<HTMLDivElement>) => !disabled && onClick?.(e);

   return (
      <div data-testid={`icon-button${showGutters ? '--contained' : ''}${disableStateLayer ? '--no-state-layer' : ''}`} {...dataAttributes(attributes)} className={classNames('relative inline-block', !showGutters && '-m-component-input-icon-button-touch-area-spacing-padding')}>
         <div
            data-testid="icon-button-container"
            role="presentation"
            onClick={handleClick}
            className={classNames(
               'p-[calc(var(--component-input-icon-button-touch-area-spacing-padding)-var(--component-input-icon-button-state-layer-spacing-padding))] min-h-component-input-icon-button-touch-area-sizing-min-size min-w-component-input-icon-button-touch-area-sizing-min-size inline-flex items-center justify-center',
               disabled ? 'pointer-events-none' : 'cursor-pointer',
               !disableStateLayer && 'group'
            )}
         >
            <button
               data-testid={`icon-button--${variant}${backdrop ? '--backdrop' : ''}`}
               disabled={disabled}
               type={type}
               className={classNames('rounded-component-input-icon-button-backdrop-border-radius flex items-center justify-center overflow-hidden', backdrop && !disabled && variantBackdropClassNames[variant])}
            >
               <div
                  data-testid={`icon-button-state-layer--${variant}`}
                  className={classNames(
                     'rounded-component-input-icon-button-state-layer-border-radius rounded-component-border-radius-icon-button-state-layer p-component-input-icon-button-state-layer-spacing-padding flex items-center justify-center transition-all',
                     !disableStateLayer && variantClassNames[variant],
                     disabled ? variantColorClassNames.disabled[variant] : variantColorClassNames.enabled[variant]
                  )}
               >
                  <Icon icon={icon} size="body" />
               </div>
            </button>

            {badge && (
               <div data-testid={`icon-button-badge--${badgeVariant}`} className="absolute right-[calc(var(--component-input-icon-button-badge-container-spacing-padding-right)/2)] top-0 translate-x-1/2">
                  <Badge variant={badgeVariant}>{badge}</Badge>
               </div>
            )}
         </div>
      </div>
   );
};
