import { CallOffPeriod, DeliveryDate } from '@ravago/shared/page-data/models/elements';

import { DayOfWeek, LocalDate, LocalDateTime, LocalTime, Period, TemporalAdjusters, ZoneOffset } from '@js-joda/core';

export class DateUtils {
   static localizeDate(
      value: string | Date,
      options: Intl.DateTimeFormatOptions = {
         year: 'numeric',
         month: '2-digit',
         day: '2-digit'
      },
      locale = navigator.language
   ): string {
      const date = value instanceof Date ? value : new Date(value);
      return date.toLocaleString(locale, options).split('-').join('/');
   }

   static transformOrderDeliveryDate(date: DeliveryDate) {
      const { from, until, type } = date;
      if (!from && !until) return undefined;

      const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit', timeZone: 'GMT' };
      if (type === 'ESTIMATED' && (until === undefined || until === null || from === until)) {
         return `${this.localizeDate(from, options)}`;
      }
      if (type === 'ESTIMATED' && from && until) {
         return `${this.localizeDate(from, options)} - ${this.localizeDate(until, options)}`;
      }
      if (until) {
         return this.localizeDate(until, options);
      }
      return from ? this.localizeDate(from, options) : undefined;
   }

   static transformCallOffOrderPeriod(date: CallOffPeriod) {
      const { from, until } = date;
      if (from === undefined) return '-';

      const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit', timeZone: 'GMT' };
      if (until === undefined || until === null || from === until) return this.localizeDate(from, options);
      return `${this.localizeDate(from, options)} - ${this.localizeDate(until, options)}`;
   }

   static getNextBusinessDay(start: LocalDateTime = LocalDateTime.now(), days = 2, cutOffHour = 16) {
      const cutOffTime = this.getCutOffTime(start, cutOffHour);
      const currentTime = start;

      if (currentTime.isAfter(cutOffTime)) {
         start = start.plusDays(1);
      }

      if (this.isWeekendDay(start)) {
         start = start.with(TemporalAdjusters.next(DayOfWeek.MONDAY));
      }

      const nextDate = this.addBusinessDays(start, days);
      return nextDate.toLocalDate().toString();
   }

   static getCutOffTime(start: LocalDateTime, cutOffHour: number): LocalDateTime {
      return LocalTime.of(cutOffHour).atDate(start.toLocalDate());
   }

   static isWeekendDay(date: LocalDateTime): boolean {
      return date.dayOfWeek() === DayOfWeek.SATURDAY || date.dayOfWeek() === DayOfWeek.SUNDAY;
   }

   static addBusinessDays(date: LocalDateTime, days: number): LocalDateTime {
      let result = date;
      let addedDays = 0;

      while (addedDays < days) {
         result = result.plusDays(1);
         if (!this.isWeekendDay(result)) {
            addedDays += 1;
         }
      }
      return result;
   }

   static getStartOfYearFromCurrentInUtc(minus: number): LocalDate {
      const currentDate = LocalDateTime.now(ZoneOffset.UTC);
      const beforeDate = currentDate.minus(Period.ofYears(minus));
      return LocalDate.of(beforeDate.year(), 1, 1);
   }
}
