export enum SystemPageType {
  ADDRESSES = 'Addresses',
  REGISTRATION_NUMBERS = 'Registration Numbers',
  ORDER_OVERVIEW = 'Order Overview',
  ORDER_DETAIL = 'Order Detail',
  SHIPMENT = 'Shipment',
  CALL_OFF_ORDER_DETAIL = 'Call Off Order Detail',
  INVOICES = 'Invoices',
  VAT_NUMBERS = 'Vat Numbers'
}
