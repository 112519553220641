'use client';

import { Dispatch, createContext, useContext } from 'react';

import { CallOffAgreementAction, CallOffAgreementState, initialState } from '../reducers/call-off-agreement-reducer';

export const CallOffAgreementContext = createContext<{ state: CallOffAgreementState; dispatch?: Dispatch<CallOffAgreementAction> }>({
   state: initialState,
   dispatch: undefined
});

export const useCallOffAgreementContext = () => {
   const context = useContext(CallOffAgreementContext);
   if (!context) {
      throw new Error('useCallOffAgreementContext must be used within a CallOffAgreementContextProvider');
   }
   return context;
};
