'use client';

import { useContext } from 'react';

import { WidgetContext } from '../context/widget-context';

export const useDocumentShare = () => {
   const {
      shareDocumentList = {
         detailPageName: '',
         documents: []
      },
      setShareList = () => {},
      downloadDocumentList = [],
      setDownloadList = () => {}
   } = useContext(WidgetContext) ?? {};

   return { shareDocumentList, setShareList, setDownloadList, downloadDocumentList };
};
