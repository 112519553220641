import { AriaRole, FC, ReactNode } from 'react';

interface Props {
   width?: string;
   type?: 'none' | 'disc';
   columns?: number;
   role?: AriaRole;
   children: ReactNode | Array<ReactNode>;
}

export const List: FC<Props> = ({ width = '100%', columns = 1, children, type = 'none', role }) => (
   <ul data-testid={`list${type === 'disc' ? '--disc' : ''}`} className={type === 'none' ? 'list-none gap-0 !pl-0' : 'list-disc pl-7'} style={{ width, columns }} role={role}>
      {children}
   </ul>
);
