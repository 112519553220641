import { CSSProperties, FC, PropsWithChildren, ReactNode, useRef, useState } from 'react';

import { Transition, TransitionStatus } from 'react-transition-group';

import { useResizeObserver } from '../../../../hooks/useResizeObserver/useResizeObserver';

const duration = 300;

const defaultStyle = {
   transition: `height ${duration}ms ease-in-out`,
   height: 0
};

interface Props extends PropsWithChildren {
   expanded?: boolean;
   onExpandToggle?: (state: boolean) => void;
   header?: ReactNode;
   overflow?: 'visible' | 'hidden';
}

export const Expand: FC<Props> = ({ expanded, onExpandToggle, header, overflow = 'hidden', children }) => {
   const [transitionStyles, setTransitionStyles] = useState<Record<TransitionStatus, CSSProperties | undefined>>({
      entering: { height: 'auto' },
      entered: { height: 'auto' },
      exiting: {},
      exited: {},
      unmounted: {}
   });
   const nodeRef = useRef(null);

   const contentRef = useResizeObserver<HTMLDivElement>(
      (contentRect) => {
         const { height } = contentRect;
         setTransitionStyles((curr) => ({
            ...curr,
            entering: { height: `${height}px` },
            entered: { height: `${height}px` }
         }));
      },
      [children]
   );

   const handleExpandToggle = () => onExpandToggle?.(!expanded);

   return (
      <div>
         {header && (
            <div data-testid="expand-header" role="presentation" onClick={handleExpandToggle} className="z-10 cursor-pointer">
               {header}
            </div>
         )}
         <div className={overflow === 'hidden' ? 'overflow-hidden' : ''}>
            <Transition nodeRef={nodeRef} in={expanded} timeout={duration} mountOnEnter unmountOnExit>
               {(state) => (
                  <div
                     data-testid="expand-styles"
                     ref={nodeRef}
                     style={{
                        ...defaultStyle,
                        ...transitionStyles[state]
                     }}
                  >
                     <div ref={contentRef}>{children}</div>
                  </div>
               )}
            </Transition>
         </div>
      </div>
   );
};
