type FlyoutTypes = 'request-for-quotation' | 'order-line' | 'call-off-agreement' | undefined;
type Actions = 'open_flyout' | 'close_flyout' | undefined;

export interface FlyoutState {
   idOpenDialog: FlyoutTypes;
   title?: string;
}

export interface FlyoutAction {
   type: Actions;
   value?: { flyoutType: FlyoutTypes; title?: string };
}

export const initialState = {
   idOpenDialog: undefined,
   title: undefined
};

export const FlyoutReducer = (state: FlyoutState, action: FlyoutAction) => {
   switch (action.type) {
      case 'open_flyout': {
         return {
            ...state,
            idOpenDialog: action.value?.flyoutType,
            title: action.value?.title
         };
      }
      case 'close_flyout': {
         return {
            ...state,
            idOpenDialog: undefined,
            title: undefined
         };
      }
      default:
         return {
            ...state
         };
   }
};
