'use client';

import { FC, useEffect, useState } from 'react';

interface Props {
   numberOfSteps: number;
   currentStep?: number;
   readOnly?: boolean;
   variant?: 'default' | 'on-primary';
   onStepChange?: (step: number) => void;
}

export const DotsStepper: FC<Props> = ({ numberOfSteps, currentStep = 0, readOnly = false, variant = 'default', onStepChange }) => {
   const [visualStep, setVisualStep] = useState(currentStep);

   useEffect(() => setVisualStep(currentStep), [currentStep]);

   const handleStepChange = (newStep: number) => {
      if (readOnly || visualStep === newStep) return;

      setVisualStep(newStep);
      onStepChange?.(newStep);
   };

   return (
      <div data-testid="dots-stepper" className="gap-component-navigation-dot-stepper-container-spacing-gap flex">
         {Array.from({ length: numberOfSteps }, (_, i) => i).map((step) => (
            // eslint-disable-next-line jsx-a11y/control-has-associated-label
            <button
               key={step}
               type="button"
               data-testid={`dots-stepper-step--${step}`}
               onClick={() => handleStepChange(step)}
               className={`h-component-navigation-dot-stepper-dot-sizing-size w-component-navigation-dot-stepper-dot-sizing-size rounded-component-navigation-dot-stepper-dot-border-radius appearance-none
                      ${visualStep === step && variant === 'default' ? 'bg-component-navigation-dot-stepper-dot-color-fill-active-default' : ''}
                      ${visualStep === step && variant === 'on-primary' ? 'bg-component-navigation-dot-stepper-dot-color-fill-active-on-primary' : ''}
                      ${visualStep !== step && variant === 'default' ? 'bg-component-navigation-dot-stepper-dot-color-fill-inactive-default' : ''}
                      ${visualStep !== step && variant === 'on-primary' ? 'bg-component-navigation-dot-stepper-dot-color-fill-inactive-on-primary' : ''}
                      ${readOnly ? 'pointer-events-none' : ''}
                  `.replace(/\s+/g, ' ')}
            />
         ))}
      </div>
   );
};
