import { useUser as useAuth0User } from '@auth0/nextjs-auth0/client';

import { useCallback } from 'react';

import { useTokenContext } from '../contexts/token-context';
import { User } from '../models/user.model';

export default function useServerAuthentication() {
  const { user: auth0User, isLoading: isLoadingUser, checkSession } = useAuth0User();
  const { accessToken, isLoading: isLoadingToken, refreshToken } = useTokenContext();

  const user: User | undefined = auth0User as User | undefined;

  const login = useCallback((redirectUrl?: string) => {
    const locale = window.location.pathname.split('/')[1];
    const language = locale.split('-')[0].toLowerCase();
    const searchParams = new URLSearchParams({ language });

    if (redirectUrl) {
      searchParams.set('returnTo', redirectUrl);
    }
    window.location.href = `/api/auth/login?${searchParams.toString()}`;
  }, []);

  const logout = useCallback(() => {
    window.location.href = '/api/auth/logout';
  }, []);

  return {
    user,
    loading: isLoadingUser || isLoadingToken,
    accessToken,
    mail: user?.mail,
    refreshToken: async () => {
      await refreshToken();
      await checkSession(); // Update user info
    },
    login,
    logout
  };
}
