import { DropdownOption } from '@ravago/shared/radiance/components/input-field/components/Dropdown/Dropdown';

type DialogTypes =
   | 'internal-document-warning'
   | 'multiple-documents-are-loading'
   | 'document-is-downloading'
   | 'share-page'
   | 'share-documents'
   | 'share-document'
   | 'request-documents-form'
   | undefined;
type Actions = 'open_dialog' | 'close_dialog' | 'download_despite_warning' | undefined;

export interface DialogState {
   idOpenDialog: DialogTypes;
   title?: string;
   documentId?: string;
   shareAnchorInfo?: { docName: string; docId: string; detailPageName: string };
   internal?: {
      amountOfInternalDocuments?: number;
      totalAmountOfDocuments?: number;
      canBeDownloaded?: boolean;
   };
   requestDocumentFormCategories?: DropdownOption[];
}

export interface DialogAction {
   type: Actions;
   value?: {
      dialogType?: DialogTypes;
      title?: string;
      shareAnchorInfo?: { docName: string; docId: string; detailPageName: string };
      documentId?: string;
      amountOfInternalDocuments?: number;
      totalAmountOfDocuments?: number;
      requestDocumentFormCategories?: DropdownOption[];
   };
}

export const initialState = {
   idOpenDialog: undefined,
   title: undefined,
   documentId: undefined,
   shareAnchorInfo: undefined,
   internal: undefined,
   requestDocumentFormCategories: undefined
};

export const DialogReducer = (state: DialogState, action: DialogAction) => {
   switch (action.type) {
      case 'open_dialog': {
         return {
            ...state,
            idOpenDialog: action.value?.dialogType,
            title: action.value?.title,
            documentId: action.value?.documentId,
            shareAnchorInfo: action.value?.shareAnchorInfo,
            internal: {
               amountOfInternalDocuments: action.value?.amountOfInternalDocuments,
               totalAmountOfDocuments: action.value?.totalAmountOfDocuments,
               canBeDownloaded: false
            },
            requestDocumentFormCategories: action.value?.requestDocumentFormCategories
         };
      }
      case 'close_dialog': {
         return {
            ...state,
            idOpenDialog: undefined,
            title: undefined,
            shareAnchorInfo: undefined,
            documentId: undefined,
            internal: undefined,
            requestDocumentFormCategories: undefined
         };
      }
      case 'download_despite_warning': {
         return {
            ...state,
            documentId: action.value?.documentId ?? state.documentId,
            idOpenDialog: undefined,
            internal: {
               canBeDownloaded: true
            }
         };
      }
      default:
         return {
            ...state
         };
   }
};
