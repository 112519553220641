'use client';

import { FC, PropsWithChildren } from 'react';

import { DynamicPageUrl } from '@ravago/shared/page-data/models/utils';

import { useConfigContext } from '../../../hooks/use-config-context';
import { UrlUtils } from '../../../utils/url/url.utils';
import { Link } from '../../shared/link/link';

interface DynamicPageLinkProps extends PropsWithChildren {
   anchor?: string;
   id: string;
   baseRoute: DynamicPageUrl | undefined;
   locale: string;
   prefetch?: boolean;
   slug?: string;
   underline?: 'none' | 'hover' | 'always';
}

export const DynamicPageLink: FC<DynamicPageLinkProps> = ({
   baseRoute,
   id,
   locale,
   slug,
   anchor,
   prefetch = false,
   underline = 'always',
   children
}) => {
   const { useLegacyDigitalPlatformRoutes } = useConfigContext();

   const href = baseRoute?.url ? UrlUtils.getRoute(locale, baseRoute.url, slug, anchor, useLegacyDigitalPlatformRoutes) : '';

   return (
      <Link id={id} noFollow={baseRoute?.noFollow} href={href} underline={underline} prefetch={prefetch} data-testid="dynamic-page-link__link">
         {children}
      </Link>
   );
};
