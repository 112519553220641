import { FC } from 'react';

import classNames from 'classnames';

interface Props {
   src: string;
   alt: string;
   title: string;
   caption?: string;
   height?: string;
   width?: string;
   objectFit?: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down';
   borderRadius?: 'null' | 'xs' | 'sm' | 'md' | 'lg';
   zoomed?: boolean;
   overlay?: 'none' | 'default' | 'primary';
}

export const Image: FC<Props> = ({ src, alt, title, caption, height = 'unset', width = 'unset', objectFit = 'cover', borderRadius = 'null', zoomed = false, overlay = 'none' }) => (
   <div
      data-testid="image-container"
      className={classNames(
         'relative inline-flex overflow-hidden',
         borderRadius === 'null' && 'rounded-brand-border-radius-null',
         borderRadius === 'xs' && 'rounded-brand-border-radius-xs',
         borderRadius === 'sm' && 'rounded-brand-border-radius-sm',
         borderRadius === 'md' && 'rounded-brand-border-radius-md',
         borderRadius === 'lg' && 'rounded-brand-border-radius-lg'
      )}
      style={{ width, height }}
   >
      {overlay !== 'none' && (
         <div
            data-testid="image-overlay"
            className={classNames(
               'absolute w-full h-full left-0 top-0 opacity-80',
               overlay === 'default' && 'bg-component-surfaces-overlay-container-color-background-scrimmed-default',
               overlay === 'primary' && 'bg-component-surfaces-overlay-container-color-background-scrimmed-primary',
               borderRadius === 'null' && 'rounded-brand-border-radius-null',
               borderRadius === 'xs' && 'rounded-brand-border-radius-xs',
               borderRadius === 'sm' && 'rounded-brand-border-radius-sm',
               borderRadius === 'md' && 'rounded-brand-border-radius-md',
               borderRadius === 'lg' && 'rounded-brand-border-radius-lg'
            )}
         />
      )}

      <img data-testid={`image${zoomed ? '--zoomed' : ''}`} src={src} alt={alt} title={title} style={{ objectFit }} className={`size-full object-cover transition-all duration-500 ${zoomed ? 'scale-110' : ''}`} />

      {caption && (
         <div
            data-testid="image-caption"
            className="typography-component-typography-body-regular-sm text-component-data-display-image-caption-container-color-text-on-scrimmed bg-component-data-display-image-caption-container-color-background-scrimmed h-component-data-display-image-caption-sizing-height px-component-data-display-image-caption-container-spacing-padding-x absolute inset-x-0 bottom-0 z-10 flex items-center justify-center"
         >
            {caption}
         </div>
      )}
   </div>
);
