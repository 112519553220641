'use client';

import { v4 as uuidv4 } from 'uuid';

export abstract class CorrelationService {
   private static storeInLocalStorage(key: string, value: { id: string; timestamp: number }) {
      localStorage.setItem(key, JSON.stringify(value));
   }

   private static isCorrelationActive(storedTimestamp: number) {
      const currentTimestamp = new Date().getTime();
      const timeDifference = currentTimestamp - storedTimestamp;
      const expirationTime = 6 * 60 * 60 * 1000;
      return timeDifference < expirationTime;
   }

   private static getCorrelationIdFromLocalStorage(correlationKey: string): { id: string; timestamp: string } | undefined {
      const correlationIdObject = JSON.parse(localStorage.getItem(correlationKey) ?? '{}');
      if (!correlationIdObject?.id || !correlationIdObject?.timestamp) {
         return undefined;
      }
      const correlationActive = CorrelationService.isCorrelationActive(correlationIdObject.timestamp);
      if (correlationActive) {
         return correlationIdObject;
      }
      return undefined;
   }

   static generateCorrelationId(correlationKey: string) {
      const correlationObject = {
         id: uuidv4(),
         timestamp: new Date().getTime()
      };
      CorrelationService.storeInLocalStorage(correlationKey, correlationObject);
      return correlationObject.id;
   }

   static getCorrelationId(correlationKey: string): string {
      let correlationId: string | undefined;
      correlationId = CorrelationService.getCorrelationIdFromLocalStorage(correlationKey)?.id;
      if (correlationId) {
         return correlationId;
      }
      correlationId = CorrelationService.generateCorrelationId(correlationKey);
      return correlationId;
   }
}
