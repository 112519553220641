'use client';

import { createContext, FC, PropsWithChildren, useContext, useMemo } from 'react';
import { useAuthentication } from '../hooks/use-authentication';
import { User } from '../models/user.model';

export interface AuthenticationContextProps {
  isAuthenticated: boolean;
  loading: boolean;
  user?: User;
  token?: string;
  login: (redirectUrl?: string) => void;
}

const AuthenticationContext = createContext<AuthenticationContextProps | null>(null);

export const useAuthenticationContext = () => {
  const context = useContext(AuthenticationContext);
  if (!context) {
    throw new Error('useAuthenticationContext must be used within a AuthenticationContextProvider');
  }
  return context;
};

export const AuthenticationContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const { accessToken, loading, user, login } = useAuthentication();

  const authenticationContextValue: AuthenticationContextProps = useMemo<AuthenticationContextProps>(
    () => ({
      isAuthenticated: !!accessToken,
      user,
      token: accessToken,
      loading,
      login
    }),
    [accessToken, loading, user, login]
  );

  return (
    <AuthenticationContext.Provider value={authenticationContextValue}>
      {children}
    </AuthenticationContext.Provider>
  );
};

export const PageProducerAuthenticationContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const producerAuthenticationContextValue: AuthenticationContextProps = {
    isAuthenticated: false,
    loading: false,
    user: undefined,
    token: undefined,
    login: () => { /*...*/
    }
  };
  return (
    <AuthenticationContext.Provider value={producerAuthenticationContextValue}>
      {children}
    </AuthenticationContext.Provider>
  );
};

export const PreviewClientAuthenticationContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const producerAuthenticationContextValue: AuthenticationContextProps = {
    isAuthenticated: false,
    loading: false,
    user: undefined,
    token: 'preview',
    login: () => { /*...*/
    }
  };
  return (
    <AuthenticationContext.Provider value={producerAuthenticationContextValue}>
      {children}
    </AuthenticationContext.Provider>
  );
};
