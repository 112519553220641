import { FacetFilterWithTerms, facetLabelTranslationMapping } from '@ravago/shared/page-data/models/elastic';

export type TrackingEvents =
   | 'autosuggest_facet_click'
   | 'autosuggest_page_click'
   | 'rfq_click_start_quote_request'
   | 'rfq_click_continue_flyout'
   | 'rfq_click_delivery_flyout'
   | 'rfq_click_login_button_flyout'
   | 'rfq_click_back_button_flyout'
   | 'rfq_click_request_quote_flyout'
   | 'rfq_click_my_quotes_flyout'
   | 'product-portfolio-search'
   | 'tds_download_new_lead'
   | 'tds_download_previously_purchased'
   | 'click_filter_facet'
   | 'click_reset_all_filters'
   | 'click_orders_search_button'
   | 'click_request_delivery_button'
   | 'order_document_download'
   | 'order_document_request';

export class TrackingUtils {
   static track(event: TrackingEvents, information?: { [key: string]: string | undefined | { [key: string]: string | undefined } }) {
      (window as any).dataLayer = (window as any).dataLayer || [];
      (window as any).dataLayer.push({
         event,
         ...information
      });
   }

   static translateFacets(
      facetFilters: FacetFilterWithTerms[],
      translate: (key: string, placeholders?: { [p: string]: any } | undefined, forceFallback?: boolean | undefined) => string
   ): FacetFilterWithTerms[] {
      return facetFilters.map((facet) => ({
         ...facet,
         facet: translate(facetLabelTranslationMapping.get(facet.facet) ?? facet.facet, undefined, true),
         terms: facet.terms.map((term) => ({
            ...term,
            nestedFacets: term.nestedFacets ? this.translateFacets(term.nestedFacets, translate) : undefined
         }))
      }));
   }
}
