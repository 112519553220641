const Format = (key: string, param?: string): string => {
   const formattedParams = param
      ?.toLowerCase()
      .replace(/[^\p{L}\p{N}_-]+/gu, '-')
      .replace(/-{2,}/g, '-')
      .replace(/^[-]+|[-]+$/g, '');

   return `${key}${formattedParams ? `-${formattedParams}` : ''}`;
};

export default Format;
