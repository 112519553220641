'use client';

import { createContext, FC, PropsWithChildren, useCallback, useMemo, useReducer } from 'react';

import { OrderableProduct, Quantity } from '@ravago/shared/page-data/models/elements';
import { AddToCartResponse, PostShoppingCartItem, ShoppingCart } from '@ravago/shared/page-data/models/features';

import { initialState, ShoppingCartReducer, ShoppingCartState } from '../reducers/shopping-cart-reducer';

interface ContextType {
   state: ShoppingCartState;
   addToCart: (lineId: string, orderableProduct: OrderableProduct, quantity: Quantity, accessToken: string, allowOverwrite?: boolean) => void;
   resetCartValidation: () => void;
}

interface ShoppingCartProvidersProps extends PropsWithChildren {
   addToShoppingCart: (accessToken: string, item: PostShoppingCartItem) => Promise<AddToCartResponse>;
   getShoppingCart: (accessToken: string) => Promise<ShoppingCart>;
}

export const ShoppingCartContext = createContext<ContextType>({
   state: initialState,
   addToCart: () => {},
   resetCartValidation: () => {}
});

export const ShoppingCartProviders: FC<ShoppingCartProvidersProps> = ({ children, addToShoppingCart, getShoppingCart }) => {
   const [state, dispatch] = useReducer(ShoppingCartReducer, initialState);

   const addToCart = useCallback(
      async (lineId: string, orderableProduct: OrderableProduct, quantity: Quantity, accessToken: string, allowOverwrite?: boolean) => {
         const item: PostShoppingCartItem = {
            productId: orderableProduct.id,
            unitPriceSource: { type: 'QUOTATION', id: lineId },
            quantity: { amount: quantity.amount, unitOfMeasure: quantity.unitOfMeasure }
         };

         if (!allowOverwrite) {
            const shoppingCart = await getShoppingCart(accessToken);
            if (shoppingCart) {
               const existingItem = shoppingCart.items.find((i) => i.product.id === item.productId);
               if (existingItem && existingItem.quantity.amount !== item.quantity.amount) {
                  dispatch({
                     type: 'duplicate_product',
                     value: {
                        type: 'DUPLICATE',
                        duplicateCartItem: {
                           lineId,
                           orderableProduct,
                           quantity
                        },
                        existingItem
                     }
                  });
                  return;
               }
            }
         }

         const addToCartResponse = await addToShoppingCart(accessToken, item);

         if (addToCartResponse.success) {
            dispatch({ type: 'add_to_cart', value: { type: 'SUCCESS' } });
         } else {
            dispatch({
               type: 'add_to_cart',
               value: {
                  type: 'ERROR',
                  productName: orderableProduct.description,
                  validationErrors: addToCartResponse.problems?.length
                     ? addToCartResponse.problems
                     : [
                          {
                             code: 'shopping-cart.validation.error',
                             message: 'shopping-cart.validation.error',
                             level: 'ERROR'
                          }
                       ]
               }
            });
         }
      },
      []
   );

   const resetCartValidation = useCallback(() => {
      dispatch({ type: 'reset', value: {} });
   }, []);

   const contextValue = useMemo(() => ({ state, addToCart, resetCartValidation }), [state, addToCart, resetCartValidation]);

   return <ShoppingCartContext.Provider value={contextValue}>{children}</ShoppingCartContext.Provider>;
};
