'use client';

import { createContext, Dispatch, FC, PropsWithChildren, useMemo, useReducer } from 'react';

import { FlyoutAction, FlyoutReducer, FlyoutState, initialState } from '../reducers/flyout-reducer';

export const FlyoutContext = createContext<{ state: FlyoutState; dispatch?: Dispatch<FlyoutAction> }>({
   state: initialState,
   dispatch: undefined
});

export const FlyoutProviders: FC<PropsWithChildren> = ({ children }) => {
   const [state, dispatch] = useReducer(FlyoutReducer, initialState);

   const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

   return <FlyoutContext.Provider value={contextValue}>{children}</FlyoutContext.Provider>;
};
