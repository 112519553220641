'use client';

import { FC, PropsWithChildren } from 'react';

import { AuthenticationContextProvider } from '../contexts/authentication-context';
import { TokenContextProvider } from '../contexts/token-context';
import { UserProvider as Auth0UserProvider } from '@auth0/nextjs-auth0/client';
import { AuthenticationConfigContextProvider } from '../contexts/authentication-config-context';
import { AuthenticationConfig } from '../models/authentication-config.model';

type AuthenticationProvidersProps = PropsWithChildren & {
   authenticationConfig: AuthenticationConfig
}

export const AuthenticationProviders: FC<AuthenticationProvidersProps> = ({ authenticationConfig, children }) => {
  if (!authenticationConfig.useNextAuth) {
    return <AuthenticationConfigContextProvider authenticationConfig={authenticationConfig}>
        <TokenContextProvider>
          <AuthenticationContextProvider>
            {children}
          </AuthenticationContextProvider>
        </TokenContextProvider>
    </AuthenticationConfigContextProvider>
  }

   return (
      <AuthenticationConfigContextProvider authenticationConfig={authenticationConfig}>
         <Auth0UserProvider>
            <TokenContextProvider>
               <AuthenticationContextProvider>
                  {children}
               </AuthenticationContextProvider>
            </TokenContextProvider>
         </Auth0UserProvider>
      </AuthenticationConfigContextProvider>
   );
};
