import { SystemPageType } from '../../enums/system-page-type/system-page-type.enum';

const VARIABLE_NAMES: Record<SystemPageType, string | undefined> = {
  [SystemPageType.REGISTRATION_NUMBERS]: undefined,
  [SystemPageType.ORDER_OVERVIEW]: undefined,
  [SystemPageType.ADDRESSES]: undefined,
  [SystemPageType.ORDER_DETAIL]: ':orderId',
  [SystemPageType.VAT_NUMBERS]: undefined,
  [SystemPageType.SHIPMENT]: ':shipmentId',
  [SystemPageType.CALL_OFF_ORDER_DETAIL]: ':orderId',
  [SystemPageType.INVOICES]: undefined
};

export class SystemPageUtils {
  /**
   * Gets the variable name of the current page entry, if it is a system page
   *
   * @param type - The type of the system page
   *
   * @returns The variable name of the given system page type
   */
  static getSystemPageVariableName(type: SystemPageType): string | undefined {
    return VARIABLE_NAMES[type];
  }
}
