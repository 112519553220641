import { FC, useMemo } from 'react';

import { getColorCode } from '@ravago/shared/page-data/const/color-to-hex-mapper';
import { ColorPickerValue, useTranslations } from '@ravago/shared/page-elements-radiance-consumer';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Icon } from '@ravago/shared/radiance/components/icon/components/Icon/Icon';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

interface Props {
   value?: ColorPickerValue;
}

export const ProductFlyoutColorDetails: FC<Props> = ({ value }: Props) => {
   const translate = useTranslations();

   const colorName = value?.colorName;
   const colorHexCode = value?.colorName ? getColorCode(value.colorName.toLowerCase()) : undefined;
   const producerColorCode = value?.producerColorCode;
   const desiredColor = value?.desiredColor;

   const fullColorLabel = useMemo(() => {
      if (!colorName) return undefined;

      let result = colorName;
      if (producerColorCode) result = `${result} - ${producerColorCode}`;
      if (desiredColor) result = `${result} - ${desiredColor}`;

      return result;
   }, [colorName, producerColorCode, desiredColor]);

   return (
      <Box direction="column" gap="2xs">
         <Typography component="span" variant="body-highlight">
            {translate('page-consumer.request-for-quotation.colour')}
         </Typography>
         {fullColorLabel && (
            <Box gap="2xs">
               <div style={{ color: colorHexCode || 'var(--component-input-color-picker-icon-end-color-text-on-surface-enabled)' }}>
                  <Icon size={colorHexCode ? 'body-highlight' : 'body'} icon={colorHexCode ? 'circle' : 'circle-dashed'} />
               </div>
               <Typography component="span" variant="body">
                  {fullColorLabel}
               </Typography>
            </Box>
         )}
      </Box>
   );
};
