import { DependencyList, useEffect, useRef } from 'react';

export const useResizeObserver = <T extends HTMLElement>(callback: (contentRect: DOMRectReadOnly) => void, deps?: DependencyList) => {
   const ref = useRef<T>(null);

   useEffect(() => {
      const element = ref.current;
      if (!element) return undefined;

      const observer = new ResizeObserver((entries) => {
         callback(entries[0].contentRect);
      });

      observer.observe(element);

      return () => {
         observer.disconnect();
      };
   }, [ref, callback, deps]);

   return ref;
};
