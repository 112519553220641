import { FC } from 'react';

import { Icon } from '../../../icon/components/Icon/Icon';

interface Props {
   icon: string;
   disabled?: boolean;
   onClick?: () => void;
}

export const InputFieldAction: FC<Props> = ({ icon, disabled, onClick }) => (
   <button
      data-testid="input-field-action"
      onClick={onClick}
      type="button"
      disabled={disabled}
      className="min-h-component-input-input-field-action-container-sizing-min-size min-w-component-input-input-field-action-container-sizing-min-size bg-component-input-input-field-action-container-color-background-enabled disabled:bg-component-input-input-field-action-container-color-background-disabled text-component-input-input-field-action-icon-color-fill-enabled disabled:text-component-input-input-field-action-icon-color-fill-disabled group z-10"
   >
      <div
         className={`min-h-component-input-input-field-action-container-sizing-min-size min-w-component-input-input-field-action-container-sizing-min-size flex items-center justify-center transition-all${
            !disabled ? 'group-focus-visible:bg-component-input-input-field-action-state-layer-color-fill-focused group-active:bg-component-input-input-field-action-state-layer-color-fill-pressed' : ''
         }`}
      >
         <Icon icon={icon} />
      </div>
   </button>
);
