import { FC, ReactNode, useState } from 'react';

import { shift, useFloating, useHover, useInteractions, useTransitionStyles } from '@floating-ui/react';

import classNames from 'classnames';

import { ProgressBarSegmentValue } from '../../ProgressBar/ProgressBar';

interface Props {
   segment: ProgressBarSegmentValue;
   width: string;
   tooltip?: (segment: ProgressBarSegmentValue) => ReactNode;
}

export const ProgressBarSegment: FC<Props> = ({ width, segment, tooltip }) => {
   const [tooltipVisible, setTooltipVisible] = useState(false);

   const { refs, floatingStyles, context } = useFloating({
      placement: 'bottom',
      open: tooltipVisible,
      onOpenChange: setTooltipVisible,
      middleware: [shift()]
   });

   const { isMounted, styles } = useTransitionStyles(context);

   const hover = useHover(context, {
      enabled: !!tooltip
   });
   const { getReferenceProps, getFloatingProps } = useInteractions([hover]);

   return (
      <>
         <div
            ref={refs.setReference}
            {...getReferenceProps()}
            data-testid="progress-bar-segment"
            style={{ width }}
            className={classNames('rounded-component-data-display-progress-bar-indicator-border-radius min-w-component-data-display-progress-bar-indicator-min-width h-full', {
               'bg-component-data-display-progress-bar-indicator-color-background-active': segment.active,
               'bg-component-data-display-progress-bar-indicator-color-background-inactive': !segment.active
            })}
         />

         {isMounted && (
            <div role="tooltip" data-testid="active-segment-tooltip" ref={refs.setFloating} style={{ ...floatingStyles, ...styles }} {...getFloatingProps()}>
               {tooltip?.(segment)}
            </div>
         )}
      </>
   );
};
