import { UserPermissions } from '@ravago/shared/page-data/models/config';
import { CallOffOrderColor, DynamicProductBlockColor, ProductDetail, Quantity, QuotedColor } from '@ravago/shared/page-data/models/elements';

import { DynamicProductBlockUtils } from '../utils/dynamic-product-block/dynamic-product-block.utils';

export interface RequestForQuotationState {
   product?: ProductDetail;
   callOffOrderColors?: Array<CallOffOrderColor>;
   quotedColors?: Array<QuotedColor>;
   userPermissions?: UserPermissions;
   color: DynamicProductBlockColor;
}

export interface RequestForQuotationResetColorAction {
   type: 'reset_color';
}

export interface RequestForQuotationSetColorAction {
   type: 'set_color';
   value: Pick<RequestForQuotationState, 'color'>;
}

export interface RequestForQuotationNewQuoteFromCallOffAction {
   type: 'new_quote_from_call_off';
}

export interface RequestForQuotationNewQuoteFromQuotedColorAction {
   type: 'new_quote_from_quoted_color';
}

export interface RequestForQuotationSetQuantityAction {
   type: 'set_quantity';
   value: {
      quantity: Quantity | undefined;
   };
}

export interface RequestForQuotationSetDataAction {
   type: 'set_data';
   value: {
      product: ProductDetail | undefined;
      callOffOrderColors: Array<CallOffOrderColor>;
      quotedColors: Array<QuotedColor>;
      userPermissions: UserPermissions;
      defaultColor: DynamicProductBlockColor;
   };
}

export type RequestForQuotationAction =
   | RequestForQuotationResetColorAction
   | RequestForQuotationSetColorAction
   | RequestForQuotationNewQuoteFromCallOffAction
   | RequestForQuotationNewQuoteFromQuotedColorAction
   | RequestForQuotationSetQuantityAction
   | RequestForQuotationSetDataAction;

export const initialState: RequestForQuotationState = {
   product: undefined,
   callOffOrderColors: undefined,
   quotedColors: undefined,
   userPermissions: undefined,
   color: {
      colorId: undefined,
      colorName: undefined,
      callOffOrderId: undefined,
      isQuotedColor: false,
      producerColorCode: undefined,
      desiredColor: undefined,
      quantity: undefined
   }
};

const createEmptyColorState = (): RequestForQuotationState['color'] => ({
   colorId: undefined,
   colorName: undefined,
   callOffOrderId: undefined,
   isQuotedColor: false,
   producerColorCode: undefined,
   desiredColor: undefined,
   quantity: undefined
});

export const RequestForQuotationReducer = (state: RequestForQuotationState, action: RequestForQuotationAction) => {
   switch (action.type) {
      case 'set_data': {
         const { product, callOffOrderColors, defaultColor, quotedColors } = action.value;

         return {
            ...state,
            product,
            callOffOrderColors,
            quotedColors,
            userPermissions: action.value.userPermissions,
            color: defaultColor
         };
      }
      case 'reset_color':
         if (!state.product || !state.callOffOrderColors || !state.quotedColors) {
            return {
               ...state,
               color: createEmptyColorState()
            };
         }
         return {
            ...state,
            color: DynamicProductBlockUtils.getDefaultColorValue(undefined, state.product, state.callOffOrderColors, state.quotedColors)
         };
      case 'set_color':
         return { ...state, color: action.value.color };
      case 'new_quote_from_call_off': {
         const { callOffOrderId } = state.color;
         if (!callOffOrderId) return state;

         const foundCallOff = state.callOffOrderColors?.find((color) => color.recommendedCallOff.id === callOffOrderId);
         if (!foundCallOff) return state;

         const matchingColor = state.product?.colors.find((color) => color.id === foundCallOff.color.id);
         if (!matchingColor) return state;

         const newColor = {
            colorId: foundCallOff.color.id,
            colorName: foundCallOff.color.name,
            producerColorCode: foundCallOff.color.producerColorCode,
            desiredColor: undefined,
            isQuotedColor: false,
            callOffOrderId: undefined,
            quantity: undefined
         };
         return { ...state, color: newColor };
      }
      case 'new_quote_from_quoted_color': {
         const { isQuotedColor, colorId } = state.color;
         if (!isQuotedColor || !colorId) return state;
         const foundQuotedColor = state.quotedColors?.find((color) => color.color.id === colorId);

         if (!foundQuotedColor) return state;
         const matchingColor = state.product?.colors.find((color) => color.id === foundQuotedColor.color.id);
         if (!matchingColor) return state;
         const newColor = {
            colorId: foundQuotedColor.color.id,
            colorName: foundQuotedColor.color.name,
            producerColorCode: foundQuotedColor.color.producerColorCode,
            desiredColor: undefined,
            isQuotedColor: false,
            callOffOrderId: undefined,
            quantity: undefined
         };
         return { ...state, color: newColor };
      }
      case 'set_quantity': {
         return { ...state, color: { ...state.color, quantity: action.value.quantity } };
      }
      default:
         return state;
   }
};
