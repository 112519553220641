'use client';

import { Dispatch, createContext } from 'react';

import { initialState, RequestForQuotationState, RequestForQuotationAction } from '../reducers/request-for-quotation-reducer';

interface RequestForQuotationContextValue {
   state: RequestForQuotationState;
   dispatch?: Dispatch<RequestForQuotationAction>;
   isRfqValid: boolean;
   isCallOffValid: boolean;
}

export const RequestForQuotationContext = createContext<RequestForQuotationContextValue>({
   state: initialState,
   dispatch: undefined,
   isRfqValid: false,
   isCallOffValid: false
});
