import { SystemPageType } from '@ravago/shared/page-data/enums/system-page-type';
import { ContentfulDynamicPage, DynamicPageContentType } from '@ravago/shared/page-data/models/contentful';
import { DynamicPageUrl } from '@ravago/shared/page-data/models/utils';

import { ContentfulClientApi } from 'contentful';

import { cache } from '../cache/cache.utils';
import { getLanguageCode } from '../locale/locale.utils';
import { QueryBuilder } from '../query-builder/query-builder';

/* c8 ignore start */
export class UrlUtils {
   static getDynamicPageUrls = cache(
      async (
         contentfulClientApi: ContentfulClientApi,
         useLegacyDigitalPlatformRoutes: boolean,
         language: string,
         contentTypes: DynamicPageContentType[]
      ): Promise<{ [key in DynamicPageContentType]?: string }> => {
         /**
          * Temporary fix for the products, brands, case-studies and producers pages which should redirect to Angular routes.
          */
         if (
            useLegacyDigitalPlatformRoutes &&
            (contentTypes.includes('product') ||
               contentTypes.includes('brand') ||
               contentTypes.includes('caseStudy') ||
               contentTypes.includes('producer'))
         ) {
            const legacyRoutes: { [key in DynamicPageContentType]?: string } = {};

            if (contentTypes.includes('product')) legacyRoutes.product = 'products';
            if (contentTypes.includes('brand')) legacyRoutes.brand = 'brands';
            if (contentTypes.includes('caseStudy')) legacyRoutes.caseStudy = 'case-studies';
            if (contentTypes.includes('producer')) legacyRoutes.producer = 'producers';

            return legacyRoutes;
         }

         const queryOptions = new QueryBuilder()
            .locale(language)
            .contentType('dynamicPage')
            .setArrayOptional('fields.contentType[in]', Array.isArray(contentTypes) ? contentTypes : [contentTypes])
            .limit(8)
            .build();

         const entries = await contentfulClientApi.getEntries<ContentfulDynamicPage>(queryOptions);

         return {
            ...(contentTypes.includes('product') && { product: entries?.items?.find((item) => item.fields.contentType === 'product')?.fields.route }),
            ...(contentTypes.includes('brand') && { brand: entries?.items?.find((item) => item.fields.contentType === 'brand')?.fields.route }),
            ...(contentTypes.includes('caseStudy') && {
               caseStudy: entries?.items?.find((item) => item.fields.contentType === 'caseStudy')?.fields.route
            }),
            ...(contentTypes.includes('producer') && {
               producer: entries?.items?.find((item) => item.fields.contentType === 'producer')?.fields.route
            }),
            ...(contentTypes.includes('process') && { process: entries?.items?.find((item) => item.fields.contentType === 'process')?.fields.route }),
            ...(contentTypes.includes('blogPost') && {
               blogPost: entries?.items?.find((item) => item.fields.contentType === 'blogPost')?.fields.route
            }),
            ...(contentTypes.includes('industry') && {
               industry: entries?.items?.find((item) => item.fields.contentType === 'industry')?.fields.route
            }),
            ...(contentTypes.includes('segment') && { segment: entries?.items?.find((item) => item.fields.contentType === 'segment')?.fields.route })
         };
      }
   );

   static getDynamicDataSlug = cache(async (contentfulClientApi: ContentfulClientApi, contentEntityId: string, locale: string): Promise<string> => {
      try {
         const entry = await contentfulClientApi.getEntry<{ slug: string }>(contentEntityId, { locale });
         return entry.fields.slug;
      } catch (error) {
         return '';
      }
   });

   static getDynamicPageParentUrl = cache(
      async (
         contentfulClientApi: ContentfulClientApi,
         useLegacyDigitalPlatformRoutes: boolean,
         language: string,
         contentType: DynamicPageContentType
      ): Promise<DynamicPageUrl | undefined> => {
         /**
          * Temporary fix for the products, brands, case-studies and producers pages which should redirect to Angular routes.
          */
         if (useLegacyDigitalPlatformRoutes && (contentType === 'product' || contentType === 'brand')) {
            // eslint-disable-next-line default-case
            switch (contentType) {
               case 'product':
                  return { url: 'products' };
               case 'brand':
                  return { url: 'brands' };
            }
         }

         const queryOptions = new QueryBuilder()
            .locale(language)
            .contentType('dynamicPage')
            .set('fields.contentType', contentType)
            .limit(1)
            .include(3)
            .build();

         const entries = await contentfulClientApi.getEntries<ContentfulDynamicPage>(queryOptions);
         if (entries.items.length === 0) return undefined;
         return {
            url: entries.items[0].fields.parentPage?.fields.route,
            noFollow: entries.items[0].fields.parentPage?.fields?.seoMetadata?.fields?.noFollow
         };
      }
   );

   static getSystemPageUrl = cache(async (contentfulClientApi: ContentfulClientApi, language: string, type: SystemPageType): Promise<string> => {
      const queryOptions = new QueryBuilder().locale(language).contentType('systemPage').set('fields.type', type).limit(1).build();

      const entries = await contentfulClientApi.getEntries<ContentfulDynamicPage>(queryOptions);

      const route = entries?.items?.length ? entries.items[0]?.fields?.route : '';
      return route || '';
   });

   static getRouteParameter = (routeParams?: { [key: string]: string }): string | undefined => {
      if (routeParams?.orderId) {
         return routeParams.orderId;
      }
      if (routeParams?.shipmentId) {
         return routeParams.shipmentId;
      }
      return undefined;
   };

   static getRoute = (
      locale: string,
      baseRoute: string | undefined,
      slug: string | undefined,
      anchor: string | undefined,
      useLegacyDigitalPlatformRoutes?: boolean
   ) => {
      if (!baseRoute) return `/${locale}${anchor ? `#${anchor}` : ''}`;

      const legacyDigitalPlatformRoutes = ['products', 'brands', 'case-studies', 'producers'];

      // HACK: This is a temporary fix to route to the Angular app
      if (useLegacyDigitalPlatformRoutes && legacyDigitalPlatformRoutes.includes(baseRoute)) {
         const language = getLanguageCode(locale);

         if (slug) {
            if (slug.startsWith('?')) {
               return `/${language}/${baseRoute}${slug}${anchor ? `#${anchor}` : ''}`;
            }
            return `/${language}/${baseRoute}/${slug}${anchor ? `#${anchor}` : ''}`;
         }
         return `/${language}/${baseRoute}${anchor ? `#${anchor}` : ''}`;
      }

      if (slug) {
         if (slug.startsWith('?')) {
            return `/${locale}/${baseRoute}${slug}${anchor ? `#${anchor}` : ''}`;
         }

         const route = baseRoute.replace(':slug', slug);
         return `/${locale}/${route}${anchor ? `#${anchor}` : ''}`;
      }

      return `/${locale}/${baseRoute}${anchor ? `#${anchor}` : ''}`;
   };
}

/* c8 ignore end */
