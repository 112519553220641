import { AriaRole, FC, MouseEvent, ReactNode } from 'react';

import classNames from 'classnames';

import dataAttributes from '../../../../utils/attributes/attributes';
import { Icon } from '../../../icon/components/Icon/Icon';
import { Typography } from '../../../typography/components/Typography/Typography';

interface Props {
   disabled?: boolean;
   id?: string;
   focused?: boolean;
   interactive?: boolean;
   leadingIcon?: string;
   labelText?: string;
   supportingText?: string;
   trailingIcon?: string;
   trailingText?: string;
   variant?: 'default' | 'alternate' | 'transparent';
   supportingTextVariant?: 'small' | 'caption';
   whiteSpace?: 'normal' | 'nowrap';
   width?: string;
   role?: AriaRole;
   ariaSelected?: boolean;
   attributes?: Record<string, string>;
   onClick?: (event: MouseEvent<HTMLElement>) => void;
   children?: ReactNode;
}

export const ListItem: FC<Props> = ({
   children,
   disabled = false,
   focused = false,
   interactive = true,
   leadingIcon,
   labelText,
   supportingText,
   trailingIcon,
   trailingText,
   variant = 'default',
   supportingTextVariant = 'caption',
   whiteSpace = 'normal',
   width = '100%',
   role,
   ariaSelected,
   onClick,
   id,
   attributes = {}
}) => {
   /* istanbul ignore next */
   const handleClick = (event: MouseEvent<HTMLElement>) => {
      if (!disabled) onClick?.(event);
   };

   return (
      <li data-testid="list-item-wrapper" {...dataAttributes(attributes)} style={{ width }} className={classNames('!m-0 relative list-none w-full min-w-component-data-display-list-item-container-sizing-min-width min-h-component-data-display-list-item-container-sizing-min-height box-border')}>
         <button
            id={id}
            type="button"
            role={role}
            aria-selected={ariaSelected}
            onMouseDown={handleClick}
            disabled={disabled}
            data-testid={`list-item--${variant}${focused ? '--focused' : ''}${interactive ? '--interactive' : ''}`}
            className={`w-full flex items-center justify-between gap-component-data-display-list-item-container-spacing-gap py-component-data-display-list-item-container-spacing-padding-y px-component-data-display-list-item-container-spacing-padding-x
              ${variant === 'default' && !disabled ? 'bg-component-data-display-list-item-container-color-background-default-enabled' : ''}
              ${variant === 'alternate' && !disabled ? 'bg-component-data-display-list-item-container-color-background-alternate-enabled' : ''}
              ${focused ? 'bg-component-data-display-list-item-state-layer-color-background-default-focused' : ''}
                  ${
                     !disabled && interactive
                        ? 'hover:bg-component-data-display-list-item-state-layer-color-background-default-hover focus-visible:bg-component-data-display-list-item-state-layer-color-background-default-focused active:bg-component-data-display-list-item-state-layer-color-background-default-pressed'
                        : ''
                  }
           `.replace(/\s+/g, ' ')}
         >
            {leadingIcon && (
               <div
                  data-testid="list-item-leading-icon"
                  className={`
                          ${variant === 'default' && !disabled ? 'text-component-data-display-list-item-icon-start-color-fill-default-enabled' : ''}
                          ${(variant === 'default' || variant === 'transparent') && disabled ? 'text-component-data-display-list-item-icon-start-color-fill-default-disabled' : ''}
                          ${variant === 'alternate' && !disabled ? 'text-component-data-display-list-item-icon-start-color-fill-alternate-enabled' : ''}
                          ${variant === 'alternate' && disabled ? 'text-component-data-display-list-item-icon-start-color-fill-alternate-disabled' : ''}
                      `.replace(/\s+/g, ' ')}
               >
                  <Icon icon={leadingIcon} />
               </div>
            )}
            <div className="flex flex-1 flex-col text-left">
               {labelText && (
                  <div data-testid="list-item-label-text" className={disabled ? 'text-component-data-display-list-item-supporting-text-color-text-disabled' : 'text-component-data-display-list-item-supporting-text-color-text-enabled'}>
                     <Typography variant="caption" color="inherit">
                        <div dangerouslySetInnerHTML={{ __html: labelText }} />
                     </Typography>
                  </div>
               )}
               <div className={disabled ? 'text-component-data-display-list-item-label-color-text-disabled' : 'text-component-data-display-list-item-label-color-text-enabled'}>
                  <Typography variant="body" color="inherit" whiteSpace={whiteSpace}>
                     {children}
                  </Typography>
               </div>
               {supportingText && (
                  <div data-testid="list-item-supporting-text" className={disabled ? 'text-component-data-display-list-item-supporting-text-color-text-disabled' : 'text-component-data-display-list-item-supporting-text-color-text-enabled'}>
                     <Typography variant={supportingTextVariant} color="inherit" maxLines="2">
                        <div className="typography-inherit typography-color-inherit" dangerouslySetInnerHTML={{ __html: supportingText }} />
                     </Typography>
                  </div>
               )}
            </div>
            {trailingIcon && (
               <div data-testid="list-item-trailing-icon" className={!disabled ? 'text-component-data-display-list-item-icon-end-color-fill-enabled' : 'text-component-data-display-list-item-icon-end-color-fill-disabled'}>
                  <Icon icon={trailingIcon} />
               </div>
            )}
            {trailingText && (
               <div data-testid="list-item-trailing-text" className={disabled ? 'text-component-data-display-list-item-text-end-color-fill-disabled' : 'text-component-data-display-list-item-text-end-color-fill-enabled'}>
                  <Typography variant="caption" align="left" color="inherit">
                     {trailingText}
                  </Typography>
               </div>
            )}
         </button>
      </li>
   );
};
