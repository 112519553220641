'use client';

import { Dispatch, createContext, useContext } from 'react';

import { initialState, OrderLineAction, OrderLineState } from '../reducers/order-line-reducer';

export const OrderLineContext = createContext<{ state: OrderLineState; dispatch?: Dispatch<OrderLineAction> }>({
   state: initialState,
   dispatch: undefined
});

export const useOrderLineContext = () => {
   const context = useContext(OrderLineContext);
   if (!context) {
      throw new Error('useOrderLineContext must be used within a OrderLineContextProvider');
   }
   return context;
};
