import { FC, ReactNode } from 'react';

import classnames from 'classnames';

interface Props {
   children: ReactNode;
   width?: string;
   height?: string;
   direction?: 'row' | 'column';
   shadow?: boolean;
   border?: boolean;
}

export const Paper: FC<Props> = ({ children, width, height, direction = 'column', shadow = false, border = true }) => (
   <div
      data-testid={`paper--${direction}${shadow ? '--shadow' : ''}${border ? '--border' : ''}`}
      className={classnames(
         `gap-component-surfaces-paper-container-spacing-gap bg-component-surfaces-paper-container-color-background rounded-component-surfaces-paper-container-border-radius flex`,
         direction === 'row'
            ? 'px-component-surfaces-paper-container-spacing-padding-x-horizontal py-component-surfaces-paper-container-spacing-padding-y-horizontal flex-row'
            : 'py-component-surfaces-paper-container-spacing-padding-y-vertical px-component-surfaces-paper-container-spacing-padding-x-vertical flex-col',
         shadow && 'shadow-theme-box-shadow-elevation-2',
         border && 'border-component-surfaces-paper-container-color-border border-component-surfaces-paper-container-border-width'
      )}
      style={{
         width,
         height
      }}
   >
      {children}
   </div>
);
