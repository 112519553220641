'use client';

import { useContext } from 'react';

import RadioGroupContext, { RadioGroupContextValue } from './RadioGroupContext';

export type RadioGroupState = RadioGroupContextValue;

export default function useRadioGroup(): RadioGroupState | undefined {
   return useContext(RadioGroupContext);
}
