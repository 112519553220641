'use client';

import screens from '../../../../tailwind.screens.json';
import { useMediaQuery } from '../useMediaQuery/useMediaQuery';

export const useBreakpoint = () => {
   const breakpoints = {
      phone: useMediaQuery(`only screen and (max-width: ${screens.phone.max})`),
      tabPort: useMediaQuery(`only screen and (min-width: ${screens['tab-port'].min}) and (max-width: ${screens['tab-port'].max})`),
      tabLand: useMediaQuery(`only screen and (min-width: ${screens['tab-land'].min}) and (max-width: ${screens['tab-land'].max})`),
      desktop: useMediaQuery(`only screen and (min-width: ${screens.desktop.min})`)
   };

   let active: keyof typeof breakpoints = 'phone';
   if (breakpoints.phone) active = 'phone';
   if (breakpoints.tabPort) active = 'tabPort';
   if (breakpoints.tabLand) active = 'tabLand';
   if (breakpoints.desktop) active = 'desktop';

   return { ...breakpoints, active };
};
