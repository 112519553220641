import { useEffect } from 'react';

export const useBodyOverflowHidden = (isHidden = true, options = { disabled: false }) => {
   useEffect(() => {
      if (options.disabled) return undefined;
      if (isHidden && document.body.style.getPropertyValue('overflow') !== 'hidden') {
         document.body.style.overflow = 'hidden';
      }
      if (!isHidden && document.body.style.overflow === 'hidden') {
         document.body.style.removeProperty('overflow');
      }

      return () => {
         document.body.style.removeProperty('overflow');
      };
   }, [options, isHidden]);
};
