import { FC, ReactNode } from 'react';

interface Props {
   useContainerQuery?: boolean;
   children: ReactNode | Array<ReactNode>;
}

export const Grid: FC<Props> = ({ children, useContainerQuery = false }) => (
   <div
      data-testid={useContainerQuery ? 'grid--contained' : 'grid'}
      className={`grid w-full
            ${
               useContainerQuery
                  ? '@phone:gap-component-layout-grid-spacing-gap-mobile @phone:grid-cols-4 @tab-port:gap-component-layout-grid-spacing-gap-tablet @tab-port:grid-cols-12 @tab-land:gap-component-layout-grid-spacing-gap-tablet @tab-land:grid-cols-12 @desktop:gap-component-layout-grid-spacing-gap-desktop @desktop:grid-cols-12'
                  : 'phone:gap-component-layout-grid-spacing-gap-mobile phone:grid-cols-4 tab-port:gap-component-layout-grid-spacing-gap-tablet tab-port:grid-cols-12 tab-land:gap-component-layout-grid-spacing-gap-tablet tab-land:grid-cols-12 desktop:gap-component-layout-grid-spacing-gap-desktop desktop:grid-cols-12'
            }
            `.replace(/\s+/g, ' ')}
   >
      {children}
   </div>
);
