'use client';

import { FC, ReactNode, useEffect, useMemo, useState } from 'react';

import classNames from 'classnames';

import useId from '../../../../hooks/useId/useId';
import dataAttributes from '../../../../utils/attributes/attributes';
import { Typography } from '../../../typography/components/Typography/Typography';
import useRadioGroup from '../RadioGroup/useRadioGroup';

interface Props {
   id?: string;
   value?: any;
   name?: string;
   checked?: boolean;
   disabled?: boolean;
   required?: boolean;
   readOnly?: boolean;
   attributes?: Record<string, string>;
   onChange?: (value: any) => void;
   children: ReactNode;
}

const getIcon = (checked: boolean) => {
   if (checked) {
      return (
         <svg width="9" height="9" viewBox="0 0 9 9" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.24681 2.625C1.94811 1.49219 3.15591 0.75 4.51954 0.75C5.84422 0.75 7.05201 1.49219 7.75331 2.625C8.41565 3.79688 8.41565 5.24219 7.75331 6.375C7.05201 7.54688 5.84422 8.25 4.51954 8.25C3.15591 8.25 1.94811 7.54688 1.24681 6.375C0.584477 5.24219 0.584477 3.79688 1.24681 2.625Z" />
         </svg>
      );
   }

   return null;
};

export const Radio: FC<Props> = ({ id, value, name, checked = false, disabled = false, required = false, readOnly = false, attributes = {}, onChange, children }) => {
   const elementId = useId(id, 'radio');
   const [isChecked, setIsChecked] = useState(false);
   const radioGroup = useRadioGroup();

   const handleChange = () => {
      if (radioGroup) {
         radioGroup.onChange(value);
      } else {
         onChange?.(value);
      }
   };

   useEffect(() => {
      if (radioGroup) {
         setIsChecked(radioGroup.value === value);
      } else {
         setIsChecked(checked);
      }
   }, [checked, radioGroup]);

   const icon = useMemo(() => getIcon(isChecked), [isChecked]);

   return (
      <div
         data-testid="radio"
         {...dataAttributes(attributes)}
         onClick={handleChange}
         role="presentation"
         className={`gap-component-input-radio-button-touch-area-with-label-spacing-gap inline-flex w-fit cursor-pointer items-center justify-center ${readOnly || disabled ? 'pointer-events-none' : ''}`}
      >
         <div className="min-h-component-input-radio-button-touch-area-sizing-min-size min-w-component-input-radio-button-touch-area-sizing-min-size group flex items-center justify-center gap-[calc(var(--component-input-radio-button-container-spacing-gap)-var(--component-input-radio-button-state-layer-spacing-padding))]">
            <div
               className={classNames('relative transition-all min-h-component-input-radio-button-touch-area-sizing-min-size min-w-component-input-radio-button-touch-area-sizing-min-size rounded-component-input-radio-button-state-layer-border-radius flex items-center justify-center', {
                  'group-hover:bg-component-input-radio-button-state-layer-color-background-selected-hover group-[has(:focus-visible)]:bg-component-input-radio-button-state-layer-color-background-selected-focused group-active:bg-component-input-radio-button-state-layer-color-background-selected-pressed':
                     isChecked,
                  'group-hover:bg-component-input-radio-button-state-layer-color-background-deselected-hover group-[has(:focus-visible)]:bg-component-input-radio-button-state-layer-color-background-deselected-focused group-active:bg-component-input-radio-button-state-layer-color-background-deselected-pressed':
                     !isChecked
               })}
            >
               <input
                  data-testid="radio-input"
                  className={classNames(
                     'rounded-full box-border border-component-input-radio-button-container-border-width w-component-data-display-icon-container-sizing-width-body aspect-square appearance-none transition-all checked:bg-component-input-radio-button-container-color-background-checked-enabled disabled:checked:bg-component-input-radio-button-container-color-background-checked-disabled',
                     {
                        'border-component-input-radio-button-container-color-background-checked-disabled': disabled && isChecked,
                        'border-component-input-radio-button-container-color-border-unchecked-disabled': disabled && !isChecked,
                        'border-component-input-radio-button-container-color-background-checked-enabled': !disabled && isChecked,
                        'border-component-input-radio-button-container-color-border-unchecked-enabled': !disabled && !isChecked
                     }
                  )}
                  type="radio"
                  id={elementId}
                  name={name}
                  onChange={handleChange}
                  checked={isChecked}
                  required={required}
                  readOnly={readOnly}
                  disabled={disabled}
               />
               <span className={`absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 ${disabled ? 'fill-component-input-radio-button-icon-color-fill-disabled' : 'fill-component-input-radio-button-icon-color-fill-enabled'}`}>{icon}</span>
            </div>
         </div>
         <label htmlFor={elementId} className={`pointer-events-none empty:hidden ${disabled ? 'text-component-input-radio-button-label-color-text-disabled' : 'text-component-input-radio-button-label-color-text-enabled'}`}>
            <Typography color="inherit" variant="body">
               {children}
            </Typography>
         </label>
      </div>
   );
};
