export enum UserGroups {
  customerPortal = 'Customer Portal',
  digitalPlatformGuest = 'Digital Platform Guest',
  digitalPlatformPendingVerification = 'Digital Platform Pending Verification',
  ravagoSalesRepresentative = 'Ravago Sales Representatives',
  ravagoGlobalGroupAdmin = 'Ravago Global Group Administrators',
  ravagoGlobalViewer = 'Ravago Global Viewer',
  ravagoGlobalEditor = 'Ravago Global Editor',
  reAuthentication = 'Re-authentication'
}
