import { FC, ReactNode } from 'react';

import { IconButton } from '../../../button/components/IconButton/IconButton';
import { Typography } from '../../../typography/components/Typography/Typography';

interface Props {
   action?: string;
   onActionClick?: () => void;
   onClose?: () => void;
   children: ReactNode;
}

export const Toast: FC<Props> = ({ action, onActionClick, onClose, children }) => (
   <div
      data-testid={`toast${onClose ? '--closable' : ''}`}
      className="min-h-component-feedback-toast-container-sizing-min-height bg-component-feedback-toast-container-color-background-neutral rounded-component-feedback-toast-container-border-radius pl-component-feedback-toast-container-spacing-padding-left pr-component-feedback-toast-container-spacing-padding-left py-component-feedback-toast-container-spacing-padding-y inline-flex w-full max-w-[700px] items-center justify-between"
   >
      <div className="text-component-feedback-toast-content-color-fill-on-inverse flex w-full items-center justify-between">
         <Typography variant="body" color="inherit">
            {children}
         </Typography>
         {action && (
            <div data-testid="toast-action" role="presentation" onClick={onActionClick} className="cursor-pointer underline">
               <Typography variant="body" color="inherit">
                  {action}
               </Typography>
            </div>
         )}
      </div>
      {onClose && (
         <div data-testid="toast-close" role="presentation" onClick={onClose}>
            <IconButton icon="times" variant="on-primary" />
         </div>
      )}
   </div>
);
