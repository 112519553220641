import { FC, ReactNode } from 'react';

import { Button } from '../../../button/components/Button/Button';
import { IconButton } from '../../../button/components/IconButton/IconButton';
import { Icon } from '../../../icon/components/Icon/Icon';
import { Typography } from '../../../typography/components/Typography/Typography';

interface Props {
   headline?: string;
   variant?: 'neutral' | 'success' | 'warning' | 'error' | 'info';
   button?: string;
   closable?: boolean;
   children?: ReactNode;
   onButtonClick?: () => void;
   onClose?: () => void;
}

export const Alert: FC<Props> = ({ headline, variant = 'neutral', button, closable, children, onButtonClick, onClose }) => {
   const alertIcon = () => {
      switch (variant) {
         case 'success':
            return 'check-circle';
         case 'info':
            return 'info-circle';
         case 'warning':
            return 'exclamation-triangle';
         case 'error':
            return 'exclamation-circle';
         /* istanbul ignore next */
         default:
            return 'exclamation-circle';
      }
   };

   const handleButtonClick = () => onButtonClick?.();

   const handleCloseClick = () => onClose?.();

   return (
      <div
         data-testid={`alert-container--${variant}`}
         className={`gap-component-feedback-alert-container-spacing-gap p-component-feedback-alert-container-spacing-padding rounded-component-feedback-alert-container-border-radius border-component-feedback-alert-container-border-width flex flex-col
               ${variant === 'neutral' && 'bg-component-feedback-alert-container-color-background-neutral border-component-feedback-alert-container-color-border-neutral'}
               ${variant === 'success' && 'bg-component-feedback-alert-container-color-background-success border-component-feedback-alert-container-color-border-success'}
               ${variant === 'warning' && 'bg-component-feedback-alert-container-color-background-warning border-component-feedback-alert-container-color-border-warning'}
               ${variant === 'error' && 'bg-component-feedback-alert-container-color-background-danger border-component-feedback-alert-container-color-border-danger'}
               ${variant === 'info' && 'bg-component-feedback-alert-container-color-background-info border-component-feedback-alert-container-color-border-info'}
            `.replace(/\s+/g, ' ')}
      >
         <div className="gap-component-feedback-alert-body-spacing-gap flex flex-col">
            {(headline || closable) && (
               <div
                  className={`flex items-center justify-between
                              ${variant === 'neutral' && 'text-component-feedback-alert-heading-color-fill-on-neutral'}
                              ${variant === 'success' && 'text-component-feedback-alert-heading-color-fill-on-success'}
                              ${variant === 'warning' && 'text-component-feedback-alert-heading-color-fill-on-warning'}
                              ${variant === 'error' && 'text-component-feedback-alert-heading-color-fill-on-danger'}
                              ${variant === 'info' && 'text-component-feedback-alert-heading-color-fill-on-info'}
            `.replace(/\s+/g, ' ')}
               >
                  {headline && (
                     <div className="gap-component-feedback-alert-header-spacing-gap flex items-center">
                        <div data-testid={`alert-icon--${variant}`.replace(/\s+/g, ' ')}>
                           <Icon size="heading-3" color="inherit" icon={alertIcon()} />
                        </div>
                        <div data-testid="alert-headline">
                           <Typography variant="heading-4" color="inherit">
                              {headline}
                           </Typography>
                        </div>
                     </div>
                  )}
                  {closable && (
                     <div data-testid="alert-close">
                        <IconButton onClick={handleCloseClick} icon="times" variant="inherit" />
                     </div>
                  )}
               </div>
            )}
            {children && (
               <div
                  data-testid="alert-body"
                  className={`
                              ${variant === 'neutral' && 'text-component-feedback-alert-body-color-text-on-neutral'}
                              ${variant === 'success' && 'text-component-feedback-alert-body-color-text-on-success'}
                              ${variant === 'warning' && 'text-component-feedback-alert-body-color-text-on-warning'}
                              ${variant === 'error' && 'text-component-feedback-alert-body-color-text-on-danger'}
                              ${variant === 'info' && 'text-component-feedback-alert-body-color-text-on-info'}
                          `.replace(/\s+/g, ' ')}
               >
                  <Typography color="inherit" component="p" variant="body">
                     {children}
                  </Typography>
               </div>
            )}
         </div>
         {button && (
            <div data-testid="alert-button" className="flex justify-start" onClick={handleButtonClick} role="presentation">
               <Button variant="tertiary">{button}</Button>
            </div>
         )}
      </div>
   );
};
