const colorNameToHex: Record<string, string> = {
  amber: '#ffbf00',
  artic_white: '#ffffff',
  beige: '#f5f5dc',
  black: '#000000',
  blue: '#31639c',
  blue_ice: '#31639c',
  brilliant_white: '#ffffff',
  bronze: '#9c5221',
  brown: '#a37063',
  cash_register_white: '#ffffff',
  clear: '#ffffff',
  clear_blue: '#31639c',
  crystal_clear: '#ffffff',
  crystal_oxidised: '#ffffff',
  cyan: '#2cb7b9',
  gold: '#E7BD42',
  green: '#49B675',
  greige: '#EDEEE8',
  grey: '#909090',
  ivory: '#F2E7BF',
  light: '#ffffff',
  light_blue: '#C3E4E8',
  metallic: '#738184',
  mixed_colors: '#ffffff',
  natural: '#ffffff',
  natural_black: '#000000',
  natural_oxidised: '#ffffff',
  natural_white: '#ffffff',
  opal: '#A8C3BC',
  orange: '#FC9303',
  pink: '#FFAAAA',
  purple: '#5A005A',
  red: '#C4342d',
  seperate: '#ffffff',
  silver: '#b5b5bd',
  stone_effect: '#ffffff',
  stryker_effect: '#ffffff',
  terracotta: '#d07c50',
  translucent: '#E5E6D1',
  transparent: '#ffffff',
  turquoise: '#006B5A',
  twilight_black: '#000000',
  violet: '#D2A8D4',
  violet_trans: '#D2A8D4',
  white: '#ffffff',
  water_white: '#F3f0e0',
  white_trans: '#ffffff',
  wild_rice: '#64550',
  yellow: '#FCE903',
  other: '#EDEEE8',
  custom: '#EDEEE8'
};

export function getColorCode(colorName: string | undefined): string {
  if (!colorName) return '#ffffff';
  return colorNameToHex[colorName] ?? '#ffffff';
}
