import { FC, ReactNode } from 'react';

import { Table } from '@ravago/shared/radiance/components/table/components/Table/Table';
import { TableCell } from '@ravago/shared/radiance/components/table/components/TableCell/TableCell';
import { TableHeader } from '@ravago/shared/radiance/components/table/components/TableHeader/TableHeader';
import { TableRow } from '@ravago/shared/radiance/components/table/components/TableRow/TableRow';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { documentToReactComponents, Options } from '@contentful/rich-text-react-renderer';
import { BLOCKS, Document, INLINES } from '@contentful/rich-text-types';

import { Link } from '../link/link';

const defaultOptions: Options = {
   renderText: (text) =>
      // eslint-disable-next-line react/no-array-index-key
      text.split('\n').reduce((children, textSegment, index) => [...children, index > 0 && <br key={index} />, textSegment], [] as ReactNode[])
};

const optionsPrimary: Options = {
   ...defaultOptions,
   renderNode: {
      [BLOCKS.PARAGRAPH]: (_, children) => (
         <Typography variant="body" component="p">
            {children}
         </Typography>
      ),
      [BLOCKS.HEADING_1]: (_, children) => (
         <Typography variant="heading-1" component="h1">
            {children}
         </Typography>
      ),
      [BLOCKS.HEADING_2]: (_, children) => (
         <Typography variant="heading-2" component="h2">
            {children}
         </Typography>
      ),
      [BLOCKS.HEADING_3]: (_, children) => (
         <Typography variant="heading-3" component="h3">
            {children}
         </Typography>
      ),
      [BLOCKS.HEADING_4]: (_, children) => (
         <Typography variant="heading-4" component="h4">
            {children}
         </Typography>
      ),
      [BLOCKS.TABLE]: (_, children) => <Table stroked="horizontal">{children}</Table>,
      [BLOCKS.TABLE_HEADER_CELL]: (_, children) => <TableHeader>{children}</TableHeader>,
      [BLOCKS.TABLE_ROW]: (_, children) => <TableRow>{children}</TableRow>,
      [BLOCKS.TABLE_CELL]: (_, children) => <TableCell>{children}</TableCell>,
      [INLINES.HYPERLINK]: (node, children) => {
         const { data } = node;
         const { uri } = data;
         return <Link href={uri}>{children}</Link>;
      }
   }
};

const optionsInverse: Options = {
   ...defaultOptions,
   renderNode: {
      [BLOCKS.PARAGRAPH]: (_, children) => (
         <Typography variant="body" component="p" color="inverse-strong">
            {children}
         </Typography>
      ),
      [BLOCKS.HEADING_1]: (_, children) => (
         <Typography variant="heading-1" component="h1" color="inverse-strong">
            {children}
         </Typography>
      ),
      [BLOCKS.HEADING_2]: (_, children) => (
         <Typography variant="heading-2" component="h2" color="inverse-strong">
            {children}
         </Typography>
      ),
      [BLOCKS.HEADING_3]: (_, children) => (
         <Typography variant="heading-3" component="h3" color="inverse-strong">
            {children}
         </Typography>
      ),
      [BLOCKS.HEADING_4]: (_, children) => (
         <Typography variant="heading-4" component="h4" color="inverse-strong">
            {children}
         </Typography>
      ),
      [BLOCKS.TABLE]: (_, children) => <Table stroked="horizontal">{children}</Table>,
      [BLOCKS.TABLE_HEADER_CELL]: (_, children) => <TableHeader>{children}</TableHeader>,
      [BLOCKS.TABLE_ROW]: (_, children) => <TableRow>{children}</TableRow>,
      [BLOCKS.TABLE_CELL]: (_, children) => <TableCell>{children}</TableCell>,
      [INLINES.HYPERLINK]: (node, children) => {
         const { data } = node;
         const { uri } = data;
         return (
            <Link color="light" href={uri}>
               {children}
            </Link>
         );
      }
   }
};

interface Props {
   document: Document;
   color: 'primary' | 'inverse';
}

export const ContentfulDocument: FC<Props> = ({ document, color = 'primary' }) => {
   const options = color === 'primary' ? optionsPrimary : optionsInverse;
   return documentToReactComponents(document, options);
};
