import { FC } from 'react';

interface Props {
   orientation?: 'horizontal' | 'vertical';
   color?: 'default' | 'primary' | 'secondary' | 'tertiary' | 'light' | 'dark';
}

export const Divider: FC<Props> = ({ orientation = 'horizontal', color = 'default' }) => (
   <>
      {orientation === 'horizontal' && (
         <div data-testid={`divider--horizontal--${color}`} className="py-component-data-display-divider-container-spacing-padding-y-horizontal inline-flex w-full">
            <hr
               className={`
                        ${color === 'default' ? 'border-component-data-display-divider-line-color-fill-default' : ''}
                        ${color === 'primary' ? 'border-component-data-display-divider-line-color-fill-primary' : ''}
                        ${color === 'secondary' ? 'border-component-data-display-divider-line-color-fill-secondary' : ''}
                        ${color === 'tertiary' ? 'border-component-data-display-divider-line-color-fill-tertiary' : ''}
                        ${color === 'light' ? 'border-component-data-display-divider-line-color-fill-light' : ''}
                        ${color === 'dark' ? 'border-component-data-display-divider-line-color-fill-dark' : ''}
                        w-full border-t-component-data-display-divider-line-sizing-height-horizontal`.replace(/\s+/g, ' ')}
            />
         </div>
      )}

      {orientation === 'vertical' && (
         <div data-testid={`divider--vertical--${color}`} className="px-component-data-display-divider-container-spacing-padding-x-vertical inline-flex h-full">
            <hr
               className={`${color === 'default' ? 'border-component-data-display-divider-line-color-fill-default' : ''}
                        ${color === 'primary' ? 'border-component-data-display-divider-line-color-fill-primary' : ''}
                        ${color === 'secondary' ? 'border-component-data-display-divider-line-color-fill-secondary' : ''}
                        ${color === 'tertiary' ? 'border-component-data-display-divider-line-color-fill-tertiary' : ''}
                        ${color === 'light' ? 'border-component-data-display-divider-line-color-fill-light' : ''}
                        ${color === 'dark' ? 'border-component-data-display-divider-line-color-fill-dark' : ''}
                         h-full border-t-0 border-r-component-data-display-divider-line-sizing-width-vertical`.replace(/\s+/g, ' ')}
            />
         </div>
      )}
   </>
);
