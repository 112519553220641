import { QueryOptions } from 'contentful-management';

export class QueryBuilder {
   private queryOptions = new Map<string, string | number>();

   constructor(base?: QueryBuilder) {
      if (base) {
         const result = base.build();
         Object.entries(result).forEach(([value, key]) => {
            this.queryOptions.set(value, key);
         });
      }
   }

   contentType(value: string): QueryBuilder {
      return this.set('content_type', value);
   }

   locale(value: string): QueryBuilder {
      return this.set('locale', value);
   }

   limit(limit: number): QueryBuilder {
      return this.set('limit', limit);
   }

   skip(skip: number): QueryBuilder {
      return this.set('skip', skip);
   }

   search(value?: string): QueryBuilder {
      return value?.length ? this.set('query', value) : this;
   }

   order(order: string): QueryBuilder {
      return this.set('order', order);
   }

   include(value: number): QueryBuilder {
      return this.set('include', value);
   }

   set(key: string, value: string | number): QueryBuilder {
      return this.setOptional(key, value);
   }

   setArrayOptional(key: string, value: string[] | number[] | undefined | null): QueryBuilder {
      return this.setOptional(key, value?.join(','));
   }

   setOptional(key: string, value: string | number | undefined | null): QueryBuilder {
      if (value) this.queryOptions.set(key, value);
      return this;
   }

   copy(): QueryBuilder {
      return new QueryBuilder(this);
   }

   build(): QueryOptions {
      return Object.fromEntries(this.queryOptions);
   }
}
