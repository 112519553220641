'use client';

import { createContext, FC, PropsWithChildren, useContext, useMemo } from 'react';

import { AuthenticationConfig } from '../models/authentication-config.model';

const AuthenticationConfigContext = createContext<AuthenticationConfig | null>(null);

export const useAuthenticationConfigContext = () => {
   const context = useContext(AuthenticationConfigContext);

   if (!context) {
      throw new Error('useAuthenticationConfigContext must be used within a AuthenticationConfigContextProvider');
   }

   return context;
};

interface AuthenticationConfigContextProviderProps extends PropsWithChildren {
   authenticationConfig: AuthenticationConfig;
}

export const AuthenticationConfigContextProvider: FC<AuthenticationConfigContextProviderProps> = ({ authenticationConfig, children }) => {
   const authenticationConfigContextValue = useMemo(
      () => authenticationConfig,
      [authenticationConfig]
   );

   return (
      <AuthenticationConfigContext.Provider value={authenticationConfigContextValue}>
         {children}
      </AuthenticationConfigContext.Provider>
   );
};
