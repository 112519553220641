'use client';

import { AriaRole, FC, PropsWithChildren, ReactNode } from 'react';

import { FloatingPortal, autoUpdate, offset, shift, size, useDismiss, useFloating, useInteractions, flip } from '@floating-ui/react';

import classnames from 'classnames';

interface Props extends PropsWithChildren {
   open?: boolean;
   closeOnOutsideClick?: boolean;
   align?: 'left' | 'right';
   width?: 'fit' | 'fill';
   toggleWidth?: 'full' | 'auto';
   maxHeight?: boolean;
   content: ReactNode;
   variant?: 'default' | 'alternate';
   role?: AriaRole;
   onMenuClose?: () => void;
}

export const Menu: FC<Props> = ({ open = false, closeOnOutsideClick = true, align = 'left', width = 'fit', toggleWidth = 'full', maxHeight = false, children, content, variant = 'default', role, onMenuClose }) => {
   const { refs, floatingStyles, context } = useFloating({
      placement: align === 'left' ? 'bottom-start' : /* istanbul ignore next */ 'bottom-end',
      strategy: 'fixed',
      whileElementsMounted: autoUpdate,
      open,
      onOpenChange: (isOpen) => {
         /* istanbul ignore next */
         if (!isOpen) onMenuClose?.();
      },
      middleware: [
         offset(5),
         shift(),
         flip(),
         size({
            apply({ rects, elements, availableHeight }) {
               Object.assign(elements.floating.style, {
                  minWidth: width === 'fill' ? `${rects.reference.width}px` : 'fit-content'
               });
            },
            padding: 10
         })
      ]
   });

   const dismiss = useDismiss(context, { enabled: closeOnOutsideClick });

   const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

   return (
      <>
         {children && (
            <div ref={refs.setReference} {...getReferenceProps()} data-testid="menu-toggle" className={classnames('inline-flex cursor-pointer', toggleWidth === 'full' ? 'w-full' : 'w-auto')}>
               {children}
            </div>
         )}
         {open && (
            <FloatingPortal>
               <div
                  ref={refs.setFloating}
                  style={floatingStyles}
                  {...getFloatingProps()}
                  data-testid="menu-content"
                  className={classnames('py-component-navigation-menu-container-spacing-padding-y rounded-component-navigation-menu-container-border-radius shadow-component-navigation-menu-container-box-shadow z-[9999]', {
                     'bg-component-navigation-menu-container-color-background-default': variant === 'default',
                     'bg-component-navigation-menu-container-color-background-alternate': variant === 'alternate',
                     'max-h-component-navigation-menu-container-sizing-max-height-on-text-fields overflow-y-auto': maxHeight
                  })}
                  role={role}
               >
                  {content}
               </div>
            </FloatingPortal>
         )}
      </>
   );
};
