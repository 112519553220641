import { FC, ReactNode } from 'react';

import { Divider } from '../../../divider/components/Divider/Divider';

interface Props {
   header: ReactNode;
   body?: ReactNode;
   open?: boolean;
   onHeaderClick?: () => void;
}

export const Accordion: FC<Props> = ({ header, body, open = false, onHeaderClick }) => (
   <div
      data-testid="accordion"
      className="px-component-surfaces-accordion-container-spacing-padding-x py-component-surfaces-accordion-container-spacing-padding-y bg-component-surfaces-accordion-container-color-background-enabled hover:bg-component-surfaces-accordion-container-color-background-hover border-component-surfaces-accordion-container-color-border border-component-surfaces-accordion-container-border-width rounded-component-surfaces-accordion-container-border-radius hover:shadow-component-surfaces-accordion-container-box-shadow-hover gap-component-surfaces-accordion-container-spacing-gap flex flex-col transition-all"
   >
      <button type="button" data-testid="accordion-header" onClick={() => onHeaderClick?.()}>
         {header}
      </button>
      {body && open && (
         <div data-testid="accordion-body" className="gap-component-surfaces-accordion-container-spacing-gap flex w-full flex-col">
            <Divider />
            {body}
         </div>
      )}
   </div>
);
