'use client';

import { FC, ReactNode, useEffect, useMemo, useState } from 'react';

import classnames from 'classnames';

import { Icon } from '../../../icon/components/Icon/Icon';
import { Typography } from '../../../typography/components/Typography/Typography';

interface Props {
   src?: string;
   title?: string;
   size?: 'extra-small' | 'small' | 'medium' | 'large' | 'extra-large';
   shape?: 'circle' | 'square';
   shadow?: boolean;
   variant?: 'default' | 'account';
   fallback?: ReactNode;
}

export const Avatar: FC<Props> = ({ src, title, size = 'medium', shape = 'circle', fallback, shadow = true, variant = 'default' }) => {
   const [showFallback, setShowFallback] = useState(false);

   useEffect(() => setShowFallback(!src), [src]);

   const handleImageError = () => setShowFallback(true);

   const initials = useMemo(() => {
      if (!title || typeof title !== 'string') return '';

      const words = title.trim().split(/\s+/);

      if (words.length === 1) {
         return words[0][0].toUpperCase();
      }

      return `${words[0][0].toUpperCase()}${words[words.length - 1][0].toUpperCase()}`;
   }, [title]);

   return (
      <div
         data-testid={`avatar--${size}--${shape}${!shadow ? '--no-shadow' : ''}`}
         className={classnames(
            'overflow-hidden',
            variant === 'default' && 'text-component-data-display-avatar-label-color-text-default bg-component-data-display-avatar-container-color-background-default',
            variant === 'account' && 'text-component-data-display-avatar-label-color-text-account bg-component-data-display-avatar-container-color-background-account',
            shadow && 'shadow-component-data-display-avatar-container-box-shadow',
            size === 'extra-small' && 'size-component-data-display-avatar-content-sizing-xs min-h-component-data-display-avatar-content-sizing-xs min-w-component-data-display-avatar-content-sizing-xs',
            size === 'small' && 'size-component-data-display-avatar-content-sizing-sm min-h-component-data-display-avatar-content-sizing-sm min-w-component-data-display-avatar-content-sizing-sm',
            size === 'medium' && 'size-component-data-display-avatar-content-sizing-md min-h-component-data-display-avatar-content-sizing-md min-w-component-data-display-avatar-content-sizing-md',
            size === 'large' && 'size-component-data-display-avatar-content-sizing-lg min-h-component-data-display-avatar-content-sizing-lg min-w-component-data-display-avatar-content-sizing-lg',
            size === 'extra-large' && 'size-component-data-display-avatar-content-sizing-xl min-h-component-data-display-avatar-content-sizing-xl min-w-component-data-display-avatar-content-sizing-xl',
            shape === 'circle' && 'rounded-component-data-display-avatar-container-border-radius-pill',
            shape === 'square' && 'rounded-component-data-display-avatar-container-border-radius-base'
         )}
      >
         {!showFallback ? (
            <img data-testid="avatar-image" src={src} alt={title} title={title} onError={handleImageError} className="aspect-square size-full object-contain" />
         ) : (
            <>
               {fallback && (
                  <div data-testid={`avatar-fallback--provided--${size}`} className="flex size-full items-center justify-center">
                     {fallback}
                  </div>
               )}
               {!fallback && title && (
                  <div data-testid={`avatar-fallback--title--${size}`} className="flex size-full items-center justify-center">
                     {size === 'extra-small' && (
                        <Typography variant="small-highlight" color="inherit">
                           {initials}
                        </Typography>
                     )}
                     {size === 'small' && (
                        <Typography variant="body" color="inherit">
                           {initials}
                        </Typography>
                     )}
                     {size === 'medium' && (
                        <Typography variant="heading-3" color="inherit">
                           {initials}
                        </Typography>
                     )}
                     {size === 'large' && (
                        <Typography variant="heading-2" color="inherit">
                           {initials}
                        </Typography>
                     )}
                     {size === 'extra-large' && (
                        <Typography variant="heading-1" color="inherit">
                           {initials}
                        </Typography>
                     )}
                  </div>
               )}
               {!fallback && !title && (
                  <div data-testid={`avatar-fallback--empty--${size}`} className="flex size-full items-center justify-center">
                     <Icon icon="image" />
                  </div>
               )}
            </>
         )}
      </div>
   );
};
