import { FC, PropsWithChildren } from 'react';

import { SystemPageType } from '@ravago/shared/page-data/enums/system-page-type';
import { SystemPageUtils } from '@ravago/shared/page-data/utils/system-page';

import { Link } from '../../shared/link/link';

interface SystemPageLinkProps extends PropsWithChildren {
   anchor?: string;
   baseRoute: string | undefined;
   locale: string;
   type: SystemPageType;
   slug?: string;
   underline?: 'none' | 'hover' | 'always';
}

const getRoute = (locale: string, baseRoute: string | undefined, slug: string | undefined, type: SystemPageType, anchor: string | undefined) => {
   if (!baseRoute) return `/${locale}${anchor ? `#${anchor}` : ''}`;
   const variableName = SystemPageUtils.getSystemPageVariableName(type);

   if (slug && variableName) {
      const route = baseRoute.replace(variableName, slug);
      return `/${locale}/${route}${anchor ? `#${anchor}` : ''}`;
   }

   return `/${locale}/${baseRoute}${anchor ? `#${anchor}` : ''}`;
};

export const SystemPageLink: FC<SystemPageLinkProps> = ({ baseRoute, locale, slug, type, anchor, underline = 'none', children }) => {
   const href = baseRoute ? getRoute(locale, baseRoute, slug, type, anchor) : '';

   return (
      <Link href={href} underline={underline} fullWidth data-testid="system-page-link__link">
         {children}
      </Link>
   );
};
