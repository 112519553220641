import { FC, ReactNode } from 'react';

import { Typography } from '../../../typography/components/Typography/Typography';

interface Props {
   align?: 'left' | 'center' | 'right';
   colSpan?: number;
   rowSpan?: number;
   scope?: 'col' | 'row';
   onClick?: () => void;
   children: ReactNode;
   width?: string;
   whiteSpace?: 'normal' | 'nowrap';
   gutter?: boolean;
}

export const TableHeader: FC<Props> = ({ align = 'left', colSpan = 1, rowSpan = 1, scope = 'col', children, onClick, width, whiteSpace, gutter = true }) => (
   <th
      data-testid={`table-header${!gutter ? '--no-gutter' : ''}`}
      align={align}
      colSpan={colSpan}
      rowSpan={rowSpan}
      scope={scope}
      onClick={onClick}
      className={`snap-start
          ${gutter ? 'px-component-data-display-table-cell-spacing-padding-x py-component-data-display-table-cell-spacing-padding-y' : ''}
          ${whiteSpace === 'nowrap' ? 'whitespace-nowrap' : ''}
          ${whiteSpace === 'normal' ? 'whitespace-normal' : ''}
        `}
      style={{ minWidth: width, maxWidth: width }}
   >
      <Typography variant="body-highlight" color="inherit">
         {children}
      </Typography>
   </th>
);
