'use client';

import { FC, PropsWithChildren, useMemo, useState } from 'react';

import classNames from 'classnames';

import RadioGroupContext from './RadioGroupContext';

type BoxSpacing = 'none' | '3xs' | '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl';

interface Props extends PropsWithChildren {
   name?: string;
   gap?: BoxSpacing;
   value?: any;
   onChange?: (value: any) => void;
}

const gapClassNames = {
   '2xl': 'gap-component-layout-box-spacing-gap-2xl',
   '2xs': 'gap-component-layout-box-spacing-gap-2xs',
   '3xl': 'gap-component-layout-box-spacing-gap-3xl',
   '3xs': 'gap-component-layout-box-spacing-gap-3xs',
   lg: 'gap-component-layout-box-spacing-gap-lg',
   md: 'gap-component-layout-box-spacing-gap-md',
   none: 'gap-component-layout-box-spacing-gap-none',
   sm: 'gap-component-layout-box-spacing-gap-sm',
   xl: 'gap-component-layout-box-spacing-gap-xl',
   xs: 'gap-component-layout-box-spacing-gap-xs'
};

export const RadioGroup: FC<Props> = ({ name, gap = 'none', value: propValue, onChange, children }) => {
   const [value, setValueState] = useState<any | undefined>(propValue);

   const contextValue = useMemo(
      () => ({
         name,
         onChange(newValue: any) {
            setValueState(newValue);

            if (onChange) {
               onChange(newValue);
            }
         },
         value
      }),
      [onChange, setValueState, value]
   );

   return (
      <fieldset data-testid="radio-group" className={classNames('inline-flex flex-col', gapClassNames[gap])}>
         <RadioGroupContext.Provider value={contextValue}>{children}</RadioGroupContext.Provider>
      </fieldset>
   );
};
