import { useContext } from 'react';

import { RequestForQuotationContext } from '../context/request-for-quotation-context';

export const useRequestForQuotationContext = () => {
   const context = useContext(RequestForQuotationContext);
   if (!context) {
      throw new Error('useRequestForQuotationContext must be used within a RequestForQuotationContextProvider');
   }
   return context;
};
