import { UnitPrice } from '@ravago/shared/page-data/models/elements';
import { TableCell } from '@ravago/shared/radiance/components/table/components/TableCell/TableCell';
import { TableRow } from '@ravago/shared/radiance/components/table/components/TableRow/TableRow';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { useTranslations } from '../../../providers/translation-client-context-provider';

interface Props {
   unitPrice: UnitPrice;
   isUnitPriceFixed: boolean;
}

export const OrderPriceInfoRow = ({ unitPrice, isUnitPriceFixed }: Props) => {
   const translate = useTranslations();

   return (
      <TableRow>
         <TableCell>
            <Typography component="b" variant="body-highlight" color="primary">
               {translate('my-orders.unit-price')}
            </Typography>
         </TableCell>
         <TableCell>
            {isUnitPriceFixed
               ? `${unitPrice.price.amount} ${unitPrice.price.currency} / ${unitPrice.unitOfMeasure}`
               : translate('my-orders.unit-price-tbc')}
         </TableCell>
      </TableRow>
   );
};
