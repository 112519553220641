/* c8 ignore start */
import { ContentfulAsset } from '@ravago/shared/page-data/models/contentful';

import { Asset } from 'contentful';

export class AssetUtils {
   static optimizeImageIfRequired(src: string, acceptWebp?: boolean, width?: number, maxWidth?: number): string {
      if (!src || !src.includes('images.ctfassets')) return src;

      let optimizedSrc = src;
      if (!src.includes('?fm=') && !src.includes('&fm=') && acceptWebp) {
         optimizedSrc = `${optimizedSrc}${optimizedSrc.includes('?') ? '&' : '?'}fm=webp`;
      }

      if (!src.includes('?w=') && !src.includes('&w=') && width) {
         const calculatedWidth = maxWidth && width > maxWidth ? maxWidth : width;
         optimizedSrc = `${optimizedSrc}${optimizedSrc.includes('?') ? '&' : '?'}w=${calculatedWidth}`;
      }

      return optimizedSrc;
   }

   static mapToImage(item?: Asset): ContentfulAsset | undefined {
      if (!item?.fields) return undefined;

      return {
         sys: {
            id: item.sys.id
         },
         title: item.fields.title,
         url: item.fields.file.url,
         width: item.fields.file.details.image?.width,
         height: item.fields.file.details.image?.height
      };
   }
}
/* c8 ignore stop */
