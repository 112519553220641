'use client';

import { FC, ReactNode, useEffect, useMemo, useRef, useState } from 'react';

import { Typography } from '../../../typography/components/Typography/Typography';

export interface CheckboxState {
   checked: boolean;
   indeterminate: boolean;
}

interface Props {
   id: string;
   checked?: boolean;
   disabled?: boolean;
   indeterminate?: boolean;
   required?: boolean;
   readOnly?: boolean;
   gutter?: boolean;
   child?: boolean;
   whiteSpace?: 'normal' | 'nowrap';
   onChange?: (state: CheckboxState) => void;
   children?: ReactNode;
}

const getIcon = (indeterminate: boolean, checked: boolean) => {
   if (indeterminate) {
      return (
         <svg width="8" height="2" viewBox="0 0 8 2" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.5625 0H0.9375C0.390625 0 0 0.429688 0 0.9375C0 1.48438 0.390625 1.875 0.9375 1.875H6.5625C7.07031 1.875 7.5 1.48438 7.5 0.9375C7.5 0.429688 7.07031 0 6.5625 0Z" />
         </svg>
      );
   }

   if (checked) {
      return (
         <svg width="10" height="7" viewBox="0 0 10 7" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.12109 6.5918L9.08203 1.5918C9.47266 1.24023 9.47266 0.654297 9.08203 0.263672C8.73047 -0.0878906 8.14453 -0.0878906 7.79297 0.263672L3.45703 4.63867L1.62109 2.80273C1.23047 2.41211 0.644531 2.41211 0.292969 2.80273C-0.0976562 3.1543 -0.0976562 3.74023 0.292969 4.0918L2.79297 6.5918C3.14453 6.98242 3.73047 6.98242 4.12109 6.5918Z" />
         </svg>
      );
   }

   return null;
};

export const Checkbox: FC<Props> = ({ id, checked = false, disabled = false, indeterminate = false, required = false, readOnly = false, gutter = true, child = false, whiteSpace = 'normal', onChange, children }) => {
   const [state, setState] = useState<CheckboxState>({
      checked,
      indeterminate
   });

   const checkboxRef = useRef<HTMLInputElement>(null);

   const handleChange = () => {
      if (readOnly) return;

      setState({
         checked: !state.checked,
         indeterminate: false
      });

      onChange?.({ checked: !state.checked, indeterminate });
   };

   useEffect(() => {
      setState({
         checked,
         indeterminate
      });

      /* istanbul ignore else */
      if (checkboxRef.current) checkboxRef.current.indeterminate = indeterminate;
   }, [checked, indeterminate]);

   const icon = useMemo(() => getIcon(state.indeterminate, state.checked), [state]);

   return (
      <div
         data-testid={`checkbox${child ? '--child' : ''}`}
         role="presentation"
         className={`gap-[calc(var(--component-input-checkbox-container-spacing-gap)-var(--component-input-checkbox-state-layer-spacing-padding))] flex cursor-pointer items-center
              ${child ? 'pl-component-input-checkbox-touch-area-spacing-padding-left-child' : ''}
              ${disabled || readOnly ? 'pointer-events-none' : ''}
              ${gutter ? 'min-h-component-input-checkbox-touch-area-sizing-min-size min-w-component-input-checkbox-touch-area-sizing-min-size' : ''}
           `.replace(/\s+/g, ' ')}
      >
         <div data-testid={`checkbox-touch-area${!gutter ? '--no-gutter' : ''}`} className={` inline-flex items-center justify-center ${!readOnly ? '' : 'group'} ${gutter ? '' : '-ml-component-input-checkbox-state-layer-spacing-padding -my-component-input-checkbox-state-layer-spacing-padding'}`}>
            <div
               className={`relative rounded-component-input-checkbox-state-layer-border-radius p-component-input-checkbox-state-layer-spacing-padding flex items-center justify-center transition-all
                    ${
                       !disabled && !readOnly && (state.checked || state.indeterminate)
                          ? 'group-hover:bg-component-input-checkbox-state-layer-color-background-checked-hover group-focus-within:bg-component-input-checkbox-state-layer-color-background-checked-focused group-active:bg-component-input-checkbox-state-layer-color-background-checked-pressed'
                          : ''
                    }
                    ${
                       !disabled && !readOnly && !state.checked && !state.indeterminate
                          ? 'group-hover:bg-component-input-checkbox-state-layer-color-background-unchecked-hover group-focus-within:bg-component-input-checkbox-state-layer-color-background-unchecked-focused group-active:bg-component-input-checkbox-state-layer-color-background-unchecked-pressed'
                          : ''
                    }
                `.replace(/\s+/g, ' ')}
            >
               <input
                  ref={checkboxRef}
                  data-testid="checkbox-input"
                  id={id}
                  type="checkbox"
                  checked={state.checked}
                  disabled={disabled}
                  required={required}
                  onChange={handleChange}
                  className="after:bg-component-input-checkbox-container-color-background-unchecked-enabled after:disabled:bg-component-input-checkbox-container-color-background-unchecked-disabled disabled:checked:after:bg-component-input-checkbox-container-color-background-checked-disabled disabled:indeterminate:after:bg-component-input-checkbox-container-color-background-checked-disabled checked:after:bg-component-input-checkbox-container-color-background-checked-enabled indeterminate:after:bg-component-input-checkbox-container-color-background-checked-enabled after:border-component-input-checkbox-container-border-width-unchecked checked:after:border-component-input-checkbox-container-border-width-checked indeterminate:after:border-component-input-checkbox-container-border-width-checked after:rounded-component-input-checkbox-container-border-radius after:border-component-input-checkbox-container-color-border-unchecked-enabled disabled:after:border-component-input-checkbox-container-color-border-unchecked-disabled after:w-component-data-display-icon-container-sizing-width-body cursor-pointer appearance-none after:box-border after:block after:aspect-square after:transition-colors"
               />
               <span className={`pointer-events-none absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 ${disabled ? 'fill-component-input-checkbox-icon-color-fill-disabled' : 'fill-component-input-checkbox-icon-color-fill-enabled'}`}>{icon}</span>
            </div>
         </div>
         {children && (
            <label htmlFor={id} className={`cursor-pointer ${disabled ? 'text-component-input-checkbox-label-color-text-disabled' : 'text-component-input-checkbox-label-color-text-enabled'}`}>
               <Typography variant="body" whiteSpace={whiteSpace} color="inherit">
                  {children}
               </Typography>
            </label>
         )}
      </div>
   );
};
