'use client';

import { ChangeEvent, FC, KeyboardEvent, useEffect, useRef, useState } from 'react';

import { Icon } from '../../../icon/components/Icon/Icon';
import { Typography } from '../../../typography/components/Typography/Typography';

interface Props {
   id: string;
   clearable?: boolean;
   disabled?: boolean;
   error?: boolean;
   errorMessage?: string;
   supportText?: string;
   label?: string;
   leadingIcon?: string;
   max?: string;
   min?: string;
   readOnly?: boolean;
   required?: boolean;
   value?: string;
   width?: string | number;
   validateWeekends?: boolean;
   weekendErrorMessage?: string;
   onClear?: () => void;
   onChange?: (value: string) => void;
   onEnter?: (value: string) => void;
   onWeekendValidationChange?: (isValid: boolean) => void;
}

export const DatePicker: FC<Props> = ({
   id,
   clearable = false,
   disabled = false,
   error = false,
   errorMessage,
   supportText,
   label,
   leadingIcon,
   max,
   min,
   readOnly = false,
   required = false,
   value = '',
   width,
   validateWeekends = false,
   weekendErrorMessage,
   onClear,
   onChange,
   onEnter,
   onWeekendValidationChange
}) => {
   const [inputValue, setInputValue] = useState(value);
   const [focused, setFocused] = useState(false);
   const [weekendError, setWeekendError] = useState(false);

   const inputRef = useRef<HTMLInputElement>(null);

   /* istanbul ignore next */
   const handleWeekendValidation = (date: string) => {
      if (validateWeekends) {
         const day = new Date(date).getDay();
         const isValid = !(day === 0 || day === 6);
         setWeekendError(!isValid);
         onWeekendValidationChange?.(isValid);
      }
   };

   /* istanbul ignore next */
   const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      setInputValue(event.target.value);
      handleWeekendValidation(event.target.value);
      onChange?.(event.target.value);
   };

   const handleClear = () => {
      setInputValue('');
      setWeekendError(false);
      onChange?.('');
      onClear?.();
      /* istanbul ignore next */
      onWeekendValidationChange?.(true);
   };

   /* istanbul ignore next */
   const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
         onEnter?.(inputValue);
      }
   };

   /* istanbul ignore next */
   const handleDatePickerToggle = () => {
      inputRef.current?.click();
      inputRef.current?.showPicker();

      inputRef.current?.focus();
   };

   useEffect(() => {
      setInputValue(value);
      handleWeekendValidation(value);
   }, [value]);

   return (
      <div data-testid={`date-picker${error ? '--error' : ''}`} style={{ width }} className="gap-component-input-date-picker-container-spacing-outer-gap flex w-full flex-col">
         <div className={`group relative cursor-text ${disabled ? 'pointer-events-none' : ''}`}>
            {label && (
               <label
                  data-testid="date-picker-label"
                  htmlFor={id}
                  className={`ml-component-input-date-picker-container-spacing-padding-x absolute left-0 top-0 flex items-center transition-all cursor-pointer bg-component-input-date-picker-container-color-background px-component-input-date-picker-label-spacing-padding-x max-w-[calc(100%-2*var(--component-input-date-picker-container-spacing-padding-x))]
                           ${!error && !disabled && (inputValue !== '' || focused) ? 'text-component-input-date-picker-label-color-text-neutral-enabled' : ''}
                           ${!error && !disabled && focused ? 'text-component-input-date-picker-label-color-text-neutral-focused' : ''}
                           ${disabled ? 'text-component-input-date-picker-label-color-text-neutral-disabled' : ''}
                           ${error && !disabled ? 'text-component-input-date-picker-label-color-text-error-enabled group-hover:text-component-input-date-picker-label-color-text-error-hover' : ''}
                           ${focused ? 'h-[var(--component-input-date-picker-container-border-width-focused)]' : 'h-[var(--component-input-date-picker-container-border-width-enabled)]'}
                      `.replace(/\s+/g, ' ')}
               >
                  <Typography variant="caption" color="inherit" maxLines="1">
                     {label}
                     {required && '*'}
                  </Typography>
               </label>
            )}

            <div
               data-testid="date-picker-container"
               onClick={() => inputRef.current?.focus()}
               role="presentation"
               className={`transition-all min-h-component-input-date-picker-container-sizing-min-height max-w-component-input-date-picker-container-sizing-max-width px-component-input-date-picker-container-spacing-padding-x py-component-input-date-picker-container-spacing-padding-y gap-component-input-date-picker-container-spacing-gap bg-component-input-date-picker-container-color-background rounded-component-input-date-picker-container-border-radius border-component-input-date-picker-container-border-width-enabled focus-within:!border-component-input-date-picker-container-border-width-focused box-border flex items-start justify-between
                           ${
                              error && !disabled
                                 ? '!border-component-input-date-picker-container-color-border-error-enabled hover:!border-component-input-date-picker-container-color-border-error-hover focus-within:!border-component-input-date-picker-container-color-border-error-enabled'
                                 : '!border-component-input-date-picker-container-color-border-neutral-enabled hover:!border-component-input-date-picker-container-color-border-neutral-hover focus-within:!border-component-input-date-picker-container-color-border-neutral-focused'
                           }
                  `.replace(/\s+/g, ' ')}
            >
               {leadingIcon && (
                  <div
                     data-testid="date-picker-leading-icon"
                     className={`w-component-data-display-icon-container-sizing-size-body m-auto flex aspect-square h-full items-center justify-center ${
                        disabled ? 'text-component-input-date-picker-icon-start-color-text-on-surface-disabled' : 'text-component-input-date-picker-icon-start-color-text-on-surface-enabled'
                     }`.replace(/\s+/g, ' ')}
                  >
                     <Icon size="body" icon={leadingIcon} />
                  </div>
               )}
               <div className="flex h-full flex-1 items-center">
                  <input
                     data-testid="date-picker-input"
                     className="datepicker:hidden text-component-input-date-picker-input-color-text-on-surface-enabled disabled:text-component-input-date-picker-input-color-text-on-surface-disabled typography-component-typography-body-regular-md w-full bg-transparent outline-none"
                     disabled={disabled}
                     id={id}
                     max={max}
                     min={min}
                     name={id}
                     onBlur={/* istanbul ignore next */ () => setFocused(false)}
                     onChange={handleChange}
                     onFocus={/* istanbul ignore next */ () => setFocused(true)}
                     onKeyUp={handleKeyPress}
                     readOnly={readOnly}
                     ref={inputRef}
                     required={required}
                     type="date"
                     value={inputValue}
                  />
               </div>
               {clearable && inputValue !== '' && (
                  <div
                     data-testid="date-picker-clear"
                     onClick={handleClear}
                     role="presentation"
                     className={`w-component-data-display-icon-container-sizing-size-body m-auto flex aspect-square h-full items-center justify-center cursor-pointer ${
                        disabled ? 'text-component-input-search-icon-end-color-text-on-surface-disabled' : 'text-component-input-search-icon-end-color-text-on-surface-enabled'
                     }`.replace(/\s+/g, ' ')}
                  >
                     <Icon size="body" icon="times-circle" />
                  </div>
               )}
               <div
                  data-testid="date-picker-trailing-icon"
                  onClick={handleDatePickerToggle}
                  role="presentation"
                  className={`cursor-pointer w-component-data-display-icon-container-sizing-size-body m-auto flex aspect-square h-full items-center justify-center ${
                     disabled ? 'text-component-input-date-picker-icon-end-color-text-on-surface-disabled' : 'text-component-input-date-picker-icon-end-color-text-on-surface-enabled'
                  }`.replace(/\s+/g, ' ')}
               >
                  <Icon size="body" icon="calendar-alt" />
               </div>
            </div>
         </div>
         {supportText && (
            <div
               data-testid="date-picker-support-text"
               className={`pl-component-input-date-picker-supporting-text-spacing-padding-left gap-component-input-date-picker-supporting-text-spacing-gap flex items-center
                        ${!disabled ? 'text-component-input-date-picker-supporting-text-color-text-neutral-enabled' : ''}
                        ${disabled ? 'text-component-input-date-picker-supporting-text-color-text-neutral-disabled' : ''}
                        `.replace(/\s+/g, ' ')}
            >
               <Typography variant="caption" color="inherit">
                  {supportText}
               </Typography>
            </div>
         )}
         {((error && errorMessage) || (weekendError && weekendErrorMessage)) && (
            <div data-testid="date-picker-error-message" className="pl-component-input-date-picker-supporting-text-spacing-padding-left gap-component-input-date-picker-supporting-text-spacing-gap text-component-input-date-picker-supporting-text-color-text-error flex items-center">
               <Icon icon="exclamation-circle" size="caption" />
               <Typography variant="caption" color="inherit">
                  {weekendError ? weekendErrorMessage : errorMessage}
               </Typography>
            </div>
         )}
      </div>
   );
};
