'use client';

import { createContext, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';

import { RelatedDocument } from '@ravago/shared/page-data/models/elements';

export interface WidgetContextProps {
   productsToCompareList: Array<string>;
   addProductsToCompare: (productId: string) => void;
   removeProductFromCompare: (productId: string) => void;
   removeAllProductsFromCompareList: () => void;
   shareDocumentList: { detailPageName: string; documents: RelatedDocument[] };
   downloadDocumentList: RelatedDocument[];
   setShareList: (documentInfo: { detailPageName: string; documents: RelatedDocument[] }) => void;
   setDownloadList: (documents: RelatedDocument[]) => void;
}

export const WidgetContext = createContext<WidgetContextProps | null>(null);

export const WidgetProvider = ({ children }: PropsWithChildren) => {
   const [productsToCompareList, setProductsToCompareList] = useState<Array<string>>([]);
   const [shareDocumentList, setShareDocumentList] = useState<{ detailPageName: string; documents: RelatedDocument[] }>({
      detailPageName: '',
      documents: []
   });
   const [downloadDocumentList, setDownloadDocumentList] = useState<RelatedDocument[]>([]);

   useEffect(() => {
      const storedProducts = localStorage.getItem('productsToCompare');
      if (storedProducts) {
         setProductsToCompareList(JSON.parse(storedProducts));
      }
   }, []);

   const setShareList = useCallback((documentInfo: { detailPageName: string; documents: RelatedDocument[] }) => {
      setShareDocumentList(documentInfo);
   }, []);

   const setDownloadList = useCallback((documents: RelatedDocument[]) => {
      setDownloadDocumentList(documents);
   }, []);

   const addProductToCompareList = useCallback((productId: string) => {
      setProductsToCompareList((prevProducts) => {
         if (prevProducts.some((product) => product === productId)) {
            return prevProducts;
         }

         const newProducts = [...prevProducts, productId];
         localStorage.setItem('productsToCompare', JSON.stringify(newProducts));
         return newProducts;
      });
   }, []);

   const removeProductFromCompareList = useCallback((productId: string) => {
      setProductsToCompareList((prevProducts) => {
         const newProducts = prevProducts.filter((product) => product !== productId);
         localStorage.setItem('productsToCompare', JSON.stringify(newProducts));
         return newProducts;
      });
   }, []);

   const removeAllProductsFromCompareList = useCallback(() => {
      setProductsToCompareList([]);
      localStorage.setItem('productsToCompare', JSON.stringify([]));
   }, []);

   const widgetContext: WidgetContextProps = useMemo(
      () => ({
         productsToCompareList,
         addProductsToCompare: addProductToCompareList,
         removeProductFromCompare: removeProductFromCompareList,
         removeAllProductsFromCompareList,
         shareDocumentList,
         setShareList,
         downloadDocumentList,
         setDownloadList
      }),
      [
         productsToCompareList,
         addProductToCompareList,
         removeProductFromCompareList,
         removeAllProductsFromCompareList,
         shareDocumentList,
         setShareList,
         downloadDocumentList,
         setDownloadList
      ]
   );

   return <WidgetContext.Provider value={widgetContext}>{children}</WidgetContext.Provider>;
};
