import { useContext } from 'react';

import { ConfigContext } from '../context/config-context';

export const useConfigContext = () => {
   const context = useContext(ConfigContext);

   if (!context) {
      throw new Error('useConfigContext must be used within a ConfigContext');
   }

   return context.config;
};
