/* eslint-disable no-use-before-define */
import { BasePageable } from '../base/base-pageable.model';
import { FacetFilter } from './facet-filter.model';

export interface AutoSuggestQueryArgs {
  autoSuggestRequest: AutoSuggestRequest;
  size: number;
  authToken?: string;
  language?: string;
}

export interface AutoSuggestRequest {
  query: string;
  facets?: Array<FacetFilter>;
}

export interface AutoSuggestResponse {
  searchResult: {
    content: Array<AutoSuggestResponseContent>;
  } & BasePageable;
  suggestedFilters?: Array<CombinedFacetResponse>;
}

export interface CombinedFacetResponse {
  filters: Array<CombinedFacetResponseContent>;
}

export interface CombinedFacetResponseContent extends AutoSuggestFacet {
  parent?: AutoSuggestFacet;
}

export interface AutoSuggestFacet {
  value: string;
  facetField: string;
  type: string;
}

export interface AutoSuggestResponseContent {
  label: string;
  type: AutoSuggestType;
  id?: number;
  fillers?: {
    name: string;
  };
  businessContexts?: {
    id: number;
    rank?: number;
    visibility?: string;
  };
  sellingCountries?: {
    businessContextId: number;
    isoCode: string;
  };
  description?: string;
  group?: string;
  subgroup?: string;
  features?: {
    id: number;
    name: string;
  };
  processingMethods?: {
    id?: number;
    businessContextId: number;
    name: string;
  };
  brand?: string;
  brandAggregate?: Array<{
    businessContextId: number;
    name: string;
  }>;
  producer?: string;
  quality?: string;
  industries?: {
    id: number;
    name: string;
    segments: Array<{
      id?: number;
      name?: string;
      rank?: number;
      businessContextId?: number;
    }>;
  };
  preferred?: number;
  confidential?: boolean;
  caseStudyType?: string;
  functionalKeyRequirements?: string;
  benefits?: string;
  slug?: string;
}

export const facetLabelTranslationMapping = new Map([
  ['industries.name.raw', 'products-overview-filters-market'],
  ['industries.segments.name.raw', 'products-overview-filters-segment'],
  ['brand.raw', 'products-overview-filters-brand'],
  ['features.name.raw', 'products-overview-filters-feature'],
  ['processingMethods.name.raw', 'products-overview-filters-technology'],
  ['features.raw', 'products-overview-filters-feature'],
  ['fillers.name.raw', 'products-overview-filters-filler'],
  ['sellingCountries', 'products-overview-filters-selling-country'],
  ['group.name.raw', 'products-overview-filters-polymer-group'],
  ['group.raw', 'products-overview-filters-polymer-group'],
  ['subgroup.name.raw', 'products-overview-filters-polymer-type'],
  ['subgroup.raw', 'products-overview-filters-polymer-type'],
  ['producer.name.raw', 'products-overview-filters-producer'],
  ['quality.name.raw', 'products-overview-filters-quality'],
  ['quality.raw', 'products-overview-filters-quality'],
  ['sustainabilityGroup.name.raw', 'products-overview-filters-sustainability'],
  ['sustainabilityGroup.subSustainability.name.raw', 'products-overview-filters-sub-sustainability']
]);

export const facetOrObjectTypeLabelTranslationMapping = new Map([
  ['Brand', 'products-overview-filters-brand'],
  ['Case Study', 'products-overview-filters-case-study'],
  ['Product', 'products-overview-filters-product'],
  ['Feature', 'products-overview-filters-feature'],
  ['Filler', 'products-overview-filters-filler'],
  ['Group', 'products-overview-filters-polymer-group'],
  ['Industry', 'products-overview-filters-market'],
  ['Processing Method', 'products-overview-filters-technology'],
  ['Producer', 'products-overview-filters-producer'],
  ['Quality', 'products-overview-filters-quality'],
  ['Selling Country', 'products-overview-filters-selling-country'],
  ['Subgroup', 'products-overview-filters-polymer-type']
]);

export const AUTO_SUGGEST_OBJECT_TYPES = ['Brand', 'Case Study', 'Product'] as const;

export type AutoSuggestObjectType = (typeof AUTO_SUGGEST_OBJECT_TYPES)[number];

export const AUTO_SUGGEST_FACET_TYPES = [
  'Feature',
  'Filler',
  'Group',
  'Industry',
  'Processing Method',
  'Producer',
  'Quality',
  'Selling Country',
  'Subgroup'
] as const;

export type AutoSuggestFacetType = (typeof AUTO_SUGGEST_FACET_TYPES)[number];

export const FILTER_KEYS = [
  'markets',
  'brands',
  'countries',
  'specialFeatures',
  'fillers',
  'polymerGroups',
  'polymerTypes',
  'technologies',
  'producers',
  'qualities',
  'specifications'
] as const;

export type ProductFilterKey = (typeof FILTER_KEYS)[number];

export type AutoSuggestType = AutoSuggestFacetType | AutoSuggestObjectType;
