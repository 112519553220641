import {
   CallOffOrder,
   DeliveryDate,
   DeliveryInfo,
   DisplayOrder,
   InvoiceInfo,
   MappedOrderHeader,
   MappedOrders,
   OrderHeader,
   OrderLineDetail,
   SalesOrder,
   ShipmentWithOrderLines,
   Status
} from '@ravago/shared/page-data/models/elements';

export class OrderMapperUtils {
   static convertFromSalesOrder(order: SalesOrder): DisplayOrder {
      return {
         customerOrderReference: order.customerOrderReference,
         customerProductReference: order.customerProductReference,
         deliveryDate: order.deliveryDate,
         orderNumber: order.id,
         packaging: order.packaging,
         product: order.product,
         contentfulProduct: order.contentfulProduct,
         quantity: order.quantity,
         status: order.status,
         unitPrice: order.unitPrice,
         shipmentIds: order.shipmentIds,
         isCallOff: false,
         orderedOn: order.orderedOn || '-',
         agreementNumber: order.agreementNumber,
         deliveryTermAddress: order.deliveryTermAddress
      };
   }

   static convertFromOrderLineDetail(order: OrderLineDetail): DisplayOrder {
      return {
         customerOrderReference: order.customerOrderReference,
         customerProductReference: order.customerProductReference,
         deliveryDate: order.deliveryDate,
         orderNumber: order.orderNumber,
         packaging: order.packaging,
         product: order.product,
         contentfulProduct: order.contentfulProduct,
         quantity: order.quantity,
         status: order.status,
         unitPrice: order.unitPrice,
         shipmentIds: order.shipmentIds,
         agreementNumber: order.agreementNumber,
         isCallOff: false
      };
   }

   static convertFromCallOffOrder(order: CallOffOrder): DisplayOrder {
      const date: DeliveryDate = { from: order.callOffPeriod.from, until: order.callOffPeriod.until, type: 'REQUESTED' };
      return {
         customerOrderReference: order.customerOrderReferences.length > 0 ? order.customerOrderReferences[0] : '',
         customerProductReference: order.customerProductReferences.length > 0 ? order.customerProductReferences[0] : '',
         deliveryDate: date,
         orderNumber: order.id,
         packaging: order.packagings.length > 0 ? order.packagings[0] : '',
         product: order.product,
         contentfulProduct: order.contentfulProduct,
         quantity: order.quantity,
         status: order.status,
         unitPrice: order.unitPrice,
         isCallOff: true
      };
   }

   static getMappedOrders(orders: DisplayOrder[]): MappedOrders {
      const inTransitShipments: ShipmentWithOrderLines[] = [];
      const transportArrangedShipments: ShipmentWithOrderLines[] = [];
      const openOrderLines: DisplayOrder[] = [];
      const closedOrderLines: DisplayOrder[] = [];
      const closedStatuses = ['Closed', 'Invoiced', 'Credited'];

      orders.forEach((order) => {
         if (order.shipmentIds && order.shipmentIds.length > 0 && !closedStatuses.includes(order.status)) {
            order.shipmentIds.forEach((shipmentId) => {
               if (order.status === 'In Transit') {
                  OrderMapperUtils.addOrderLineToShipment(inTransitShipments, order, shipmentId);
               }
               if (order.status === 'Transport Arranged') {
                  OrderMapperUtils.addOrderLineToShipment(transportArrangedShipments, order, shipmentId);
               }
            });
         } else {
            if (
               order.status === 'Planned' ||
               order.status === 'Order Received' ||
               order.status === 'Processing' ||
               order.status === 'Order Confirmed' ||
               order.status === 'Call Off Overdue' ||
               order.status === 'Awaiting Call Off'
            ) {
               openOrderLines.push(order);
            }
            if (closedStatuses.includes(order.status)) {
               closedOrderLines.push(order);
            }
         }
      });

      return {
         inTransitShipments: OrderMapperUtils.getSortedShipmentsWithOrderLines(inTransitShipments),
         transportArrangedShipments: OrderMapperUtils.getSortedShipmentsWithOrderLines(transportArrangedShipments),
         openOrderLines: OrderMapperUtils.getSortedOrderLinesByStatusByDeliveredDate(openOrderLines, true),
         closedOrderLines: OrderMapperUtils.getSortedOrderLinesByStatusByDeliveredDate(closedOrderLines)
      };
   }

   static getMappedOrderHeader(orderHeader: OrderHeader): MappedOrderHeader {
      const inTransitShipments: ShipmentWithOrderLines[] = [];
      const transportArrangedShipments: ShipmentWithOrderLines[] = [];
      const openOrderLines: DisplayOrder[] = [];
      const closedOrderLines: DisplayOrder[] = [];
      const closedStatuses = ['Closed', 'Invoiced', 'Credited'];

      orderHeader.orderLines.forEach((orderLine) => {
         if (orderLine.shipmentIds && orderLine.shipmentIds.length > 0 && !closedStatuses.includes(orderLine.status)) {
            orderLine.shipmentIds.forEach((shipmentId) => {
               if (orderLine.status === 'In Transit') {
                  OrderMapperUtils.addOrderLineToShipment(inTransitShipments, OrderMapperUtils.convertFromOrderLineDetail(orderLine), shipmentId);
               }
               if (orderLine.status === 'Transport Arranged') {
                  OrderMapperUtils.addOrderLineToShipment(
                     transportArrangedShipments,
                     OrderMapperUtils.convertFromOrderLineDetail(orderLine),
                     shipmentId
                  );
               }
            });
         } else {
            if (
               orderLine.status === 'Planned' ||
               orderLine.status === 'Processing' ||
               orderLine.status === 'Order Confirmed' ||
               orderLine.status === 'Order Received'
            ) {
               openOrderLines.push(OrderMapperUtils.convertFromOrderLineDetail(orderLine));
            }
            if (closedStatuses.includes(orderLine.status)) {
               closedOrderLines.push(OrderMapperUtils.convertFromOrderLineDetail(orderLine));
            }
         }
      });

      return {
         id: orderHeader.id,
         oleId: orderHeader.oleId,
         ole: orderHeader.ole,
         status: OrderMapperUtils.getOrderHeaderStatus(orderHeader),
         deliveryInfo: OrderMapperUtils.getOrderHeaderDeliveryInfo(orderHeader),
         invoiceInfo: OrderMapperUtils.checkShowInvoiceInfo(orderHeader),
         mappedOrders: {
            inTransitShipments: OrderMapperUtils.getSortedShipmentsWithOrderLines(inTransitShipments),
            transportArrangedShipments: OrderMapperUtils.getSortedShipmentsWithOrderLines(transportArrangedShipments),
            openOrderLines: OrderMapperUtils.getSortedOrderLinesByStatusByDeliveredDate(openOrderLines, true),
            closedOrderLines: OrderMapperUtils.getSortedOrderLinesByStatusByDeliveredDate(closedOrderLines)
         }
      };
   }

   static addOrderLineToShipment(shipments: ShipmentWithOrderLines[], orderLine: DisplayOrder, shipmentId: number): void {
      let shipment = shipments.find((s) => s.shipmentId === shipmentId);
      if (!shipment) {
         shipment = {
            shipmentId,
            orderLines: []
         };
         shipments.push(shipment);
      }
      shipment.orderLines.push(orderLine);
   }

   static getOrderHeaderStatus(orderHeader: OrderHeader): Status | undefined {
      const allOrderLinesStatuses = orderHeader.orderLines.map((orderLine) => orderLine.status);
      const uniqueOrderLinesStatuses = Array.from(new Set(allOrderLinesStatuses));
      if (uniqueOrderLinesStatuses.length === 1) {
         return uniqueOrderLinesStatuses[0];
      }
      return undefined;
   }

   static checkShowInvoiceInfo(orderHeader: OrderHeader): InvoiceInfo | undefined {
      const firstOrderLine = orderHeader.orderLines[0];
      const showVatCode = orderHeader.orderLines.every((orderLine) => orderLine.vatCode === firstOrderLine.vatCode);
      if (showVatCode && firstOrderLine.vatCode) {
         return {
            vatCode: firstOrderLine.vatCode,
            ole: orderHeader.ole
         };
      }
      return undefined;
   }

   static getOrderHeaderDeliveryInfo(orderHeader: OrderHeader): DeliveryInfo | undefined {
      const firstOrderLine = orderHeader.orderLines[0];
      const showDeliveryInfo = orderHeader.orderLines.every(
         (orderLine) =>
            orderLine.destinationAddress?.id === firstOrderLine.destinationAddress?.id &&
            orderLine.deliveryTermAddress?.id === firstOrderLine.deliveryTermAddress?.id &&
            orderLine.deliveryTerm === firstOrderLine.deliveryTerm &&
            orderLine.deliveryInstructions === firstOrderLine.deliveryInstructions
      );
      if (showDeliveryInfo) {
         return {
            destinationAddress: firstOrderLine.destinationAddress,
            deliveryTermAddress: firstOrderLine.deliveryTermAddress,
            deliveryTerm: firstOrderLine.deliveryTerm,
            deliveryInstructions: firstOrderLine.deliveryInstructions
         };
      }
      return undefined;
   }

   static getSortedShipmentsWithOrderLines(shipments: ShipmentWithOrderLines[]): ShipmentWithOrderLines[] {
      return shipments.sort((a, b) => {
         const aDeliveryDate = a.orderLines[0].deliveryDate;
         const bDeliveryDate = b.orderLines[0].deliveryDate;
         if (aDeliveryDate && bDeliveryDate) {
            return new Date(aDeliveryDate.from).getTime() - new Date(bDeliveryDate.from).getTime();
         }
         return 0;
      });
   }

   static getSortedOrderLinesByStatusByDeliveredDate(orders: DisplayOrder[], isOpen?: boolean): DisplayOrder[] {
      return orders.sort((a, b) => {
         const deliveryDateComparison = isOpen
            ? new Date(a.deliveryDate.from).getTime() - new Date(b.deliveryDate.from).getTime()
            : new Date(b.deliveryDate.from).getTime() - new Date(a.deliveryDate.from).getTime();
         if (deliveryDateComparison !== 0) return deliveryDateComparison;

         return a.orderNumber.localeCompare(b.orderNumber, undefined, { numeric: true });
      });
   }
}
