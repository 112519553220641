import { useContext } from 'react';

import { ShoppingCartContext } from '../providers/shopping-cart-providers';

export const useShoppingCartContext = () => {
   const context = useContext(ShoppingCartContext);
   if (!context) {
      throw new Error('useShoppingCartContext must be used within a ShoppingCartContextProvider');
   }
   return context;
};
