import { Reducer } from 'react';

import { DuplicateCartItem, ShoppingCartItem, ShoppingCartProblem } from '@ravago/shared/page-data/models/features';

export interface ShoppingCartState {
   type: 'SUCCESS' | 'DUPLICATE' | 'ERROR' | undefined;
   duplicateCartItem: DuplicateCartItem | undefined;
   productName: string;
   existingItem: ShoppingCartItem | undefined;
   validationErrors: ShoppingCartProblem[];
}

export interface ShoppingCartAction {
   type: 'add_to_cart' | 'duplicate_product' | 'reset';
   value: Partial<ShoppingCartState>;
}

export const initialState: ShoppingCartState = {
   type: undefined,
   validationErrors: [],
   productName: '',
   duplicateCartItem: undefined,
   existingItem: undefined
};

export const ShoppingCartReducer: Reducer<ShoppingCartState, ShoppingCartAction> = (state, action) => {
   switch (action.type) {
      case 'add_to_cart':
         return {
            ...state,
            type: action.value.type ?? initialState.type,
            validationErrors: action.value.validationErrors ?? initialState.validationErrors,
            productName: action.value.productName ?? initialState.productName,
            duplicateCartItem: action.value.duplicateCartItem ?? initialState.duplicateCartItem,
            existingItem: action.value.existingItem ?? initialState.existingItem
         };
      case 'duplicate_product':
         return {
            ...state,
            type: action.value.type ?? initialState.type,
            duplicateCartItem: action.value.duplicateCartItem ?? initialState.duplicateCartItem,
            productName: action.value.productName ?? initialState.productName,
            existingItem: action.value.existingItem ?? initialState.existingItem,
            validationErrors: []
         };
      case 'reset':
         return initialState;
      default:
         return state;
   }
};
